import React from "react";
import { updateGymDetails, changePassword } from "../../services/profileServices";
import { setGymDetails } from "../../actions/index";
import {
    Form,
    Divider,
    Button,
    message,
    Input
} from 'antd';
import { connect } from 'react-redux';


const mapStateToProps = (state) => {
    return {
        preferences: state.preferences,
        gymDetails: state.gymDetails,
    }
}


class _Preferences extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonDisabled: true,
            passwordButtonDisabled: true
        };
    }

    state = {
        confirmDirty: false,
        autoCompleteResult: [],
    };

    componentDidMount() {
        this.setFields()
    }

    setFields = () => {
        const { gymName, email, firstName, lastName, phone, address } = this.props.gymDetails;
        this.props.form.setFieldsValue({
            gymName: gymName,
            email: email,
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            address: address,
        })
    }


    doSubmitGymDetails = async (details) => {
        try {
            const response = await updateGymDetails(details);
            this.props.setGymDetails(response.data);
            this.setFields();
            this.setState({ buttonDisabled: true })

            if (response.data) {
                message.success("Profile Details Successfully Updated!", 5)
                // this.props.history.push(`/dashboard`);
            }
        } catch (ex) {
            if (ex.response) {
                message.error(ex.response.data, 5)
            }
        }

    };

    handleUpdate = e => {
        e.preventDefault();
        if (this.state.buttonDisabled === true) {
            this.setState({ buttonDisabled: false })
        } else {
            this.props.form.validateFieldsAndScroll(['gymName', 'firstName', 'lastName', 'phone', 'address'], (err, details) => {
                if (!err) {
                    this.doSubmitGymDetails(details)
                }
            });
        }

    };

    handlePasswordChange = e => {
        e.preventDefault();
        if (this.state.passwordButtonDisabled === true) {
            this.setState({ passwordButtonDisabled: false })
        } else {
            this.props.form.validateFieldsAndScroll(['currentPassword', 'newPassword'], (err, passwords) => {
                if (!err) {
                    this.doSubmitPassword(passwords)
                }
            });
        }

    };

    doSubmitPassword = async (passwords) => {

        try {
            const response = await changePassword(passwords);
            message.success(response.data, 5)
            window.location = "/logout";

        } catch (ex) {
            if (ex.response) {
                message.error(ex.response.data, 5)
            }
        }

    };

    reset = () => {
        this.props.form.resetFields()
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 14,
                    offset: 8,
                },
            },
        };

        return (
            <div>

                <h3>Profile</h3>
                <Divider />
                <div className="RegisterPage">
                    <div className="RegisterPage__Content">


                        <Form {...formItemLayout} onSubmit={this.handleSubmit} className="RegisterPage__Form" autoComplete='off'>
                            <Form.Item label="E-mail">
                                {getFieldDecorator('email')(<Input disabled />)}
                            </Form.Item>

                            <Form.Item label="GYM Name" hasFeedback>
                                {getFieldDecorator('gymName', {
                                    rules: [
                                        { required: true, message: 'GYM name is required.' },
                                        { whitespace: true },
                                        {
                                            min: 3,
                                            message: 'GYM name should be 3 to 30 characters long.'
                                        },
                                        {
                                            max: 30,
                                            message: 'GYM name should be 3 to 30 characters long.'
                                        }]
                                })(
                                    <Input autoComplete="anyrandomstring" disabled={this.state.buttonDisabled} />
                                )}


                            </Form.Item>


                            <Form.Item label="Name" hasFeedback style={{ marginBottom: 0 }}>
                                {/* <Input.Group compact> */}
                                <Form.Item style={{ display: 'inline-block', width: '50%' }}>
                                    {getFieldDecorator('firstName', {
                                        rules: [
                                            { required: true, message: 'First name is required.' },
                                            { whitespace: true },
                                            {
                                                min: 1,
                                                message: 'First name should be 1 to 30 characters long.'
                                            },
                                            {
                                                max: 30,
                                                message: 'First name should be 1 to 30 characters long.'
                                            }]
                                    })(
                                        <Input
                                            // style={{ width: '50%' }}
                                            autoComplete="anyrandomstring"
                                            disabled={this.state.buttonDisabled}
                                            placeholder="First Name" />
                                    )}
                                </Form.Item>
                                <Form.Item style={{ display: 'inline-block', width: '50%' }}>
                                    {getFieldDecorator('lastName', {
                                        rules: [
                                            { required: true, message: 'Last name is required.' },
                                            { whitespace: true },
                                            {
                                                min: 1,
                                                message: 'Last name should be 1 to 30 characters long.'
                                            },
                                            {
                                                max: 30,
                                                message: 'Last name should be 1 to 30 characters long.'
                                            }]
                                    })(
                                        <Input
                                            // style={{ width: '50%' }}
                                            autoComplete="anyrandomstring"
                                            disabled={this.state.buttonDisabled}
                                            placeholder="Last Name" />
                                    )}
                                </Form.Item>

                                {/* </Input.Group> */}
                            </Form.Item>
                            <Form.Item label="Phone" hasFeedback>
                                {getFieldDecorator('phone', {

                                })(
                                    <Input autoComplete="anyrandomstring" disabled={this.state.buttonDisabled} />
                                )}

                            </Form.Item>
                            <Form.Item label="Address" hasFeedback>
                                {getFieldDecorator('address', {

                                })(
                                    <Input autoComplete="anyrandomstring" disabled={this.state.buttonDisabled} />
                                )}

                            </Form.Item>

                            <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" onClick={this.handleUpdate}>
                                    {this.state.buttonDisabled ? "Edit" : "Save"}
                                </Button>
                            </Form.Item>

                            <Divider />

                            <Form.Item label="Current Password" hasFeedback>
                                {getFieldDecorator('currentPassword', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Current Password is required.',
                                        },
                                        {
                                            min: 8,
                                            message: 'Password must have minimum 8 characters.',
                                        },

                                    ],
                                })(<Input.Password disabled={this.state.passwordButtonDisabled} />)}
                            </Form.Item>
                            <Form.Item label="New Password" hasFeedback>
                                {getFieldDecorator('newPassword', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please enter new password.',
                                        },
                                        {
                                            min: 8,
                                            message: 'Password must have minimum 8 characters.',
                                        },
                                    ],
                                })(<Input.Password disabled={this.state.passwordButtonDisabled} onBlur={this.handleConfirmBlur} />)}
                            </Form.Item>

                            <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" onClick={this.handlePasswordChange}>
                                    Change Password
                                    </Button>
                            </Form.Item>



                        </Form>

                    </div>
                </div>


            </div>

        );
    }
}

const Preferences = connect(mapStateToProps, { setGymDetails })(Form.create({ name: 'preferences_page' })(_Preferences));

export default Preferences;

