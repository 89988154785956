import React from "react";
import { withRouter } from "react-router-dom";
import Camera from '../../../components/Utils/Camera';
import constants from "../../../utils/constants";
import Utils from "../../../utils/utils";
import profile from "../../../utils/profile";
import _ from "lodash"
import { PubSub } from 'pubsub-js';
import { connect } from 'react-redux';
import { uploadFile } from "../../../services/fileUploadServices";
import ImageCropper from '../../../components/Utils/ImageCropper';
import LoadingIndicator from '../../../components/Utils/LoadingIndicator';
import { memberUpdate, getMemberDetails, } from "../../../services/memberServices";
import UnitConversions from "../../../utils/unitsConversions";

import {
  Icon,
  Row,
  Col,
  Form,
  Input,
  Select,
  Divider,
  DatePicker,
  Button,
  Breadcrumb,
  Modal,
  message,
  Upload,
  notification
} from "antd";

const confirm = Modal.confirm;

const mapStateToProps = (state) => {
  return {
    preferences: state.preferences
  }
}

class RegisterPageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      profPic: null,
      profilePic: null,
      profile: profile,
      errors: "",
      proceedAnyway: false,
      plans: [],
      selectedPlanId: "",
      error: "",
      weightUnit: "",
      heightUnit: "",
      selectedPlan: "",
      expireDate: "",
      startDate: "",
      preparingPhoto: false,
      formIsSubmitting: false,
      profileObjectId: "",
      currentIdNumber: "",
      currentProfilePic: "",
    };
  }

  async componentDidMount() {
    try {
      const response = await getMemberDetails(this.props.match.params.memberId)
      this.setFields(response.data[0])
      this.setState({
        profile: response.data[0],
        profileObjectId: response.data[0]._id,
        error: false,
        spinning: false,
        profPic: response.data[0].profilePic,
        currentIdNumber: response.data[0].idNumber,
        currentProfilePic: response.data[0].profilePic
      })

    } catch (ex) {
      console.log(ex)
      if (ex.response && ex.response.status === 400) {
        this.setState({ error: true, spinning: false })
      }

    }
  }

  setFields = (profile) => {
    const { heightUnit, weightUnit } = this.props.preferences;
    this.props.form.setFieldsValue({
      name: profile.name,
      idNumber: profile.idNumber,
      address: profile.address,
      email: profile.email,
      phone: profile.phone,
      gender: profile.gender,
      dateOfBirth: Utils.convertUtcTimeToLocalAsMoment(profile.dateOfBirth),
      joiningDate: Utils.convertUtcTimeToLocalAsMoment(profile.joiningDate),
      age: profile.age,
      weight: String(UnitConversions.serverWeightToLocalUnits(weightUnit, profile.weight)),
      height: Utils.getClosest(constants.heightArray(heightUnit), profile.height),
      healthIssues: profile.healthIssues,
      emgName: profile.emgName,
      emgAddress: profile.emgAddress,
      emgPhone: profile.emgPhone,
      emgRelationship: profile.emgRelationship,

    })
  }

  createMember = async (profile) => {
    this.setState({ formIsSubmitting: true });
    message.loading({ content: 'Updating member details...', key: "update_member", duration: 0 });
    if (this.state.profilePic) {
  
      const response2 = await this.submitFile(this.state.profilePic);
      profile.profilePic = response2.data.Location;
      profile.oldProfilePic = this.state.currentProfilePic;
      if (profile.oldProfilePic === constants.emptyProfilePicUrl) {
        profile.deleteOldImage = false;
      } else {
        profile.deleteOldImage = true;
      }

    } else {
      profile.profilePic = this.state.currentProfilePic;
    }
    const response = await memberUpdate(profile, true, "personal")
    message.success({ content: "Member updated successfully!", key: "update_member" });
    this.setState({ formIsSubmitting: false });
    this.props.history.push(`/member/${response.data.memberId}`);

  }

  doSubmit = async (profile) => {
    try {
      this.setState({ formIsSubmitting: true });
      message.loading({ content: 'Updating member details...', key: "update_member" });

      if (profile.idNumber !== this.state.currentIdNumber) {
        const response = await memberUpdate(profile, false, "personal")
        if (response.data.hasAMember === true) {
          message.warn({ content: "Member already exists!", key: "update_member" });
          this.setState({ formIsSubmitting: false });
          this.showConfirm();
        } else {
          this.createMember(profile);
        }
      } else {
        this.createMember(profile);
      }

    } catch (ex) {
      console.log("ex", ex.message, ex.response.data);
      this.setState({ formIsSubmitting: false });
      if (ex.response && ex.response.status === 400) {
        message.error({ content: ex.response.data, key: "update_member" });
      }
    }
  }

  handleSubmit = e => {
    const { weightUnit } = this.props.preferences;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
       
        values.dateOfBirth = Utils.convertLocalTimeToUtc(values.dateOfBirth)
        values.joiningDate = Utils.convertLocalTimeToUtc(values.joiningDate)
        values.membershipExpireDate = Utils.convertLocalTimeToUtc(values.membershipExpireDate)
        values.membershipStartDate = Utils.convertLocalTimeToUtc(values.membershipStartDate)
        values.weight = UnitConversions.localWeightToServerUnits(weightUnit, values.weight)
        this.setState({
          profile: {
            ...this.state.profile,
            ...values
          }
        }, () => { this.doSubmit(this.state.profile) });

      }
    });
  };

  saveSelectedPlan_calculateExpireDate(planId, plans) {
    let plan = _.find(plans, { "_id": planId })
    this.setState({ selectedPlan: plan }, () => this.calculateExpireDate(plan, this.state.startDate))
  }

  savePlanStartDate_calculateExpireDate(planStartDate) {
    this.setState({ startDate: planStartDate }, () => this.calculateExpireDate(this.state.selectedPlan, planStartDate))
  }


  calculateExpireDate = (plan, planStartDate) => {
    try {
      let expire_date = planStartDate.clone().add(plan.membershipPeriod, plan.prefix);
      this.setState({ expireDate: expire_date }, () => this.props.form.setFieldsValue({ membershipExpireDate: expire_date }))
    } catch (ex) {
    }
  }


  handleRegister = () => {
    let heightInMeters = this.state.profile.height * 0.0254;
    let BMI = (this.state.profile.weight / (heightInMeters * heightInMeters)).toFixed(1);
    this.setState({
      profile: {
        ...this.state.profile,
        bmi: BMI,

      }
    }, () => { this.doSubmit(this.state.profile) });
  }

  showConfirm = () => {
    confirm({
      title: 'Confirm Member Registration',
      content: 'A member with the given ID number already exist. Do you want to Proceed?',
      onOk: () => {
        this.createMember(this.state.profile);
      },
      onCancel: () => { },
    });
  }

  handleDropDownChange(key, value) {
    this.setState({
      profile: {
        ...this.state.profile,
        [key]: value
      }
    });
  }


  /**
   * ==============================
   * Start of Profile image
   * ==============================
   */

  saveToFile = (uri) => {
    let pic = Utils.dataURLtoFile(uri, 'profileImage2.png');
    this.setState({
      profilePic: pic,
      preparingPhoto: false,
    });
  }

  openCamera = () => {
    PubSub.publish("openCamera");
  }

  handleProfilePhoto = (data) => {
    PubSub.publish("openImageCropper", data.profilePhoto);
  }

  handleImageBrowser = (file) => {
    if (file && constants.files.allowedImageTypes.indexOf(file.type) === -1) {
      notification.error({
        message: 'Unsupported File Type!',
        description:
          'Only .jpg, jpeg and .png files are supported.',
      });
    } else if (file && file.size > constants.files.maxImageSize) {
      notification.error({
        message: 'Unsupported File Size!',
        description:
          'File size should be less than 3MB.',
      });
    } else {
      const reader = new FileReader();
      reader.addEventListener("load", function () {
        PubSub.publish("openImageCropper", reader.result);
      }, false);
      if (file) {
        reader.readAsDataURL(file);
      }
    }

    return false;
  }

  handleProfilePhotoCropped = (imageData) => {
    this.setState({
      profPic: imageData
    });
    this.saveToFile(imageData);
  }

  /**
  * ==============================
  * End of Profile image
  * ==============================
  */

  handleInputChange = (key, event) => {
    this.setState({
      profile: {
        ...this.state.profile,
        [key]: event.target.value
      }
    });
  }

  handleSelectChange = (key, value) => {

    this.setState({
      profile: {
        ...this.state.profile,
        [key]: value
      }
    });
  }

  handleEmergencyDetailsChange = (key, event) => {
    this.setState({
      profile: {
        ...this.state.profile,
        emergency: {
          ...this.state.profile.emergency,
          [key]: event.target.value
        }

      }
    });
  }

  handleDateChange = (key, value) => {
    
    this.setState({
      profile: {
        ...this.state.profile,
        [key]: value
      }
    });
  }

  submitFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await uploadFile(formData);
    console.log("File uploaded", response);
    return response;
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    const { dateFormat, weightUnit, heightUnit } = this.props.preferences;

    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>            
            <span>Members</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Update</Breadcrumb.Item>
        </Breadcrumb>

        <h3>Update Member Details</h3>

        <div className="RegisterPage">
          <div className="RegisterPage__Content">
            <Form name="new-customer" className="RegisterPage__Form" noValidate onSubmit={this.handleSubmit}>

              <LoadingIndicator visible={this.state.formIsSubmitting}></LoadingIndicator>

              <div
                className="profilePicContainer">
                <div className="avatar-uploader">
                  {this.state.profPic ?
                    <div style={{ backgroundImage: `url(${this.state.profPic})`, width: "250px", height: "250px", backgroundPosition: "center", backgroundSize: "cover" }}></div> :
                    <Icon width="256px" height="256px" type="user" />}
                  <Button title="Capture" onClick={this.openCamera} className="cameraButton" type="primary" shape="circle" icon="camera"></Button>
                  <Upload accept={constants.files.allowedFileBrowserImageTypes} showUploadList={false} multiple={false} beforeUpload={this.handleImageBrowser}>
                    <Button className="uploadButton" type="primary" shape="circle">
                      <Icon type="upload" />
                    </Button>
                  </Upload>
                </div>

              </div>

              <Row gutter={24}>
                <Col sm={24} md={12}>
                  <Form.Item label="Name">
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: 'Please enter member name' }],
                    })(
                      <Input allowClear={true} placeholder="Enter member name" />,
                    )}
                  </Form.Item>

                </Col>
                <Col sm={24} md={12}>
                  <Form.Item label="ID Number">
                    {getFieldDecorator('idNumber', {
                      rules: [{ required: true, message: 'Please enter member ID Number' }],
                    })(
                      <Input allowClear={true} placeholder="Enter member ID number" />,
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col sm={24} md={12}>
                  <Form.Item label="Address">
                    {getFieldDecorator('address')(
                      <Input allowClear={true} placeholder="Enter address" />,
                    )}
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item label="Email">
                    {getFieldDecorator('email')(
                      <Input allowClear={true} placeholder="Enter email" />,
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col sm={24} md={12}>
                  <Form.Item label="Phone">
                    {getFieldDecorator('phone')(
                      <Input allowClear={true} placeholder="Enter phone number" />,
                    )}
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item label="Gender">
                    {getFieldDecorator('gender')(
                      <Select placeholder="Please select gender">
                        <Select.Option value="Male">Male</Select.Option>
                        <Select.Option value="Female">Female</Select.Option>
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col sm={24} md={12}>
                  <Form.Item label="Date of Birth">
                    {getFieldDecorator('dateOfBirth')(
                      <DatePicker showTime={false} format={dateFormat} />,
                    )}
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item label="Joining Date">
                    {getFieldDecorator('joiningDate')(
                      <DatePicker showTime={false} format={dateFormat} />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col sm={12} md={6}>
                  <Form.Item label="Age">
                    {getFieldDecorator('age', {
                      rules: [{ pattern: "^[0-9.]+$", message: 'Enter positive value' }],
                    })(
                      <Input allowClear={true} placeholder="30" />,
                    )}
                  </Form.Item>
                </Col>
                <Col sm={12} md={6}>
                  <Form.Item label="Weight">
                    {getFieldDecorator('weight', {
                      rules: [{ pattern: "^[0-9.]+$", message: 'Enter positive value' }],
                    })(
                      <Input allowClear={true} addonAfter={weightUnit} placeholder="80" />,
                    )}
                  </Form.Item>
                </Col>
                <Col sm={12} md={6}>
                  <Form.Item label="Height">
                    {getFieldDecorator('height')(
                      <Select placeholder="Select height">
                        {constants.heightArray(heightUnit).map((height, index) => <Select.Option key={index} value={height}>{Utils.convertHeightToUnits(heightUnit, height)}</Select.Option>)}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col sm={12} md={6}>
                  <Form.Item label="BMI">
                    {Utils.calculateBMI(this.props.form.getFieldValue("height"), this.props.form.getFieldValue("weight"), heightUnit, weightUnit)}

                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={24}>
                  <Row>
                    <Form.Item label="Health Issues">
                      {getFieldDecorator('healthIssues')(
                        <Input.TextArea rows={2} />
                      )}

                    </Form.Item>
                  </Row>
                </Col>
              </Row>

              <Divider style={{ margin: "8px 0" }} />
              <h4 style={{ marginBottom: "24px" }}>
                Emergency Contact Details
              </h4>

              <Row gutter={24}>
                <Col sm={24} md={12}>
                  <Form.Item label="Name">
                    {getFieldDecorator('emgName')(
                      <Input allowClear={true} placeholder="Enter name" />
                    )}
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item label="Address">
                    {getFieldDecorator('emgAddress')(
                      <Input allowClear={true} placeholder="Enter address" />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col sm={24} md={12}>
                  <Form.Item label="Relationship">
                    {getFieldDecorator('emgRelationship')(
                      <Input allowClear={true} placeholder="Enter relationship" />
                    )}
                  </Form.Item>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item label="Phone">
                    {getFieldDecorator('emgPhone')(
                      <Input type="number" allowClear={true} placeholder="Enter Phone" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item className="regbutton">
                    <Button type="primary" htmlType="submit" loading={this.state.preparingPhoto} disabled={this.state.preparingPhoto} >
                      Update
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

            </Form>
          </div>
        </div>

        <Camera
          title="Profile Picture"
          onTakePhoto={this.handleProfilePhoto}></Camera>
        <ImageCropper
          onImageCropped={this.handleProfilePhotoCropped}
          title="Crop Image"></ImageCropper>
      </div >
    );
  }
}

const WrappedForm = connect(mapStateToProps, {})(Form.create({ name: 'member_register_form' })(RegisterPageComponent));
const RegisterPage = withRouter(WrappedForm);
export default RegisterPage;
