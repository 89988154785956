import { Component } from 'react'
import auth from "../services/authService";

class Logout extends Component {
    async componentDidMount() {
        await auth.logoutFromServer();
        auth.logout();
        window.location = "/signin";
    }
    render() {
        return null;
    }
}
export default Logout;