import React, { Component } from 'react'
import Chart from "react-apexcharts";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom"

const mapStateToProps = (state) => {
  return {
    preferences: state.preferences,
  }
}

class AttendanceChart extends Component {

  constructor(props) {
    super(props);

    this.state = {
      options: {
        markers: {
          strokeWidth: 5,

        },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          colors: undefined,
          width: 2,
          dashArray: 0,
        },
        chart: {
          id: "basic-bar",
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: false,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: false,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            autoSelected: 'zoom'
          },
        },

        dataLabels: {
          enabled: false,
          formatter: function (value, opts) {
            return value + props.preferences.currencyUnit
          },
          markers: {
            size: 2,
            colors: "blue",
            strokeColors: '#fff',
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
              size: undefined,
              sizeOffset: 3
            }
          },
          position: 'top',
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
          },

          background: {
            enabled: false,
            foreColor: '#fff',
            borderRadius: 2,
            padding: 4,
            opacity: 0.9,
            borderWidth: 1,
            borderColor: '#fff'
          },
          // offsetY: -10,

        },
        yaxis: {
          labels: {
            formatter: function (value) {
              if (Number.isInteger(value)) {
                return value;
              } else {
                return ""
              }

            }
          },
        },
        xaxis: {
          type: 'category',
          //tickPlacement: 'between',  
          tickAmount: 15,
          datetimeUTC: false,
          labels: {
            show: true,
            rotate: 0,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            format: undefined,
          },

        }
      },
      series: [
        {
          name: "Attendance",
          data: this.props.data,
          type: "column"

        }
      ],
    };
  }

  componentDidMount() {
    this.setState({
      series: [
        {
          name: "Attendance",
          data: this.props.data,

        }
      ],
    })
  }
  componentDidUpdate(prevProps, prevState) {

    if (prevProps !== this.props) {
      this.setState({
        series: [
          {
            name: "Attendance",
            data: this.props.data,

          }
        ],
      })
    }

  }

  render() {
    return (
      <div style={{ padding: "10px" }} >
        <Chart
          options={this.state.options}
          series={this.state.series}
          height={this.props.height}
          type="line"
          width={this.props.width}
        />
      </div >
    )
  }
}


export default withRouter(connect(mapStateToProps, {})(AttendanceChart));