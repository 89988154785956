import React from "react";
import constants from "../../utils/constants";
import {
    Icon,
    Table,
    Input,
    Button, Modal
} from "antd";
import Highlighter from "react-highlight-words";
import { staffMemberRestore } from "../../services/staffMemberServices"

const { confirm } = Modal;

export default class DeactivatedStaffMembersTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortedInfo: null,
            filteredInfo: null,
            tableRowCount: null,
            // selectedProfile: {}
        };
    }

    handleChange = (pagination, filters, sorter, currentPageData) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
            tableRowCount: currentPageData.currentDataSource.length
        });
    };

    componentDidMount() {
        this.setState({ tableRowCount: this.props.members.length });
    }

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null
        });
    };


    handleOk = () => {
        this.setState({ visible: false });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };


    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={e =>
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
            </Button>
                    <Button
                        onClick={() => this.handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
            </Button>
                </div>
            ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        )
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    restoreProfile = async (record) => {       
        try {
            let data = {
                name: record.name,
                idNumber: record.idNumber,
                restore: true,
                deactivated: false
            }
            await staffMemberRestore(record._id, data)
            window.location.assign(`/staff_member/${record.staffId}`)
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                console.log("Updating error")
            }
        }


    }

    showRestoreConfirm = (record) => {

        confirm({
            title: 'Restore Account',
            content: 'Do you want to restore the account?',
            onOk: () => {
                this.restoreProfile(record);
            },
            onCancel() { },
        });
    }

    render() {
        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};
        const columns = [
            {
                title: "Staff ID",
                //width: 150,
                dataIndex: "staffId",
                key: "staffId",
                sorter: (a, b) => a.staffId.length - b.staffId.length,
                sortOrder: sortedInfo.columnKey === "staffId" && sortedInfo.order,
                ...this.getColumnSearchProps("staffId")
            },
            {
                title: "Name",
                dataIndex: "name",
                // ellipsis: true,
                key: "name",
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
                ...this.getColumnSearchProps("name")
            },
            {
                title: "ID Number",
                // width: 150,
                dataIndex: "idNumber",
                key: "idNumber",
                ...this.getColumnSearchProps("idNumber")
            },
            {
                title: "Gender",
                //width: 100,
                // ellipsis: true,
                dataIndex: "gender",
                key: "gender",
                filters: [
                    {
                        text: "Male",
                        value: "Male"
                    },
                    {
                        text: "Female",
                        value: "Female"
                    }
                ],
                filteredValue: filteredInfo.gender || null,
                onFilter: (value, record) => record.gender === (value)
            },           
            {
                title: "Joined Date",
                // ellipsis: true,
                dataIndex: "joiningDate",
                key: "joiningDate",

            },

           
        ];       
        if (this.props.type === "deactivated") {
            columns.push({
                title: "",
                dataIndex: "",
                width: 120,
                key: "b3",
                render: record => (
                    <Button
                        type="primary"
                        onClick={() => this.showRestoreConfirm(record)}
                    >
                        Restore
                    </Button>


                )
            })
        }

        return <>

            <Table
                bordered
                rowKey={record => record._id}
                columns={columns}
                scroll={{ x: 1120 }}
                dataSource={this.props.members}
                onChange={this.handleChange}
                pagination={{
                    pageSizeOptions: constants.tables.pageSizes,
                    showSizeChanger: true
                }}
            />
        </>

    }

}