import Constants from '../utils/constants';

const initialState = {      
    profile: {},
    membershipId:"",
    paymentCollapseActiveKey:"",
    currentSubscription:"",
    preferences:{},
    gymDetails:{},
};

function rootReducer(state = initialState, action) {

    if (action.type === Constants.redux.actions.SET_USER_NAME) {
        return Object.assign({}, state, {
            user_name: action.payload
        });
    }

    if (action.type === Constants.redux.actions.SET_PROFILE) {
        return Object.assign({}, state, {
            profile: action.payload
        });
    }
    if (action.type === Constants.redux.actions.SET_MEMBERSHIP_ID) {
        return Object.assign({}, state, {
            membershipId: action.payload
        });
    }
    if (action.type === Constants.redux.actions.SET_PAYMENT_COLLAPSE_KEY) {
        return Object.assign({}, state, {
            paymentCollapseActiveKey: action.payload
        });
    }
    if (action.type === Constants.redux.actions.SET_CURRENT_SUBSCRIPTION) {
        return Object.assign({}, state, {
            currentSubscription: action.payload
        });
    }
    if (action.type === Constants.redux.actions.SET_PREFERENCES) {
        return Object.assign({}, state, {
            preferences: action.payload
        });
    }
    if (action.type === Constants.redux.actions.SET_GYM_DETAILS) {
        return Object.assign({}, state, {
            gymDetails: action.payload
        });
    }
    
    return state;
};

export default rootReducer;