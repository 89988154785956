import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import CustomerPage from "./pages/Members/ViewMembersPage";
import MemberRegisterPage from "./pages/Members/AddNewMember";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import TakeAttendancePage from "./pages/Attendance/TakeAttendancePage";
import AttendanceLogPage from "./pages/Attendance/AttendanceLogPage";
import MemberPage from "./pages/Members/SubPages/MemberPage";
import UpdatePersonalDetailsPage from "./pages/Members/SubPages/UpdatePersonalDetailsPage";
import MembershipPlans from "./pages/MembershipPlans/MembershipPlans.js";
import AddMembershipPlan from "./pages/MembershipPlans/AddMembershipPlan.js";
import EditMembershipPlan from "./pages/MembershipPlans/EditMembershipPlan.js";
import SignInPage from "./pages/SignInPage/SignInPage.js";
import SignupPage from "./pages/SignupPage/SignupPage.js";
import Logout from "./components/Logout.js";
import ProtectedRouteApp from "./components/ProtectedRoutes/ProtectedRouteApp.js";
import ProtectedRouteUserValidation from "./components/ProtectedRoutes/ProtectedRouteUserValidation.js";
import SubscriptionsPage from "./pages/Subscriptions/SubscriptionsPage.js";
import PaymentsPage from "./pages/Payments/PaymentsPage.js";
import AddStaffMember from "./pages/Staff/AddStaffMember.js"
import ViewStaffMembersPage from "./pages/Staff/ViewStaffMembersPage.js"
import StaffMemberPage from "./pages/Staff/SubPages/StaffMemberPage.js"
import UpdateStaffPersonalDetailsPage from "./pages/Staff/SubPages/UpdateStaffPersonalDetailsPage";
import Preferences from './pages/Preferences/Preferences.js';
import Profile from './pages/Profile/Profile.js';
import ForgotPasswordPage from './pages/ForgotPassword/ForgotPassword.js';
import ResetPasswordPage from './pages/ForgotPassword/ResetPasswordPage.js';
import SignupEmailSentPage from './pages/SignupPage/SignupEmailSentPage.js';
import AccountValidationPage from "./pages/SignupPage/AccountValidationPage.js"
import PurchasePage from "./pages/PurchasePage/PurchasePage.js"
import BillingPage from "./pages/BillingPage/BillingPage.js"
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preferences: "",
    };
  }

  render() {
    return (
      <Router>
        <Switch>
          <ProtectedRouteUserValidation exact path="/signin" component={SignInPage} />
          <ProtectedRouteUserValidation exact path="/signup" component={SignupPage} />
          <ProtectedRouteUserValidation exact path="/forgottenPassword" component={ForgotPasswordPage} />
          <ProtectedRouteUserValidation exact path="/resetPassword" component={ResetPasswordPage} />
          {/* <ProtectedRouteUserValidation exact path="/emailSent" component={SignupEmailSentPage} />  */}
          <Route exact path="/emailSent" component={SignupEmailSentPage} />
          <Route exact path="/accountValidation" component={AccountValidationPage} />
          <Route exact path="/logout" component={Logout} />
          <ProtectedRouteApp exact path="/dashboard" component={DashboardPage} />
          <ProtectedRouteApp exact path="/members" component={CustomerPage} />
          <ProtectedRouteApp exact path="/memberRegister" component={MemberRegisterPage} />
          <ProtectedRouteApp exact path="/takeAttendance" component={TakeAttendancePage} />
          <ProtectedRouteApp exact path="/attendanceLog" component={AttendanceLogPage} />
          <ProtectedRouteApp exact path="/member/:memberId" component={MemberPage} />
          <ProtectedRouteApp exact path="/membership_plans" component={MembershipPlans} />
          <ProtectedRouteApp exact path="/add_membership_plan" component={AddMembershipPlan} />
          <ProtectedRouteApp exact path="/edit_membership_plan/:planId" component={EditMembershipPlan} />
          <ProtectedRouteApp exact path="/member/:memberId/updatePersonal" component={UpdatePersonalDetailsPage} />
          <ProtectedRouteApp exact path="/subscriptions" component={SubscriptionsPage} />
          <ProtectedRouteApp exact path="/payments" component={PaymentsPage} />
          <ProtectedRouteApp exact path="/staff" component={ViewStaffMembersPage} />
          <ProtectedRouteApp exact path="/staffRegister" component={AddStaffMember} />
          <ProtectedRouteApp exact path="/staff_member/:staffId" component={StaffMemberPage} />
          <ProtectedRouteApp exact path="/staff_member/:staffId/updatePersonal" component={UpdateStaffPersonalDetailsPage} />
          <ProtectedRouteApp exact path="/preferences" component={Preferences} />
          <ProtectedRouteApp exact path="/profile" component={Profile} />
          <ProtectedRouteApp exact path="/purchase" component={PurchasePage} />
          <ProtectedRouteApp exact path="/billing" component={BillingPage} />

          <Route exact path="/">
            <Redirect to="/signin" />
          </Route>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    );
  }
}


