import React from 'react';
import moment from "moment"

/**
 * PROPS
 * ============
 * timeFormat > ex: "dddd, MMMM Do YYYY"
 * dateFormat > ex: "h:mm:ss A"
 * interval > ex: 1000
 * className > ex: "class" , .date and .time to format 
 */

export default class Clock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            time: null,
            date: null
        }
    }

    updateTime = () => {
        try {
            this.setState({
                date: moment().format(this.props.dateFormat || "dddd, MMMM Do YYYY"),
                time: moment().format(this.props.timeFormat || "h:mm:ss A")
            });
        } catch (ex) {
            console.error("Invalid time format", ex);
        }
    }

    componentDidMount() {
        this.updateTime();
        this.timerHandler=setInterval(() => {
            this.updateTime();
        }, this.props.interval || 1000);
    }
    componentWillUnmount() {
        clearInterval(this.timerHandler)
    }
    
    render() {
        return <div className={this.props.className || ""}>
            <div className="time">
                {this.state.time}
            </div>
            <div className="date">
                {this.state.date}
            </div>
        </div>;
    }

}
