import http from "./httpService";
import Utils from "../utils/utils";

const paymentsApiEndpoint = Utils.getApiUrl() + "dashboard/payments";
const membersApiEndpoint = Utils.getApiUrl() + "dashboard/members";
const attendancesApiEndpoint = Utils.getApiUrl() + "dashboard/attendances";

export function getDashboardPaymentsDetails(data) {
    return http.get(paymentsApiEndpoint, { params: data });
}

export function getDashboardMembersDetails() {
    return http.get(membersApiEndpoint);
}
export function getDashboardAttendanceDetails(data) {
    return http.get(attendancesApiEndpoint, { params: data });
}

export default {
    getDashboardPaymentsDetails,
    getDashboardMembersDetails,
    getDashboardAttendanceDetails,
};

