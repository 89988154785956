import http from "./httpService";
import Utils from "../utils/utils";

const apiEndpoint = Utils.getApiUrl() + "payments";


export function getPaymentsDetails(data) {
    return http.get(apiEndpoint,{params:data});
}


export default {
    getPaymentsDetails,    
};

