function inchesToFeet(inches) {
    var feet = Math.round(inches / 12);
    inches %= 12;
    return `${feet}'${inches}"`;
}

function inchesToCm(inches) {
    var cm = (inches * 2.54);
    return `${cm} cm`;
}

function heightWithCm(cm) {
    return `${cm} cm`;
}

function cmToFeet(cm) {
    var _inches = (cm / 2.54);
    var _feet = Math.floor(_inches / 12);
    _inches %= 12;  
    return `${_feet}"${Math.round(_inches)}'`;
}

function kgToLb(kg) {
    var lb = (kg * 2.205);
    return lb;
}
function lbToKg(lb) {
    var kg = (lb / 2.205);
    return kg;
}

function serverHeightToLocalUnits(unit, value) {
    if (value) {
        if (unit === "cm") {
            return `${Math.round(value)} cm`;
        } else if (unit === "feet") {
            return cmToFeet(value);
        }
    } else {
        return ""
    }
}

function serverWeightToLocalUnits(unit, value) {
    if (value) {
        if (unit === "kg") {
            return (Math.round((value) * 10) / 10);
        } else if (unit === "lb") {
            return (Math.round(kgToLb(value) * 10) / 10);
        }
    } else {
        return ""
    }

}

function localWeightToServerUnits(unit, value) {
    if (value) {
        if (unit === "kg") {
            return value;
        } else if (unit === "lb") {
            return lbToKg(value);
        }
    } else {
        return ""
    }

}


export default {
    inchesToFeet,
    inchesToCm,
    heightWithCm,
    cmToFeet,
    serverWeightToLocalUnits,
    localWeightToServerUnits,
    serverHeightToLocalUnits,
    lbToKg

}
