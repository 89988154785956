import React, { Component } from "react";
import { Statistic, Row, Col } from "antd";

class StatBar extends Component {
  state = {};
  render() {
    return (
      <div className="customers_statBar">
        <Row gutter={16}>
          <Col span={8}>
            <Statistic
              title="Total Registered Members"
              value={this.props.totalRegisteredMembers} />
          </Col>
          <Col span={8}>
            <Statistic
              title=" Active Members"
              value={this.props.membershipActiveMembers}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="Past Due Members"
              value={this.props.membershipPastDueMembers}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default StatBar;
