import http from "./httpService";
import Utils from "../utils/utils";

const apiEndpoint = Utils.getApiUrl() + "staffMember";

function memberUrl(id) {
    return `${apiEndpoint}/${id}`;
}

export function getStaffMemberDetails(staffId) {
    return http.get(memberUrl(staffId));
}


export async function staffMemberRegister(profile, proceedAnyway) {
    profile.proceedAnyway = proceedAnyway;
    return http.post(apiEndpoint, profile);
}

export async function staffMemberUpdate(profile, proceedAnyway,area) {
    profile.proceedAnyway = proceedAnyway;
    profile.area = area;
    return http.put(memberUrl(profile._id), profile);
}

export async function staffMemberRestore(id,data) {   
    return http.put(memberUrl(id), data);
}

export async function staffMemberDelete(memberId) {    
    return http.delete(memberUrl(memberId));
}

export default {
    staffMemberRegister,
    getStaffMemberDetails,
    staffMemberDelete,
    staffMemberRestore,
    staffMemberUpdate
};

