import React from 'react';
import Sidebar from '../Layout/Sidebar';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import TrialRemainingDaysAlert from "../HeaderAlertMessages/TrialRemainingDaysAlert"
import TrialExpiredAlert from "../HeaderAlertMessages/TrialExpiredAlert"
import auth from "../../services/authService.js";
import { withRouter } from 'react-router-dom';
import { getGymDetails } from "../../services/profileServices";
import { setGymDetails } from "../../actions/index";
import { connect } from 'react-redux';
import PurchaseExpiredAlert from '../HeaderAlertMessages/PurchaseExpiredAlert';
import PurchaseCancelledAlert from '../HeaderAlertMessages/PurchaseCancelledAlert';

const mapStateToProps = (state) => {
    return {
        gymDetails: state.gymDetails
    }
}


class AppWrapper extends React.Component {

    constructor(props) {
        super(props)
        const user = auth.getCurrentUser()
        if (user) {
            this.state = { user }
            this.getGymDetail();
        } else {
            window.location = "/signin"
        }

    }

    getGymDetail = async () => {
        try {
            const responseGymDetails = await getGymDetails();
            this.props.setGymDetails(responseGymDetails.data);
        } catch (ex) {
            console.log("Getting gym details", ex);
        }

    }


    render() {
        return <div className="AppWrapper">
            <Header />
            <Sidebar></Sidebar>
            <div className="App">
                <div className="Content">
                    {this.props.gymDetails.paymentStatus === 0 && !this.props.gymDetails.expired ? <TrialRemainingDaysAlert /> : null}
                    {this.props.gymDetails.paymentStatus === 0 && this.props.gymDetails.expired ? <TrialExpiredAlert /> : null}
                    {this.props.gymDetails.paymentStatus === 1 && this.props.gymDetails.subscription_status === "deleted" && this.props.gymDetails.expired ? <PurchaseExpiredAlert /> : null}
                    {this.props.gymDetails.paymentStatus === 1 && this.props.gymDetails.subscription_status === "deleted" && !this.props.gymDetails.expired ? <PurchaseCancelledAlert /> : null}
                    <div style={{ position: "relative" }}>
                        {this.props.children}
                    </div>
                </div>
                <Footer></Footer>
            </div>
        </div>;
    }
}

export default withRouter(connect(mapStateToProps, { setGymDetails })(AppWrapper));
