import React from "react";
import constants from "../../utils/constants";
import { NavLink } from "react-router-dom";
import Utils from "../../utils/utils";
import _ from "lodash";
import { Icon, Table, Input, Button, Modal, Avatar } from "antd";
import Highlighter from "react-highlight-words";
import { memberRestore } from "../../services/memberServices";

const { confirm } = Modal;

export default class CustomerTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedInfo: null,
      filteredInfo: null,
      tableRowCount: null,
      // selectedProfile: {}
    };
  }

  handleChange = (pagination, filters, sorter, currentPageData) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
      tableRowCount: currentPageData.currentDataSource.length,
    });
  };

  componentDidMount() {
    this.setState({ tableRowCount: this.props.members.length });
  }

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  handleOk = () => {
    this.setState({ visible: false });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  restoreProfile = async (record) => {
    //console.log(memberId)
    // return <NavLink to={`/member/${memberId}`} />\
    try {
      let data = {
        name: record.name,
        idNumber: record.idNumber,
        restore: true,
        deactivated: false,
      };
      await memberRestore(record._id, data);
      window.location.assign(`/member/${record.memberId}`);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        console.log("Updating error");
      }
    }
  };

  showRestoreConfirm = (record) => {
    confirm({
      title: "Restore Account",
      content: "Do you want to restore the account?",
      onOk: () => {
        this.restoreProfile(record);
      },
      onCancel() {},
    });
  };

  render() {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: "Member ID",
        width: 140,
        fixed: "left",
        dataIndex: "memberId",
        key: "memberId",
        sorter: (a, b) => a.memberId.length - b.memberId.length,
        sortOrder: sortedInfo.columnKey === "memberId" && sortedInfo.order,
        ...this.getColumnSearchProps("memberId"),
        render: (text) => (
          <NavLink to={`/member/${text}`}>
            <Button type="primary" ghost icon="user">
              {text}
            </Button>
          </NavLink>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        //width: 220,
        ellipsis: true,
        key: "name",
        sorter: (a, b) => a.name.length - b.name.length,
        sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "ID",
        //width: 150,
        dataIndex: "idNumber",
        ellipsis: true,
        key: "idNumber",
        ...this.getColumnSearchProps("idNumber"),
      },
      {
        title: "Status",
        width: 140,
        dataIndex: "currentSubscription.status",
        key: "status",
        sorter: (a, b) => a.status.length - b.status.length,
        sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
        filters: [
          { text: "ACTIVE", value: "active" },
          { text: "PENDING", value: "pending" },
          { text: "EXPIRED", value: "expired" },
          { text: "NO SUBSCRIPTION", value: "no subscription" },
          { text: "PAST DUE", value: "past due" },
        ],
        filteredValue: filteredInfo.status || null,
        onFilter: (value, record) =>
          _.includes(record.currentSubscription, value),
        render: (tag) => <span>{Utils.membershipStatusTag(tag)}</span>,
      },
      {
        title: "Payment Status",
        width: 130,
        dataIndex: "currentSubscription.paymentStatus",
        key: "paymentStatus",
        sorter: (a, b) => a.paymentStatus.length - b.paymentStatus.length,
        sortOrder: sortedInfo.columnKey === "paymentStatus" && sortedInfo.order,
        filters: [
          { text: "FULLY PAID", value: "fully paid" },
          { text: "NOT PAID", value: "not paid" },
          { text: "PARTIALLY PAID", value: "partially paid" },
        ],
        filteredValue: filteredInfo.paymentStatus || null,
        onFilter: (value, record) =>
          _.includes(record.currentSubscription, value),
        render: (tag) => <span>{Utils.paymentStatusTag(tag)}</span>,
      },
      {
        title: "Gender",
        width: 100,
        //ellipsis: true,
        dataIndex: "gender",
        key: "gender",
        filters: [
          {
            text: "Male",
            value: "Male",
          },
          {
            text: "Female",
            value: "Female",
          },
        ],
        filteredValue: filteredInfo.gender || null,
        onFilter: (value, record) => record.gender === value,
      },

      {
        title: "Last Attendance",
        // width: 150,
        // ellipsis: true,
        dataIndex: "lastAttendance",
        key: "lastAttendance",
      },
      {
        title: "Expire Date",
        // width: 150,
        // ellipsis: true,
        dataIndex: "currentSubscription.expireDate",
        key: "expireDate",
      },
    ];
    if (this.props.type === "deactivated") {
      columns.splice(0, 1, {
        title: "Member ID",
        width: 140,
        fixed: "left",
        dataIndex: "memberId",
        key: "memberId",
        sorter: (a, b) => a.memberId.length - b.memberId.length,
        sortOrder: sortedInfo.columnKey === "memberId" && sortedInfo.order,
        ...this.getColumnSearchProps("memberId"),
      });
      columns.push({
        title: "",
        dataIndex: "",
        key: "b3",
        render: (record) => (
          <Button
            type="primary"
            onClick={() => this.showRestoreConfirm(record)}
          >
            Restore
          </Button>
        ),
      });
    }

    return (
      <>
        <Table
          bordered
          rowKey={(record) => record._id}
          columns={columns}
          scroll={{ x: 1200 }}
          dataSource={this.props.members}
          onChange={this.handleChange}
          pagination={{
            pageSizeOptions: constants.tables.pageSizes,
            showSizeChanger: true,
          }}
        />
      </>
    );
  }
}
