import React from "react";
import { Breadcrumb, Icon, Card, Tabs, Row, Col, Button,Spin } from "antd";
import { getStaffMembers } from "../../services/staffMembersServices";
import Utils from "../../utils/utils.js";
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import DeactivatedStaffMembersTable from "../../components/Tables/DeactivatedStaffMembersTable";
import _ from "lodash";

const { Meta } = Card;
const { TabPane } = Tabs;

const mapStateToProps = (state) => {
    return {
        preferences: state.preferences
    }
}

class StaffMembersPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            staffMembers: [],
            tabActiveKey: "1",
            deactivatedStaffMembers: [],
            hasStaffMembers: 2,
            loading: true,

        };

    }

    componentDidMount() {
        this.loadData();
        if (this.props.location.state?.tabActiveKey === "2" && this.props.location.state) {
            this.setState({ tabActiveKey: this.props.location.state.tabActiveKey })
        } else {
            this.setState({ tabActiveKey: "1" })
        }
        this.props.history.push(`/staff`, { tabActiveKey: "1" });
    }

    async loadData() {
        try {
            this.data = await getStaffMembers()
            this.processData(this.data);

        } catch (ex) {
            console.log(ex);
            if (ex.response && ex.response.status === 400) {
                this.setState({ error: true, spinning: false })
            }

        }
    }

    processData(response) {
        const { dateFormat } = this.props.preferences;
        let _hasStaffMembers = 2;
        let StaffMembers = response.data.map((member) => {
            member.joiningDate = Utils.convertUtcTimeToLocal(member.joiningDate, dateFormat);
            return member;
        });


        let _deactivatedStaffMembers = _.filter(StaffMembers, { deactivated: true })
        let _StaffMembers = _.filter(StaffMembers, { deactivated: false })

        if (_StaffMembers.length > 0) {
            _hasStaffMembers = 1;
        } else {
            _hasStaffMembers = 0;
        }
        this.setState({
            hasStaffMembers: _hasStaffMembers,
            staffMembers: _StaffMembers,
            deactivatedStaffMembers: _deactivatedStaffMembers,
            loading: false
        })

    }

    handleTabChange = (key) => {
        this.setState({ tabActiveKey: key })
    }

    handleCardClick = (staffId) => {
        this.props.history.push(`/staff_member/${staffId}`);
    }

    render() {
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item>                       
                        <span>Staff</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>View Staff</Breadcrumb.Item>
                </Breadcrumb>
                <h3>Staff Members</h3>

                <Tabs tabBarGutter={5} type="card" activeKey={this.state.tabActiveKey} onChange={this.handleTabChange}>
                    <TabPane tab="Staff Members" key="1">

                        {this.state.loading ? <Spin size="large" className="spinner" tip="Loading Staff Members..." /> :
                            <div style={{ display: "flex", flexDirection: "row", alignContent: "space-evenly", flexWrap: "wrap" }}>
                                {this.state.hasStaffMembers === 1 ? <>
                                    {this.state.staffMembers.map((member, i) => (
                                        <Card
                                            key={i}
                                            hoverable
                                            style={{ width: 250, margin: "10px" }}
                                            cover={<img width="248" height="248" alt="profilePic" src={member.profilePic} />}
                                            onClick={() => this.handleCardClick(member.staffId)}
                                        >
                                            <Meta title={member.name} />
                                            <div><h3> &nbsp;</h3></div>

                                            <Row gutter={24}>
                                                <Col sm={24} md={4}>
                                                    <div ><Icon type="idcard" theme="twoTone" /></div>
                                                </Col>
                                                <Col sm={24} md={20}>
                                                    <div style={{ textAlign: "left", overflowWrap: "anywhere" }}>{member.staffId}</div>
                                                </Col>
                                            </Row>
                                            <Row gutter={24}>
                                                <Col sm={24} md={4}>
                                                    <div > <Icon type="phone" theme="twoTone" /></div>
                                                </Col>
                                                <Col sm={24} md={20}>
                                                    <div style={{ textAlign: "left", overflowWrap: "anywhere" }}>{member.phone}</div>
                                                </Col>
                                            </Row>
                                            <Row gutter={24}>
                                                <Col sm={24} md={4}>
                                                    <div > <Icon type="mail" theme="twoTone" /></div>
                                                </Col>
                                                <Col sm={24} md={20}>
                                                    <div style={{ textAlign: "left", overflowWrap: "anywhere" }}>{member.email}</div>
                                                </Col>
                                            </Row>

                                        </Card>
                                    ))}</> : null}

                                {this.state.hasStaffMembers === 0 ? <>
                                    <div style={{ marginTop: "20px" }}><div>There are no staff members to show. <NavLink to="/staffRegister">
                                        <Button style={{ marginLeft: "5px" }} type="primary" size="small" ghost>Add Staff Members</Button>
                                    </NavLink></div></div></> : null}

                            </div>}



                    </TabPane>


                    <TabPane tab="Deactivated Staff Members" key="2">

                        <DeactivatedStaffMembersTable
                            type="deactivated"
                            members={this.state.deactivatedStaffMembers}>
                        </DeactivatedStaffMembersTable>

                    </TabPane>
                </Tabs>


            </div>);
    }
}


export default withRouter(connect(mapStateToProps, {})(StaffMembersPage));