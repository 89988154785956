import React from "react";
import {
    Spin,  
} from "antd";
import SubscriptionsTable from "../../components/Tables/SubscriptionsTable";
import Utils from "../../utils/utils.js";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAllSubscriptions } from "../../services/subscriptionsServices.js";


const mapStateToProps = (state) => {
    return {
        preferences: state.preferences
    }
}

class SubscriptionsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            spinning: true,
            tabActiveKey: "1",
            currentSubscriptions: [],
        };

    }
    componentDidMount() {
        this.init();
    }

    handleTabChange = (key) => {
        this.setState({ tabActiveKey: key })
    }

    init = async () => {
        const { dateFormat, currencyUnit } = this.props.preferences;

        let _currentSubscriptions = [];
        try {
            let response = await getAllSubscriptions();

            _currentSubscriptions = response.data.map((subscription) => {
                subscription.startDate = Utils.convertUtcTimeToLocal(subscription.startDate, dateFormat);
                subscription.expireDate = Utils.convertUtcTimeToLocal(subscription.expireDate, dateFormat);
                subscription.duration = subscription.duration + " " + subscription.durationPrefix;
                subscription.price = subscription.price + " " + currencyUnit;
                subscription.amountBalance = subscription.amountBalance + " " + currencyUnit;
                subscription.amountPaid = subscription.amountPaid + " " + currencyUnit;
                subscription.discountsGiven = subscription.discountsGiven + " " + currencyUnit;

                return subscription;
            });

            this.setState({
                currentSubscriptions: _currentSubscriptions,
                spinning: false,
            })
        } catch (ex) {
            console.log("subscriptions ex:", ex)
        }
    }

    render() {

        return (
            <div>
                {/* <Breadcrumb>
                    <Breadcrumb.Item>                       
                        <span>Subscriptions</span>
                    </Breadcrumb.Item>
                </Breadcrumb> */}
                <h3>Subscriptions</h3>

                <div>
                    {(this.state.spinning === true) ? <Spin size="large" className="spinner" tip="Loading Subscriptions..."/> : <div>
                        <div className="SubscriptionsPage">
                            <SubscriptionsTable
                                subscriptions={this.state.currentSubscriptions}>
                            </SubscriptionsTable>
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, {})(SubscriptionsPage));
