import React from "react";
import constants from "../../utils/constants";
import { NavLink, withRouter } from "react-router-dom";
import Utils from "../../utils/utils";
import _ from "lodash";
import {
    Icon,
    Table,
    Input,
    Button,
} from "antd";
import Highlighter from "react-highlight-words";
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        preferences: state.preferences,
    }
}

class _SubscriptionsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortedInfo: null,
            filteredInfo: null,
            tableRowCount: null,
            // selectedProfile: {}
        };
    }

    handleChange = (pagination, filters, sorter, currentPageData) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
            tableRowCount: currentPageData.currentDataSource.length
        });
    };

    componentDidMount() {
        this.setState({ tableRowCount: this.props.subscriptions.length });
    }

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null
        });
    };


    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={e =>
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
            </Button>
                    <Button
                        onClick={() => this.handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
            </Button>
                </div>
            ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        )
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
    };


    expandedRowRender = (e) => {
        let data = [];
        data[0] = e;
        const columns = [

            {
                title: 'Calendar Details',
                children: [
                    {
                        title: "Start Date",
                        ellipsis: true,
                        dataIndex: "startDate",
                        key: "startDate",

                    },
                    {
                        title: "Expire Date",
                        ellipsis: true,
                        dataIndex: "expireDate",
                        key: "expireDate",

                    },
                    {
                        title: "Duration",
                        //width: 100,
                        ellipsis: true,
                        dataIndex: "duration",
                        key: "duration",
                    },
                ]

            },

            {
                title: 'Payments Details',
                children: [
                    {
                        title: "Price",
                        dataIndex: 'price',
                        key: 'price',
                        // width: 150,

                    },
                    {
                        title: "Paid Amount",
                        dataIndex: 'amountPaid',
                        key: 'amountPaid',
                        // width: 150,                    
                    },
                    {
                        title: "Discount",
                        dataIndex: 'discountsGiven',
                        key: 'discount',
                        // width: 150,                    
                    },
                    {
                        title: "Balance",
                        dataIndex: 'amountBalance',
                        key: 'balance',
                        // width: 150,

                    },
                ]

            },

        ];

        return <Table bordered={true} columns={columns} dataSource={data} pagination={false} rowKey={record => record._id} />;
    }

    render() {
        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};
        const columns = [
            {
                title: "Member ID",                
                dataIndex: "memberId",
                width: 140,
                // fixed: 'left',
                key: "memberId",
                sorter: (a, b) => a.memberId.length - b.memberId.length,
                sortOrder: sortedInfo.columnKey === "memberId" && sortedInfo.order,
                ...this.getColumnSearchProps("memberId")
            },
            {
                title: "Name",
                dataIndex: "name",
                key: "name",
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
                ...this.getColumnSearchProps("name")
            },
            {
                title: "Plan Name",
                // width: 150,
                dataIndex: "membershipPlanName",
                key: "membershipPlanName",
                ...this.getColumnSearchProps("membershipPlanName")
            },
            {
                title: "Membership Status",
                width: 200,
                dataIndex: 'status',
                key: 'membershipStatus',
                sorter: (a, b) => a.status.length - b.status.length,
                sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
                filters: [{ text: 'ACTIVE', value: 'active' }, { text: 'PENDING', value: 'pending' }, { text: 'EXPIRED', value: 'expired' }, { text: 'NO SUBSCRIPTION', value: 'no subscription' }, { text: 'PAST DUE', value: 'past due' }],
                filteredValue: filteredInfo.status || null,
                onFilter: (value, record) => _.includes(record, value),
                // ...this.getColumnSearchProps("status"),
                render: tag => (
                    <span>

                        {Utils.membershipStatusTag(tag)}
                    </span>
                ),
            },
            {
                title: "Payment Status",
                width: 200,
                dataIndex: 'paymentStatus',
                key: 'paymentStatus',
                sorter: (a, b) => a.paymentStatus.length - b.paymentStatus.length,
                sortOrder: sortedInfo.columnKey === "paymentStatus" && sortedInfo.order,
                filters: [{ text: 'FULLY PAID', value: 'fully paid' }, { text: 'NOT PAID', value: 'not paid' }, { text: 'PARTIALLY PAID', value: 'partially paid' }],
                filteredValue: filteredInfo.paymentStatus || null,
                onFilter: (value, record) => _.includes(record, value),
                // ...this.getColumnSearchProps("paymentStatus"),
                render: tag => (
                    <span>
                        {Utils.paymentStatusTag(tag)}
                    </span>
                ),
            },
        ];

        columns.push({
            title: "",
            dataIndex: "",
            width: 100,
            key: "b1",
            render: record => (
                <NavLink to={`/member/${record.memberId}`} >
                    <Button
                        type="primary"
                    >
                        Profile
                    </Button>
                </NavLink>

            )
        });


        return <>

            <Table
                bordered
                rowKey={record => record._id}
                columns={columns}
                scroll={{ x: 1120 }}
                dataSource={this.props.subscriptions}
                onChange={this.handleChange}
                expandedRowRender={this.expandedRowRender}
                pagination={{
                    pageSizeOptions: constants.tables.pageSizes,
                    showSizeChanger: true
                }}

            />
        </>

    }

}

const SubscriptionsTable = connect(mapStateToProps, {})(_SubscriptionsTable);
export default withRouter(SubscriptionsTable);