import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter, NavLink } from "react-router-dom"
import { Button } from "antd"

const mapStateToProps = (state) => {
  return {
    gymDetails: state.gymDetails

  }
}

class TrialRemainingDaysAlert extends Component {

  constructor(props) {
    super(props);
    this.state = {}

  }

  render() {
    return (
      <div className="Trial__Alert">
        <div>
          <span className="Trial__Alert__Text">{`${this.props.gymDetails.remainingTime?.days + 1} days remaining in your trial period.`}</span>
          <div className="Trial__Alert__SubText">Please upgrade to keep using all the features without interruption.</div>
        </div>
        <div className="Trial__Alert__Button">
          <NavLink to="/purchase" >
            <Button type="danger" >Buy Now</Button>
          </NavLink>
        </div>

      </div>
    )
  }
}
export default withRouter(connect(mapStateToProps, {})(TrialRemainingDaysAlert));