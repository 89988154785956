import http from "./httpService";
import Utils from "../utils/utils";

const apiEndpoint = Utils.getApiUrl() + "members";



export function getMembers() {
    return http.get(apiEndpoint);
}


export default {
   getMembers
};

