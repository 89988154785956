import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../services/authService.js";

const ProtectedRouteUserValidation = ({ path, component: Component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!auth.getCurrentUser()){
          return Component ? <Component {...props} /> : render(props)};
        return (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

export default ProtectedRouteUserValidation;
