import http from "./httpService";
import Utils from "../utils/utils";

const apiEndpoint = Utils.getApiUrl() + "validateAccount";

export function validateAccount(data) {
    return http.post(apiEndpoint, data);
}

export default {
    validateAccount,
   
};

