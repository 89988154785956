import React, { Component } from 'react';
import { Button, Card, Collapse, Tabs } from "antd";
import Utils from "../../utils/utils";
import SubscriptionModal from "../../models/SubscriptionModal";
import DeleteSubscriptionModal from "../../models/DeleteSubscriptionModal";
import SubscriptionContent from "./subscriptionCardContent"
import { connect } from 'react-redux';

import { setCurrentSubscription } from '../../actions/index';


import { PubSub } from 'pubsub-js';

const { Panel } = Collapse;
const { TabPane } = Tabs;

const mapStateToProps = (state) => {
    return {
        currentSubscription: state.currentSubscription,
        membershipId: state.membershipId,
    }
}

const SubscriptionCollapseStatusButtons = (subscription) => (
    <div className="subscriptionTags"> {Utils.membershipStatusTag(subscription.status)} {Utils.paymentStatusTag(subscription.paymentStatus)}</div>
);


class SubscriptionCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addVisible: false,
            updateVisible: false,
            subscriptions: [],
            spinning: true,
            modalButtonText: "",
            disableAddButton: true,

        };

    }

    componentDidMount = async () => {
        this.init();
    }

    init = () => {
        let _disableAddButton = false;
        try {
            if (this.props.currentSubscription) {
                _disableAddButton = true;
            } else {
                _disableAddButton = false;
            }
            this.setState({ disableAddButton: _disableAddButton, error: false, spinning: false })
        } catch (ex) {
            console.log("_getSubscriptionDetails Ex:", ex)
            if (ex.response && ex.response.status === 400) {
                this.setState({ error: true, spinning: false })
            }
        }
    }

    showModal = (buttonText) => {
        if (buttonText === "Add") {
            this.setState({
                addVisible: true,
                modalButtonText: buttonText,
            });
        } else if (buttonText === "Update") {
            this.setState({
                updateVisible: true,
                modalButtonText: buttonText,
            })
        } else if (buttonText === "Delete") {
            this.setState({
                deleteVisible: true,
                modalButtonText: buttonText,
            });
        };
    }
    handleOk = () => {
        PubSub.publish("refreshPage");
        this.setState({
            addVisible: false,
            updateVisible: false,
            deleteVisible: false,
        });
    };
    handleSubscriptionId = (subscriptionId) => {
        if (subscriptionId) {
            return ` (${subscriptionId})`;
        } else {
            return "";
        }
    }

    handleCancel = e => {
        this.setState({
            addVisible: false,
            updateVisible: false,
            deleteVisible: false,
        });
    };

    getExtra = (currentSubscription) => {
        return <>
            {(currentSubscription) ? <>
                <span>{Utils.membershipStatusTag(currentSubscription.status)}</span>
                <span>{Utils.paymentStatusTag(currentSubscription.paymentStatus)}</span></> : null}
            <span><Button disabled={this.props.currentSubscription} type="primary" style={{ margin: "5px" }} onClick={() => this.showModal("Add")}>Add</Button></span>
        </>
    }

    render() {
        const { modalButtonText, addVisible, updateVisible, deleteVisible } = this.state;
        const { currentSubscription, subscriptionHistory } = this.props;

        return (
            <div className="memberDetailsCard">
                <Card title={`Subscription`} bordered={true} extra={this.getExtra(currentSubscription)}>

                    <Tabs defaultActiveKey="1" >
                        <TabPane tab="Current Subscription" key="1">
                            <div>
                                {(currentSubscription) ? <>
                                    <h3>Subscription ID: {currentSubscription?.subscriptionId}</h3>
                                    <SubscriptionContent disableButtons={false} showModal={this.showModal} subscription={currentSubscription} ></SubscriptionContent>
                                </> : <p>There is no subscription.</p>}
                            </div>

                        </TabPane>

                        <TabPane tab="History" key="2">
                            <h3>Subscription History</h3>
                            <div className="subscriptionHistoryContent">
                                {(subscriptionHistory.length !== 0) ? <><Collapse accordion
                                    onChange={this.callback}
                                >
                                    {(subscriptionHistory).map((subscription) => {
                                        return <Panel header={<span className="subscriptionTitle">Subscription ID: {subscription.subscriptionId}</span>} key={subscription.index} extra={SubscriptionCollapseStatusButtons(subscription)} >
                                            <SubscriptionContent disableButtons={true} subscription={subscription}></SubscriptionContent>
                                        </Panel>
                                    })}

                                </Collapse>
                                </> : <p>Subscription history is empty!</p>}

                            </div>
                        </TabPane>

                    </Tabs>
                </Card>

                {(currentSubscription) ? <SubscriptionModal
                    visible={updateVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    buttonText={modalButtonText}
                    formType="update">
                </SubscriptionModal> : null}
                {!(this.state.disableAddButton) ? <SubscriptionModal
                    visible={addVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    buttonText={modalButtonText}
                    formType="add">
                </SubscriptionModal> : null}
                <DeleteSubscriptionModal
                    visible={deleteVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    buttonText={modalButtonText}
                    currentSubscription={currentSubscription}>
                </DeleteSubscriptionModal>

            </div >
        )
    }
}

export default connect(mapStateToProps, { setCurrentSubscription })(SubscriptionCard);