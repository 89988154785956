import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { deleteSubscription } from "../services/subscriptionsServices";
import { connect } from 'react-redux';
import {
    Form,
    Button,
    Modal,
    message,
} from "antd";


const mapStateToProps = (state) => {
    return {
        currentSubscription: state.currentSubscription,
        membershipId: state.membershipId,
    }
}


class _DeleteSubscriptionModal extends Component {

    constructor(props) {
        super(props);
        this.state = {           
            loading:false,
        };
    }

    deleteSubscriptionPlan = async (memberId, sendToHistory) => {
        try {
            const response = await deleteSubscription(memberId, sendToHistory);
            message.success(response.data, 5);
        } catch (ex) {
            console.log(ex)
            if (ex.response && ex.response.status === 400) {
                this.setState({ error: true, spinning: false })
            }
        }
    }
    handleSubmit = e => {
        this.setState({loading:true})
        const { currentSubscription } = this.props
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
         
            if (!err) {
                if (currentSubscription.amountPaid > 0) {
                    await this.deleteSubscriptionPlan(this.props.membershipId, true);
                    this.setState({loading:false})
                    this.props.onOk();
                } else {
                    await this.deleteSubscriptionPlan(this.props.membershipId, false);
                    this.setState({loading:false})
                    this.props.onOk();
                }
            }
        });
    };

    handleCancel = e => {
        this.props.onCancel()
    };

    handleOk = values => {
        this.props.onOk()
        this.setState({
            visible: false,
        });
    };
    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 12,
                },
            },
        };

        return (
            <Modal
                visible={this.props.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer=""
                width={650}>

                <div >
                    <div >
                        <Form name="Delete_Subscription" noValidate onSubmit={this.handleSubmit} {...formItemLayout}>
                            <h4 style={{ marginBottom: "24px" }}>Delete a subscription plan</h4>
                            {(this.props.currentSubscription) ? <>

                                {(this.props.currentSubscription.amountPaid > 0) ?
                                    <div>Payments have been done for this subscription!<br /> You can only send this to subscription history.</div>
                                    : "Are you sure you want to delete ?"}
                            </> : null
                            }
                            <Form.Item {...tailFormItemLayout}  >
                                <Button style={{ margin: "10px" }} type="primary" onClick={this.handleCancel}  >
                                    Cancel
                                </Button>
                                <Button style={{ margin: "10px" }} type="primary" htmlType="submit" loading={this.state.loading} >
                                    {(this.props.currentSubscription) ? <>
                                        {(this.props.currentSubscription.amountPaid) ? "Send to History" : this.props.buttonText}
                                    </> : null}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        );
    }
}

// const WrappedModal = Form.create({ name: 'subscription_delete_form' })(_DeleteSubscriptionModal);
// const DeleteSubscriptionModal = withRouter(WrappedModal);
// export default DeleteSubscriptionModal;

const WrappedModal = connect(mapStateToProps, {})(Form.create({ name: 'subscription_delete_form' })(_DeleteSubscriptionModal));
export default withRouter(WrappedModal);


