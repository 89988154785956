import React from "react";
import { resendEmail } from "../../services/signupService";
import queryString from 'query-string'
import Footer from './../../components/Layout/Footer';
import {
    Button,
    Result,
    Spin
} from 'antd';

export default class SignupEmailSentPage extends React.Component {
    state = {
        confirmDirty: false,
        errors: "",
        email: "",
        disabledButton: false,
        emailSent: true,
        errorText: "",
        hasProps: true,
        loading: false,
        status: ""
    };


    componentDidMount() {
      
        const searchQuery = queryString.parse(this.props.location.search);

        if (this.props.location.state && this.props.location.state.emailSent) {
            this.setState({ email: searchQuery.email, emailSent: this.props.location.state.emailSent, hasProps: true, status: this.props.location.state.status });
        } else {
            this.setState({ hasProps: false })
        }
    }

    handleResendEmail = async () => {
        this.setState({ loading: true })
        //message.loading({ content: 'Sending Email...', duration: 0, key: "emailSending" })
        try {
            const response = await resendEmail({ email: this.state.email });
            if (response.data.emailSent) {
                // message.success({ content: 'Email Sent...', key: "emailSending" })
                this.setState({ loading: false, email: response.data.email, emailSent: response.data.emailSent, status: response.data.status })
            }
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                // message.error({ content: ex.response.data, key: "emailSending" })
                this.setState({ errorText: ex.response.data, emailSent: false, loading: false })
            }
        }

    }

    render() {

        return (
            <div className="register-page">
                {this.state.loading ?
                    <Spin tip="Sending Email..." className="spinner"></Spin> : <>

                        {this.state.hasProps ? <>

                            {this.state.emailSent ? <Result
                                status={this.state.status === "pending" ? "warning" : "success"}
                                title={this.state.status === "pending" ? "Email verification Pending!" : "Email Sent!"}
                                subTitle={<span>A verification email has been sent to {this.state.email}. <br />Please check your email and verify to proceed.</span>}
                                extra={[
                                    <Button disabled={this.state.disabledButton} type="primary" size="large" key="console" onClick={this.handleResendEmail}>
                                        Resend Link
                        </Button>,

                                ]}
                            /> : <Result
                                    status="error"
                                    title="Email sending failed!"
                                    subTitle={this.state.errorText}
                                    extra={[
                                        <Button disabled={this.state.disabledButton} type="primary" size="large" key="console" onClick={this.handleResendEmail}>
                                            Resend Link
                    </Button>,

                                    ]}
                                />}

                        </> : <Result
                                status="404"
                                title="404"
                                subTitle="Sorry, the page you visited does not exist."
                            />}


                    </>}
                <Footer isLogOut={true}></Footer>
            </div>
        );
    }
}
