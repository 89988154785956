import React, { useRef } from 'react';
import { Button, Row, Divider, Col } from "antd";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Utils from "../../utils/utils";
import { useReactToPrint } from 'react-to-print';
import PaymentBill from "../Invoice/PaymentBill"

const mapStateToProps = (state) => {
    return {
        preferences: state.preferences,
        gymDetails: state.gymDetails,
    }
}

function PaymentCollapseContent(props) {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const { payment, gymDetails } = props;
    const { currencyUnit, dateFormat } = props.preferences;
    return (
        < div id={payment.paymentId} >

            <Row gutter={24}>
                <Col sm={24} md={12}>
                    <div>ID: {payment.paymentId}</div>
                </Col>
                <Col sm={24} md={12}>
                    <div><h4>Date: {Utils.convertUtcTimeToLocal(payment.date, dateFormat)}</h4></div>
                </Col>
            </Row>

            <div style={{ marginTop: "10px", marginBottom: "5px" }}>Subscription ID: <b>{payment.subscriptionId}</b></div>
            <div className="plan_heading"><h4 className="_heading">Plan Name: </h4><span className="_name">{payment.planName}</span></div>

            <Divider style={{ marginTop: "10px", marginBottom: "15px" }}></Divider>

            <Row gutter={24}>
                <Col sm={24} md={16}>
                    <div><h4>Start Date</h4></div>
                </Col>
                <Col sm={24} md={8}>
                    <div style={{ textAlign: "right" }}>{Utils.convertUtcTimeToLocal(payment.startDate, dateFormat)}</div>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col sm={24} md={16}>
                    <div><h4>Expire Date</h4></div>
                </Col>
                <Col sm={24} md={8}>
                    <div style={{ textAlign: "right" }}>{Utils.convertUtcTimeToLocal(payment.expireDate, dateFormat)}</div>
                </Col>
            </Row>

            <Divider style={{ marginTop: "10px", marginBottom: "15px" }}></Divider>

            <Row gutter={24}>
                <Col sm={24} md={16}>
                    <div><h4>Plan Price</h4></div>
                </Col>
                <Col sm={24} md={8}>
                    <div style={{ textAlign: "right" }}>{payment.planPrice} {currencyUnit}</div>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col sm={24} md={16}>
                    <div><h4>Previous Balance</h4></div>
                </Col>
                <Col sm={24} md={8}>
                    <div style={{ textAlign: "right" }}>{payment.planBalanceAmount.toFixed(2)} {currencyUnit}</div>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col sm={24} md={16}>
                    <div><h4>Discount</h4></div>
                </Col>
                <Col sm={24} md={8}>
                    <div style={{ textAlign: "right" }}>{payment.discount} {currencyUnit}</div>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col sm={24} md={16}>
                    <div><h4>Total To Be Paid</h4></div>
                </Col>
                <Col sm={24} md={8}>
                    <div style={{ textAlign: "right" }}>{payment.amountToBePaid.toFixed(2)} {currencyUnit}</div>
                </Col>
            </Row>
            <Divider style={{ marginTop: "10px", marginBottom: "15px" }} />
            <Row gutter={24}>
                <Col sm={24} md={16}>
                    <div><h4>Payment {(payment.paymentType) ? <>-{payment.paymentType}</> : null}</h4></div>
                </Col>
                <Col sm={24} md={8}>
                    <div style={{ textAlign: "right" }}>{payment.amountPaid} {currencyUnit}</div>
                </Col>
            </Row>
            <Divider style={{ marginTop: "10px", marginBottom: "15px" }} />
            <Row gutter={24}>
                <Col sm={24} md={24}>
                    <div><h4>Remarks:</h4></div>
                </Col>
                <Col sm={24} md={24}>
                    <div>{payment.remarks}</div>
                </Col>
            </Row>
            <Row gutter={24} className="cardBottomFooter">
                <Button onClick={handlePrint} type="primary" ghost className="bottomButton" size="small">Invoice</Button >
            </Row>


            <div style={{ display: "none" }}>
                <PaymentBill ref={componentRef} payment={payment} gymDetails={gymDetails} preferences={props.preferences} />
            </div>


        </div >
    )
}

export default withRouter(connect(mapStateToProps, {})(PaymentCollapseContent));