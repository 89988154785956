import React, { Component } from 'react';
import { Button, Card, Collapse} from "antd";
import Utils from "../../utils/utils";
import MakePaymentModal from "../../models/MakePaymentModal";
import PaymentCollapseContent from "../../components/Cards/paymentCollapseContent"
import { connect } from 'react-redux';
import { setPaymentCollapseKey } from '../../actions/index';


import { PubSub } from 'pubsub-js';
import { withRouter } from 'react-router-dom';


const { Panel } = Collapse;

const mapStateToProps = (state) => {
    return {
        membershipId: state.membershipId,
        paymentCollapseActiveKey: state.paymentCollapseActiveKey,
        preferences: state.preferences,
        currentSubscription: state.currentSubscription,

    }
}

class PaymentsCard extends Component {

    state = {
        visible: false,
        membershipId: "",
        spinning: true,
        payments: []
    };


    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    handleOk = values => {
        PubSub.publish("refreshPage");
        this.setState({
            visible: false,
        });
    };
    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    callback = (key) => {
        this.props.setPaymentCollapseKey(key);
    }

    render() {
        const { visible } = this.state;
        const { dateFormat } = this.props.preferences;
        const {payments}=this.props;
        return (
            <div className="paymentCard memberDetailsCard">
                <Card id="paymentCard" title="Payments" bordered={true} extra={<Button disabled={!this.props.currentSubscription} type="primary" onClick={this.showModal}>Make a payment</Button>}>
                  
                    <div>
                        {(payments.length !== 0) ? <><Collapse accordion
                            defaultActiveKey={[payments[0]?.paymentId]}
                            activeKey={[this.props.paymentCollapseActiveKey || payments[0]?.paymentId]}
                            onChange={this.callback}
                        >
                            {(payments).map((payment, index) => {
                                return <Panel id={payment.paymentId} header={`${Utils.convertUtcTimeToLocal(payment.date, dateFormat)} - Payment ID: ${payment.paymentId} `} key={payment.paymentId}>
                                    <PaymentCollapseContent payment={payment}></PaymentCollapseContent>
                                </Panel>
                            })}

                        </Collapse>
                        </> : <> <Collapse defaultActiveKey={['1']} disabled={true} >
                            <Panel header="Payment" key="1">
                                <p>There are no payments</p>
                            </Panel>
                        </Collapse>
                            </>}

                    </div>
                </Card>

                {(this.props.currentSubscription) ? <MakePaymentModal
                    visible={visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    buttonText="Pay">
                </MakePaymentModal> : null}
            </div >
        )
    }
}
export default withRouter(connect(mapStateToProps, { setPaymentCollapseKey })(PaymentsCard));