import React from 'react';
import { setPreferences } from "../../actions/index";
import { getPreferences } from "../../services/preferencesServices";
import { connect } from 'react-redux';

class Preferences extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isDataLoaded: false
        }
    }

    componentDidMount = async () => {
        try {
            const responsePreferences = await getPreferences();            
            this.props.setPreferences(responsePreferences.data);            
            this.setState({
                isDataLoaded: true
            })
        } catch (ex) {
            console.log("try catch ex", ex);
        }
    }

    render() {
        return <>
            {this.state.isDataLoaded ? this.props.children : null}
        </>;
    }
}

export default connect(null, { setPreferences })(Preferences);
