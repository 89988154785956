import http from "./httpService";
import Utils from "../utils/utils";

const apiEndpoint = Utils.getApiUrl() + "signup";

export function signup(user) {
    return http.post(apiEndpoint, {
        gymName: user.gymName,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        password: user.password,
        agreement: user.agreement,
    });
}

export function resendEmail(data) {
    return http.put(apiEndpoint, data);
}


export default {
    signup,
    resendEmail

};

