import React from "react";
import { Form, Input, Button, Result, message } from "antd";
import { checkResetLink, resetPassword } from "../../services/forgotPasswordServices";
import queryString from 'query-string'
import { NavLink } from 'react-router-dom';
import Footer from './../../components/Layout/Footer';

class ForgotPassword extends React.Component {

    state = {
        errors: "",
        buttonDisabled: false,
        emailSent: false,
        email: "",
        resetLinkIsValid: 2,
        token: "",

    };

    async componentDidMount() {
        try {
            const value = queryString.parse(this.props.location.search);
            const response = await checkResetLink({ token: value.token });
            this.setState({ resetLinkIsValid: response.data.resetLinkIsValid, token: value.token })

        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                this.setState({ resetLinkIsValid: ex.response.data.resetLinkIsValid })
                // this.setState({ errors: ex.response.data })
            }
        }

    }

    doSubmit = async (passwords) => {
        passwords.token = this.state.token;
        try {
            const response = await resetPassword(passwords);
            window.location = "/logout"
            message.success({ content: response.data.status, key: "new_password" });
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                message.error({ content: ex.response.data.status, key: "new_password" });
            }
        }

    };


    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, passwords) => {
            if (!err) {
                this.setState({ buttonDisabled: true }, () => {
                    this.doSubmit(passwords);
                })

            }
        });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="login-page reset-password">
                {this.state.resetLinkIsValid === true ? <div className="form-wrapper">
                    <a href="/"><div className="LogoWrapper">
                        <div className="Logo"></div>
                    </div></a>
                    {this.state.resetLinkIsValid === true && <>
                        <Form onSubmit={this.handleSubmit} className="login-form">
                            <h2 style={{ padding: "0", margin: "0", textAlign: "center" }}>Reset Your Password</h2>
                            <div style={{ padding: "12px 0" }}>Please enter a new password for your account.</div>

                            <Form.Item label="Password" hasFeedback>
                                {getFieldDecorator('password', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input your new password!',
                                        },
                                        {
                                            min: 8,
                                            message: 'Password must have minimum 8 characters.',
                                        },
                                        {
                                            validator: this.validateToNextPassword,
                                        },
                                    ],
                                })(<Input.Password size="large" />)}
                            </Form.Item>
                            <Form.Item label="Confirm Password" hasFeedback>
                                {getFieldDecorator('confirmPassword', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',
                                        },
                                        {
                                            validator: this.compareToFirstPassword,
                                        },
                                    ],
                                })(<Input.Password size="large" onBlur={this.handleConfirmBlur} />)}
                            </Form.Item>
                            <Form.Item>
                                <Button disabled={this.state.buttonDisabled} type="primary" size="large" htmlType="submit" className="login-form-button">
                                    Reset
                         </Button>
                            </Form.Item>
                        </Form>
                    </>
                    }
                </div> : <div className="form-wrapper">
                        <a href="/"><div className="LogoWrapper">
                            <div className="Logo"></div>
                        </div></a>
                        <Result
                            status="warning"
                            title="Password reset link has expired!"
                            extra={
                                <Button type="primary" size="large" key="retry">
                                    <NavLink to="/forgottenPassword">
                                        <span>Retry Again</span>
                                    </NavLink>
                                </Button>
                            }
                        />
                    </div>}
                <Footer isLogOut={true}></Footer>
            </div >
        );
    }
}

const ForgotPasswordPage = Form.create({ name: 'forgotPassword_form' })(ForgotPassword);
export default ForgotPasswordPage;
