import React, { Component } from 'react'
import { Button, Card, Tabs, Spin, Modal, Row, Col, Divider } from "antd"
import Utils from "../../utils/utils";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { setUserName, setProfile } from '../../actions/index';
import { memberDelete } from "../../services/memberServices";
import UnitsConversions from "./../../utils/unitsConversions"

const { TabPane } = Tabs;
const { confirm } = Modal;

const mapStateToProps = (state) => {
    return {
        preferences: state.preferences,
        membershipId: state.membershipId
    }
}
class PersonalDetailsCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profPic: "",
            spinning: false,
            error: false,
            profile: "",
        };
    }

    handleDeactivate = () => {
        confirm({
            title: 'Deactivate profile?',
            content: ' Are you sure you want to deactivate the member profile ?',
            okText: "ok",
            onOk: async () => {
                try {
                    await memberDelete(this.props.membershipId);
                    this.props.history.push(`/members`, { tabActiveKey: "3" });
                } catch (ex) {
                    if (ex.response && ex.response.status === 400) {
                        console.log("handle deactivate Error Occurred")
                    }
                }
            },
            onCancel() { },
        });
    }

    render() {
        const { heightUnit, weightUnit } = this.props.preferences
        let { memberId = "", joiningDate, dateOfBirth, idNumber, name, address, email, phone, gender, age, healthIssues, emgName, emgAddress, emgPhone, emgRelationship, weight, height, profilePic } = this.props.profile
        return (
            <div className="memberInfo memberDetailsCard">
                <Card title={`Member ID: ${memberId}`} bordered={true} extra={<><NavLink to={`${memberId}/updatePersonal`}>
                    <Button type="primary" >Update</Button>
                </NavLink> <Button style={{ marginLeft: "5px" }} type="primary" onClick={() => this.handleDeactivate()} >Deactivate</Button> </>}>

                    {(this.state.spinning === true) ? <Spin style={{ marginTop: "50px" }} size="large" className="spinner" /> : <>

                        <div
                            className="profilePicContainer">
                            <div className="photo">
                                <div style={{ backgroundImage: `url(${profilePic})`, width: "250px", height: "250px", backgroundPosition: "center", backgroundSize: "cover" }}></div>
                                {/* <img src={"https://source.unsplash.com/random/250x250"} alt={"profilePic"} /> */}
                                {/* <img src={profilePic} alt={"profilePic"} /> */}
                            </div>
                        </div>

                        <Tabs defaultActiveKey="1" >
                            <TabPane tab="Details" key="1">
                                <Row gutter={24}>
                                    <Col sm={24} md={24}>
                                        <div><h3>Member Details</h3></div>
                                    </Col>
                                    <Col sm={24} md={24}>
                                        <div><h3> &nbsp;</h3></div>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col sm={24} md={8}>
                                        <div>Name</div>
                                    </Col>
                                    <Col sm={24} md={16}>
                                        <div style={{ textAlign: "left" }}>{name}</div>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col sm={24} md={8}>
                                        <div>ID Number</div>
                                    </Col>
                                    <Col sm={24} md={16}>
                                        <div style={{ textAlign: "left" }}>{idNumber}</div>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col sm={24} md={8}>
                                        <div>Gender</div>
                                    </Col>
                                    <Col sm={24} md={16}>
                                        <div style={{ textAlign: "left" }}>{gender}</div>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col sm={24} md={8}>
                                        <div>Date of Birth</div>
                                    </Col>
                                    <Col sm={24} md={16}>
                                        <div style={{ textAlign: "left" }}>{dateOfBirth}</div>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col sm={24} md={8}>
                                        <div>Age</div>
                                    </Col>
                                    <Col sm={24} md={16}>
                                        <div style={{ textAlign: "left" }}>{age}</div>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col sm={24} md={8}>
                                        <div>Health Issues</div>
                                    </Col>
                                    <Col sm={24} md={16}>
                                        <div style={{ textAlign: "left" }}>{healthIssues}</div>
                                    </Col>
                                </Row>

                                <Divider style={{ marginTop: "10px", marginBottom: "15px" }} />

                                <Row gutter={24}>
                                    <Col sm={24} md={8}>
                                        <div>Phone</div>
                                    </Col>
                                    <Col sm={24} md={16}>
                                        <div style={{ textAlign: "left" }}>{phone}</div>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col sm={24} md={8}>
                                        <div>Email</div>
                                    </Col>
                                    <Col sm={24} md={16}>
                                        <div style={{ textAlign: "left" }}>{email}</div>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col sm={24} md={8}>
                                        <div>Address</div>
                                    </Col>
                                    <Col sm={24} md={16}>
                                        <div style={{ textAlign: "left" }}>{address}</div>
                                    </Col>
                                </Row>

                                <Divider style={{ marginTop: "10px", marginBottom: "15px" }} />

                                <Row gutter={24}>
                                    <Col sm={24} md={8}>
                                        <div>Joined Date</div>
                                    </Col>
                                    <Col sm={24} md={16}>
                                        <div style={{ textAlign: "left" }}>{joiningDate}</div>
                                    </Col>
                                </Row>

                            </TabPane>

                            <TabPane tab="Emergency" key="2">


                                <Row gutter={24}>
                                    <Col sm={24} md={24}>
                                        <div><h3>Emergency Contact Details</h3></div>
                                    </Col>
                                    <Col sm={24} md={24}>
                                        <div><h3> &nbsp;</h3></div>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col sm={24} md={8}>
                                        <div>Name</div>
                                    </Col>
                                    <Col sm={24} md={16}>
                                        <div style={{ textAlign: "left" }}>{emgName}</div>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col sm={24} md={8}>
                                        <div>Address</div>
                                    </Col>
                                    <Col sm={24} md={16}>
                                        <div style={{ textAlign: "left" }}>{emgAddress}</div>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col sm={24} md={8}>
                                        <div>Phone</div>
                                    </Col>
                                    <Col sm={24} md={16}>
                                        <div style={{ textAlign: "left" }}>{emgPhone}</div>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col sm={24} md={8}>
                                        <div>Relationship</div>
                                    </Col>
                                    <Col sm={24} md={16}>
                                        <div style={{ textAlign: "left" }}>{emgRelationship}</div>
                                    </Col>
                                </Row>

                            </TabPane>

                            <TabPane tab="Physical" key="3">


                                <Row gutter={24}>
                                    <Col sm={24} md={24}>
                                        <div><h3>Physical Details</h3></div>
                                    </Col>
                                    <Col sm={24} md={24}>
                                        <div><h3> &nbsp;</h3></div>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col sm={24} md={8}>
                                        <div>Weight:</div>
                                    </Col>
                                    <Col sm={24} md={16}>
                                        {(weight) ? <div style={{ textAlign: "left" }}>{UnitsConversions.serverWeightToLocalUnits(weightUnit, weight)} {weightUnit}</div> : null}
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col sm={24} md={8}>
                                        <div>Height:</div>
                                    </Col>
                                    <Col sm={24} md={16}>
                                        {(height) ? <div style={{ textAlign: "left" }}>{UnitsConversions.serverHeightToLocalUnits(heightUnit, height)}</div> : null}
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col sm={24} md={8}>
                                        <div>BMI</div>
                                    </Col>
                                    <Col sm={24} md={16}>
                                        <div style={{ textAlign: "left" }}>{Utils.calculateBMI(height, weight, "cm", "kg")}</div>
                                    </Col>
                                </Row>
                            </TabPane>

                        </Tabs>
                    </>}
                </Card>

            </div >
        )
    }
}

export default withRouter(connect(mapStateToProps, { setUserName, setProfile })(PersonalDetailsCard));
