// import { createStore, applyMiddleware } from "redux";
// // import { composeWithDevTools } from 'redux-devtools-extension';
// import thunk from "redux-thunk";
// import rootReducer from "../reducers/index";

// // const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
// const store = createStore(rootReducer, applyMiddleware(thunk));
// export default store;


//import { createStore, applyMiddleware } from "redux";
import { createStore } from "redux";
//import { composeWithDevTools } from 'redux-devtools-extension';
// import thunk from "redux-thunk";
import rootReducer from "../reducers/index";

// const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
export default store;