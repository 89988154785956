import React from "react";
import {
    Table,
    Button,
    message
} from "antd";
import Constant from "../../utils/constants"
import ReasonForCancellationModel from "../../models/ReasonForCancellationModel"
import { cancelPurchase } from "../../services/cancelPurchase"

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        gymDetails: state.gymDetails,
    }
}

class _CurrentPurchaseTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableRowCount: null,
            showCancelModal: false,
        };
    }

    componentDidMount() {
        this.setState({ tableRowCount: [this.props.gymDetails].length });
    }

    handleCancel = () => {
        this.setState({ showCancelModal: true })
    }

    handleOk = async (data) => {
        data.subscription_id = this.props.gymDetails.subscription.subscription_id;
        data.gymId = this.props.gymDetails._id;
        try {
            await cancelPurchase(data);
            this.setState({
                showCancelModal: false
            });
            window.location = "/billing";
        } catch (ex) {
            console.log(ex);
            this.setState({ showCancelModal: false })
            if (ex.response && ex.response.status === 400) {
                message.error({ content: ex.response.data, key: "failed" });
            }
        }

    };

    handleModalCancel = e => {
        this.setState({
            showCancelModal: false
        });
    };

    handleUpdateBilling = () => {
        window.Paddle.Setup({
            vendor: Constant.PADDLE.VENDOR_ID
        });
        window.Paddle.Checkout.open({
            override: this.props.gymDetails.subscription.update_url
        });
    }

    render() {

        const columns = [
            {
                title: "Plan Name",
                // width: 150,
                dataIndex: "subscription.subscription_plan",
                key: "planName",

            },
            {
                title: "Unit Price",
                // width: 150,
                dataIndex: "subscription.unit_price",
                key: "unitPrice",
                render: price => (<>
                    <span>{price} USD</span>
                </>

                ),

            },
            {
                title: <>{this.props.gymDetails.subscription_status === Constant.PADDLE.STATES.DELETED ? <span>Cancellation Date</span> : <span>Next Billing Date</span>}</>,
                // width: 150,
                key: "nextRenewalDate",
                dataIndex: this.props.gymDetails.subscription_status === Constant.PADDLE.STATES.DELETED ? "subscription.cancellation_effective_date" : "subscription.next_bill_date",

            },
            {
                title: "Status",
                //width:300,
                key: 'status',
                dataIndex: 'subscription_status',
                render: tag => (<>
                    {tag === Constant.PADDLE.STATES.DELETED ? <span>Cancelled</span> : <span>Active</span>}
                </>

                ),


            },
        ];
        columns.push({
            title: "Action",
            dataIndex: "",
            key: "b1",
            render: record => (<div className="tableButtons">
                <>
                    {record.subscription_status === Constant.PADDLE.STATES.DELETED ? <Button style={{ margin: "0 10px 10px 0" }}
                        disabled
                        type="danger"
                        ghost
                        size="small"
                    >
                        Cancel Plan
                    </Button> : <Button style={{ margin: "0 10px 10px 0" }}
                            type="danger"
                            ghost
                            size="small"
                            onClick={this.handleCancel}
                        >
                            Cancel Plan
                    </Button>}
                </>

                <>
                    {record.subscription_status === Constant.PADDLE.STATES.DELETED ? <Button style={{ margin: "0 10px 10px 0" }}
                        disabled
                        type="primary"
                        size="small"
                        ghost
                    >
                        Update Billing
                        </Button> : <Button style={{ margin: "0 10px 10px 0" }}
                            type="primary"
                            size="small"
                            ghost
                            onClick={this.handleUpdateBilling}
                        >
                            Update Billing
                        </Button>}
                </>

            </div>


            )
        })
        columns.push({
            title: "Invoice",
            dataIndex: "",
            key: "b2",
            render: record => (<div className="tableButtons">
                <a target="_blank" href={record.subscription.receipt_url} rel="noopener noreferrer" >
                    <Button
                        type="primary"
                        ghost
                    >
                        Invoice
                    </Button>
                </a>

            </div>


            )
        })

        return <>
            {this.props.gymDetails.subscription ? <Table
                bordered
                rowKey={record => record._id}
                columns={columns}
                scroll={{ x: 1120 }}
                dataSource={[this.props.gymDetails]}
                loading={this.props.loading}
                onChange={this.handleChange}
                pagination={false}
            /> : null}

            <ReasonForCancellationModel
                onOk={this.handleOk}
                onCancel={this.handleModalCancel}
                visible={this.state.showCancelModal}
            />


        </>

    }

}


const CurrentPurchaseTable = connect(mapStateToProps, {})(_CurrentPurchaseTable);

export default CurrentPurchaseTable;
