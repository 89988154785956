import React from 'react';
import { Avatar, Menu, Dropdown } from 'antd';
import { NavLink, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import PubSub from 'pubsub-js';

const mapStateToProps = (state) => {
  return {
    gymDetails: state.gymDetails,
    sidebarOpen: false
  }
}

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userFirstLetter: this.props.fir,
      sidebarOpen: false,
      disabledMenuItem: true,
    }
  }

  generateAvatar = (firstName, lastName) => {
    if (firstName && lastName) {
      return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
    }
  }

  componentDidMount() {
    this.checkPermission();
    this.props.history.listen(() => {
      this.setState({
        sidebarOpen: false
      }, () => {
        PubSub.publish('eventSidebar', true);
        document.body.classList.remove("overflow-hidden");
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps !== this.props) {
      this.checkPermission()
    }

  }

  checkPermission = () => {
    if (this.props.gymDetails.expired) {
      this.setState({ disabledMenuItem: true })
    } else {
      this.setState({ disabledMenuItem: false })
    }
  }

  handleSidebarState = () => {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen
    }, () => {
      if (this.state.sidebarOpen) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
      PubSub.publish('eventSidebar');
    });
  }

  render() {
    const { firstName, lastName, gymName } = this.props.gymDetails;
    const { disabledMenuItem } = this.state;
    const menu = (
      <Menu >
        {!disabledMenuItem ? <Menu.Item key="0" >
          <NavLink to="/profile">
            <span>Profile</span>
          </NavLink>
        </Menu.Item> : null}
        <Menu.Item key="1">
        <NavLink to="/billing">
            <span>Billing</span>
          </NavLink>
        </Menu.Item>
        {!disabledMenuItem ? <Menu.Item key="2">
          <NavLink to="/preferences">
            <span>Preferences</span>
          </NavLink>
        </Menu.Item> : null}
        <Menu.Divider />
        <Menu.Item key="3">
          <NavLink to="/logout">
            Logout
        </NavLink>
        </Menu.Item>
      </Menu>
    );
    return <>
      <div className="Header">

        <div className="Gym__Name__Container">
          <div aria-label="Hamburger Menu" onClick={this.handleSidebarState} type="button" className="Hamburger__Icon">
            {this.state.sidebarOpen ?
              <svg className="fill-current w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"></path>
              </svg> : <svg className="fill-current w-4 h-4" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z">
                </path>
              </svg>}
          </div>
          {gymName && <span className="Gym__Name"> {gymName}</span>}
        </div>

        <Dropdown overlay={menu} trigger={['click']}>
          <div style={{ "cursor": "pointer" }}>
            {firstName && lastName && <Avatar style={{ backgroundColor: "#f56a00" }} size="large">{this.generateAvatar(firstName, lastName)}</Avatar>}
            {firstName && <span> {firstName}</span>}
          </div>
        </Dropdown>
      </div>
    </>
  }

}

export default withRouter(connect(mapStateToProps, {})(Header));
