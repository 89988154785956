import axios from "axios"
const tokenKey = "token";

axios.interceptors.response.use(null, error => {
    const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;
    
    // handle server middleware auth service's no token or invalid token response
    if (error.response.status === 401) {
        console.log("redirected to sign in after invalid token")
        if (localStorage.getItem(tokenKey)) {
            localStorage.removeItem(tokenKey);
        }
        else if (sessionStorage.getItem(tokenKey)) {
            sessionStorage.removeItem(tokenKey);
        }
        window.location = "/signin";
    }

    if (error.response.status === 402) {
        console.log("redirected to purchase page")

        if (error.response.data.status === "trialExpired") {
            window.location = "/purchase";
        } else if (error.response.data.status === "purchaseExpired") {
            window.location = "/purchase";
        }


    }


    if (!expectedError) {
        console.log("Logging the error", error);
        alert("An unexpected error occurred");
    }

    return Promise.reject(error)


});


function setJwt(jwt) {
    axios.defaults.headers.common["p-auth-token"] = jwt;
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    setJwt
}


