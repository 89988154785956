import React from "react";
import constants from "../../utils/constants";
import { NavLink, withRouter } from "react-router-dom";
import Utils from "../../utils/utils";
import {
    Icon,
    Table,
    Input,
    Button
} from "antd";
import Highlighter from "react-highlight-words";
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        preferences: state.preferences,
    }
}

class _PaymentsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortedInfo: null,
            filteredInfo: null,
            tableRowCount: null,
            // selectedProfile: {}
        };
    }

    handleChange = (pagination, filters, sorter, currentPageData) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
            tableRowCount: currentPageData.currentDataSource.length
        });
    };

    componentDidMount() {
        this.setState({ tableRowCount: this.props.payments.length });
    }

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null
        });
    };


    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={e =>
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
            </Button>
                    <Button
                        onClick={() => this.handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
            </Button>
                </div>
            ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        )
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
    };


    render() {
        let { sortedInfo } = this.state;
        sortedInfo = sortedInfo || {};       
        const columns = [
            {
                title: "Member ID",
                width: 140,
                dataIndex: "memberId",
                fixed:"left",
                key: "memberId",
                sorter: (a, b) => a.memberId.length - b.memberId.length,
                sortOrder: sortedInfo.columnKey === "memberId" && sortedInfo.order,
                ...this.getColumnSearchProps("memberId")
            },
            {
                title: "Payment ID",
                //width: 150,
                dataIndex: "paymentId",
                key: "paymentId",
                sorter: (a, b) => a.paymentId.length - b.paymentId.length,
                sortOrder: sortedInfo.columnKey === "paymentId" && sortedInfo.order,
                ...this.getColumnSearchProps("paymentId")
            },            
            {
                title: "Name",
                //width: 150,
                dataIndex: "member.name",
                key: "memberName",
                sorter: (a, b) => a.memberName.length - b.memberName.length,
                sortOrder: sortedInfo.columnKey === "memberName" && sortedInfo.order,
                ...this.getColumnSearchProps("memberName")
            },
            {
                title: "Plan Name",
                // width: 150,
                dataIndex: "planName",
                key: "planName",
                ...this.getColumnSearchProps("planName")
            },
            {
                title: "Date",
                // width: 150,
                dataIndex: "date",
                key: "date",
                render: record => (
                    <div>{Utils.convertUtcTimeToLocal(record, this.props.preferences.dateFormat)} </div>
                )

            },
            {
                title: "Discount",
                //width:300,
                dataIndex: 'discount',
                key: 'discount',

            },
            {
                title: `Amount Paid (${this.props.preferences.currencyUnit})`,
                //width: 150,
                dataIndex: "amountPaid",
                key: "amountPaid",
                sorter: (a, b) => a.amountPaid.length - b.amountPaid.length,
                sortOrder: sortedInfo.columnKey === "amountPaid" && sortedInfo.order,                
                ...this.getColumnSearchProps("amountPaid"),
                // render: record => (
                //     <div>{record} {this.props.preferences.currencyUnit}</div>
                // )
            },
        ];

        columns.push({
            title: "",
            dataIndex: "",
            width: 100,
            key: "b1",
            render: record => (
                <NavLink to={`/member/${record.memberId}`} >
                    <Button
                        type="primary"
                    >
                        Profile
                    </Button>
                </NavLink>

            )
        });
       

        return <>

            <Table
                bordered
                rowKey={record => record._id}
                columns={columns}
                scroll={{ x: 1120 }}
                dataSource={this.props.payments}
                onChange={this.handleChange}
                pagination={{
                    pageSizeOptions: constants.tables.pageSizes,
                    showSizeChanger: true
                }}

            />
        </>

    }

}

const PaymentsTable = connect(mapStateToProps, {})(_PaymentsTable);
export default withRouter(PaymentsTable);