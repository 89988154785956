import React from "react";
import PlansTable from "../../components/Tables/PlansTable";
import { getPlans } from "../../services/membershipPlansServices";
import Utils from "../../utils/utils";

import {
    Breadcrumb,
    Divider,
    Spin
} from "antd";

export default class MembershipPlans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plans: [],
            error: false,
            spinning: true
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        try {
            this.setState({
                spinning: true
            }, async () => {
                const response = await getPlans();
                let plans = Utils.combinePeriodPrefix(response.data);
                this.setState({
                    plans: plans,
                    spinning: false
                });
            });
        } catch (ex) {
            console.log(ex);
            if (ex.response && ex.response.status === 400) {
                this.setState({ error: true, spinning: false })
            }
        }
    }



    render() {
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item>                      
                        <span>Membership Plans</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>View Plans</Breadcrumb.Item>
                </Breadcrumb>
                <h3>Membership Plans</h3>
                <Divider />
                {(this.state.spinning === true) ? <Spin size="large" className="spinner" tip="Loading plans..." /> : <div className="plansTable">
                    <PlansTable
                        onRefresh={this.loadData}
                        type="plans"
                        plans={this.state.plans}>
                    </PlansTable>
                </div>}
                <Divider />
            </div>
        );
    }
}
