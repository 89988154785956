import React from 'react';
import { Button, Row, Col, List, Divider, Tag } from "antd";
import Utils from "../../utils/utils";
import { connect } from 'react-redux';
import { setPaymentCollapseKey } from '../../actions/index';
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => {
    return {
        paymentCollapseActiveKey: state.setPaymentCollapseKey,
        preferences: state.preferences,
    }
}

function SubscriptionContent(props) {    
    const { dateFormat, currencyUnit } = props.preferences;
    const { subscription } = props

    return (
        <div className="subscriptionCollapseContent">

            {(!props.disableButtons) ? <Row gutter={24}>
                <Col sm={24} md={24} style={{ textAlign: "right" }} >
                    <Button ghost size="small" type="primary" style={{ marginRight: "10px" }} onClick={() => props.showModal("Update")}>Update</Button>
                    <Button ghost size="small" type="primary" onClick={() => props.showModal("Delete")}>Delete</Button>
                </Col>
            </Row> : null}

            <div style={{ marginTop: "10px", marginBottom: "5px" }}>
                <span>Plan ID: <b>{subscription.planId}</b></span>
                <span>  </span>
                {(subscription.planStatus?.toLowerCase() === "deleted") ? <Tag style={{ marginLeft: "5px" }} color="magenta">Deleted Plan</Tag> : null}
            </div>
            <div className="plan_heading">
                <h4 className="_heading">Plan Name:</h4>
                <span className="_name">{subscription.membershipPlanName}</span>
            </div>
            {/* {(subscription.renew === true) ? <div>* Automatic renewal is active</div> : <div>* Automatic renewal is not active</div>} */}
            <Divider />
            <Row gutter={24}>
                <Col sm={24} md={8}>
                    <div><div><h4>Start Date</h4></div><div>{Utils.convertUtcTimeToLocal(subscription.startDate, dateFormat)}</div></div>
                </Col>
                <Col sm={24} md={8}>
                    <div><div><h4>Expire Date</h4></div><div>{Utils.convertUtcTimeToLocal(subscription.expireDate, dateFormat)}</div></div>
                </Col>
                <Col sm={24} md={8}>
                    <div><div><h4>Duration</h4></div><div>{`${subscription.duration} ${subscription.durationPrefix}`}</div></div>
                </Col>
            </Row>
            <Divider />
            <Row gutter={24}>
                <Col sm={24} md={6}>
                    <div><div><h4>Plan <br />price</h4></div><div>{subscription.price} {currencyUnit}</div></div>
                </Col>
                <Col sm={24} md={6}>
                    <div><div><h4>Discounts <br /> Given</h4></div><div>{subscription.discountsGiven} {currencyUnit}</div></div>
                </Col>
                <Col sm={24} md={6}>
                    <div><div><h4>Paid  <br /> Amount</h4></div><div>{subscription.amountPaid} {currencyUnit}</div></div>
                </Col>                
                <Col sm={24} md={6}>
                    <div><div><h4>Balance <br /> Amount</h4></div><div>{subscription.amountBalance} {currencyUnit}</div></div>
                </Col>
            </Row>
            <Divider />
            <List style={{ marginBottom: "10px" }}
                size="small"
                position="top"
                header={<div><h4>Payments received for this subscription</h4></div>}
                bordered
                dataSource={subscription.paymentIds}
                renderItem={item => <Button style={{ width: "100%", textAlign: "left" }} key={item} type="link" onClick={() => {
                    setTimeout(() => {
                        if (document.querySelector("#paymentCard .ant-card-body") && document.getElementById(item)) {
                            document.querySelector("#paymentCard .ant-card-body").scrollTop = document.getElementById(item).offsetTop - 70;
                        }
                    }, 200);
                    props.setPaymentCollapseKey(item);
                }}>{`Payment ID:- ${item}`}</Button>}
            />
        </div>);
};

export default withRouter(connect(mapStateToProps, { setPaymentCollapseKey })(SubscriptionContent));