import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../services/authService.js"; 
import Preferences from './Preferences';
import AppWrapper from './AppWrapper';


const ProtectedRouteApp = ({ path, component: Component, render, ...props }) => {
  return (
    <Route
      {...props}
      render={props => {
        if (!auth.getCurrentUser())
          return (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: props.location }
              }}
            />
          );
        return Component ? <AppWrapper><Preferences><Component {...props} /></Preferences></AppWrapper> : render(props);
      }}
    />
  );
};

export default ProtectedRouteApp;