import React from "react";
import { Form, Icon, Input, Button, Checkbox, Alert } from "antd";
import { NavLink } from "react-router-dom";
import { signIn } from "../../services/authService";
import Footer from './../../components/Layout/Footer';

class SignInForm extends React.Component {

    state = {
        errors: "",
        isLogin: false
    };

    doSubmit = async (user) => {
        this.setState({ isLogin: true });
        try {
            const response = await signIn(user);
            this.setState({ isLogin: false });
            if (user.remember === true) {
                localStorage.setItem("token", response.data)
            } else {
                sessionStorage.setItem("token", response.data)
            }
            window.location = "/dashboard"
        } catch (ex) {
            this.setState({ isLogin: false });
            if (ex.response && ex.response.status === 400) {
                this.setState({ errors: ex.response.data })
            }
            if (ex.response && ex.response.status === 403) {
                this.props.history.push(`/emailSent?email=${ex.response.data.email}`, ex.response.data)
            }
        }
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, userData) => {
            if (!err) {
                this.doSubmit(userData);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="login-page">
                <div className="form-wrapper">
                    <a href="/"><div className="LogoWrapper">
                        <div className="Logo"></div>
                    </div></a>
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        {(this.state.errors.length !== 0) ? <Alert
                            message="Error"
                            description={this.state.errors}
                            type="error"
                            showIcon
                        /> : null}
                        <Form.Item>
                            {getFieldDecorator('email', {
                                rules: [{ required: true, message: 'Email is required!' }],
                            })(
                                <Input
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    size="large"
                                    placeholder="Email or User Name"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: 'Password is required!' }],
                            })(
                                <Input
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="password"
                                    size="large"
                                    placeholder="Password"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('remember', {
                                valuePropName: 'checked',
                                initialValue: true,
                            })(<Checkbox>Remember me.</Checkbox>)}

                            <NavLink to="/forgottenPassword">
                                Forgot password ?
                            </NavLink>

                            <Button type="primary" size="large" htmlType="submit" disabled={this.state.isLogin} loading={this.state.isLogin} className="login-form-button">
                                Log in
                        </Button>
                            <span>Or </span>
                            <NavLink to="/signup">
                                Register Now
                </NavLink>
                        </Form.Item>
                    </Form>
                </div>
                <Footer isLogOut={true}></Footer>
            </div>
        );
    }
}

const SignInPage = Form.create({ name: 'login_form' })(SignInForm);
export default SignInPage;
