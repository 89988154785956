import React from "react";
import constants from "../../utils/constants";
import { NavLink } from "react-router-dom";
import {
    Icon,
    Table,
    Input,
    Button,
} from "antd";
import Highlighter from "react-highlight-words";
import utils from "../../utils/utils";


export default class AttendanceTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortedInfo: null,
            filteredInfo: null,
            tableRowCount: null,
            attendances: [],

        };
    }

    componentDidMount() {
        this.setState({ tableRowCount: this.props.attendances.length });
    }

    handleChange = (pagination, filters, sorter, currentPageData) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
            tableRowCount: currentPageData.currentDataSource.length
        });
    };

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null
        });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={e =>
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
            </Button>
                    <Button
                        onClick={() => this.handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
            </Button>
                </div>
            ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        )
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    render() {

        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};
        const columns = [
            {
                title: "Member ID",
                width: 140,
                fixed: 'left',
                dataIndex: "memberId",
                key: "memberId",
                sorter: (a, b) => a.memberId.length - b.memberId.length,
                sortOrder: sortedInfo.columnKey === "memberId" && sortedInfo.order,
                ...this.getColumnSearchProps("memberId")
            },
            {
                title: "Name",
                // width: 150,
                key: "name",
                dataIndex: "details.name",
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
                ...this.getColumnSearchProps("name")
            },
            {
                title: "IN / OUT",
                //  width: 150,
                dataIndex: "inOutStatus",
                key: "inOutStatus",
                sorter: (a, b) => a.inOutStatus.length - b.inOutStatus.length,
                sortOrder: sortedInfo.columnKey === "inOutStatus" && sortedInfo.order,
                ...this.getColumnSearchProps("inOutStatus")
            },
            {
                title: "Time",
                dataIndex: "time",
                // width: 200,
                ellipsis: true,
                key: "time",
                sorter: (a, b) => a.time.length - b.time.length,
                sortOrder: sortedInfo.columnKey === "time" && sortedInfo.order,
                ...this.getColumnSearchProps("time")
            },
            {
                title: "Membership Status",
                //width:300,
                key: 'membershipStatus',
                dataIndex: 'details.membershipStatus',
                sorter: (a, b) => a.membershipStatus.length - b.membershipStatus.length,
                sortOrder: sortedInfo.columnKey === "membershipStatus" && sortedInfo.order,
                filters: [{ text: 'ACTIVE', value: 'active' }, { text: 'PENDING', value: 'pending' }, { text: 'EXPIRED', value: 'expired' }, { text: 'NO SUBSCRIPTION', value: 'no subscription' }, { text: 'PAST DUE', value: 'past due' }],
                filteredValue: filteredInfo.membershipStatus || null,
                onFilter: (value, record) => record.membershipStatus.includes(value),
                // ...this.getColumnSearchProps("membershipStatus"),
                render: tag => (
                    <span>
                        {utils.membershipStatusTag(tag)}
                    </span>
                ),
            },
            {
                title: "Payment Status",
                // width:300,
                key: 'paymentStatus',
                dataIndex: 'details.paymentStatus',
                sorter: (a, b) => a.paymentStatus.length - b.paymentStatus.length,
                sortOrder: sortedInfo.columnKey === "paymentStatus" && sortedInfo.order,
                filters: [{ text: 'FULLY PAID', value: 'fully paid' }, { text: 'NOT PAID', value: 'not paid' }, { text: 'PARTIALLY PAID', value: 'partially paid' }],
                filteredValue: filteredInfo.paymentStatus || null,
                onFilter: (value, record) => record.paymentStatus.includes(value),

                // ...this.getColumnSearchProps("paymentStatus"),
                render: tag => (
                    <span>
                        {utils.paymentStatusTag(tag)}
                    </span>
                ),
            },

        ];
        columns.push({
            title: "",
            dataIndex: "",
            key: "b1",
            render: record => (<div className="tableButtons">
                <NavLink to={`/member/${record.memberId}`} >
                    <Button
                        type="primary"
                    >
                        View Profile
                    </Button>
                </NavLink>
                <Button style={{ marginLeft: "5px", marginTop: "5px" }} ghost size="small" icon="delete"
                    type="primary" shape="round" onClick={() => this.props.onDeleteAttendance(record._id)}
                />

            </div>


            )
        })

        return <>

            <Table
                bordered
                rowKey={record => record._id}
                columns={columns}
                scroll={{ x: 1120 }}
                dataSource={this.props.attendances}
                loading={{spinning:this.props.loading,tip:"Loading..."}}
                onChange={this.handleChange}
                pagination={{
                    pageSizeOptions: constants.tables.pageSizes,
                    showSizeChanger: true
                }}
            />
        </>

    }

}
