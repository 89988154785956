import React from "react";
import ReactExport from "react-export-excel";
import { Button } from "antd"
import Utils from "../../utils/utils";
import { connect } from 'react-redux';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const mapStateToProps = (state) => {
    return {
        preferences: state.preferences,
    }
}

class DownloadExcelPayments extends React.Component {
    state = {
        payments: [],
    }


    arrangeDataToExport = (payments) => {
        let _payments = payments;
        if (_payments) {
            _payments = _payments.map((payment) => {
                let date = payment.date;
                payment.date = Utils.convertUtcTimeToLocal(payment.date, this.props.preferences.dateFormat)
                payment.time = Utils.convertUtcTimeToLocal(date, this.props.preferences.timeFormat)
                payment.memberName=payment.member.name
                return payment
            })
            this.setState({
                payments: _payments
            })
        } else {
            return ""
        }

    }

    componentDidMount() {
        this.arrangeDataToExport(this.props.data);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.arrangeDataToExport(this.props.data);
        }
    }

    render() {
        return (
            <ExcelFile
                filename={`Payments - ${this.props.from} to ${this.props.to}`}
                element={<Button ghost type="primary" style={{ marginLeft: "10px" }} shape="round" size="small" icon="download">Export to Excell</Button>}>
                <ExcelSheet data={this.state.payments} name="Payments">
                    <ExcelColumn label="Payment ID" value="paymentId" />
                    <ExcelColumn label="Member ID" value="memberId" />
                    <ExcelColumn label="Member Name" value="memberName" />
                    <ExcelColumn label="Plan Name" value="planName" />
                    <ExcelColumn label="Date" value="date" />
                    <ExcelColumn label="Time" value="time" />
                    <ExcelColumn label="Discount" value="discount" />
                    <ExcelColumn label="Amount Paid" value="amountPaid" />
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export default connect(mapStateToProps, {})(DownloadExcelPayments);
