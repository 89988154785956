import React from "react";
import { PubSub } from 'pubsub-js';
import { connect } from 'react-redux';
import { getStaffMemberDetails } from "../../../services/staffMemberServices";
import StaffPersonalDetailsCard from '../../../components/Cards/StaffPersonalDetailsCard';
import Utils from "../../../utils/utils";
import { withRouter } from 'react-router-dom';
// import { setMembershipId, setProfile, setCurrentSubscription } from '../../../actions/index';
// import MemberAttendanceCalendar from "../../../components/MemberAttendanceCalendar";
import {
  Breadcrumb,
  Result,
  Icon, 
  Row,
  Col,
  Spin,
  Button
} from "antd";

const mapStateToProps = (state) => {
  return {
    membershipId: state.membershipId,
    preferences: state.preferences
  }
}

class MemberPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: "",
      hasProfile: "loading",
    }

  }

  getDetails = async () => {
    const { dateFormat } = this.props.preferences;
    let _response = {};
    try {
      const response = await getStaffMemberDetails(this.props.match.params.staffId)
      _response = response.data;
      _response.dateOfBirth = Utils.convertUtcTimeToLocal(_response.dateOfBirth, dateFormat);
      _response.joiningDate = Utils.convertUtcTimeToLocal(_response.joiningDate, dateFormat);

      this.setState({
        error: false,
        spinning: false,
        profile: _response,
        hasProfile: "true"
      })


    } catch (ex) {
      console.log(ex)
      if (ex.response && ex.response.status === 400) {
        this.setState({ error: true, spinning: false, hasProfile: "false" })
      }
    }
  }

  componentWillUnmount() {
    PubSub.unsubscribe("refreshPage")
  }

  componentDidMount = async () => {
    PubSub.subscribe("refreshPage", async () => {
      await this.getDetails();
    });
    await this.getDetails();
  };

  render() {
    let { profile } = this.state
    let profileBlock = null;

    switch (this.state.hasProfile) {
      case 'true':
        profileBlock = <>
          <Row gutter={16} style={{ margin: "10px" }}>
            <Col sm={24} xl={24} span={24}>
              <StaffPersonalDetailsCard profile={this.state.profile} />
            </Col>
          </Row>
          {/* <Row gutter={16} style={{ margin: "10px" }}>
            <Col sm={24} xl={24} span={8}>
              <MemberAttendanceCalendar attendances={attendances} />
            </Col>
          </Row> */}
        </>;
        break;
      case 'false':
        profileBlock = <Result
          status="403"
          title="400"
          subTitle="Member not found !"
          extra={<Button type="primary">Back to members</Button>}
        />
        break;
      case 'loading':
        profileBlock = <Spin size="large" className="spinner" tip="Loading Profile..." />;
        break;
      default:

    }
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="">
            <Icon type="user" />
            <span>Staff Member</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{this.props.membershipId}</Breadcrumb.Item>
        </Breadcrumb>

        <div className="memberPageDetailsHeader">
          <h3>{profile.name} </h3>
        </div>

        {profileBlock}

      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, {})(MemberPage));