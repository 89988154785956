import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom"

const mapStateToProps = (state) => {
  return {
    gymDetails: state.gymDetails

  }
}

class PurchaseExpiredAlert extends Component {

  constructor(props) {
    super(props);
    this.state = {}

  }

  render() {
    return (
      <div className="Trial__Alert">
        <div>
          <span className="Trial__Alert__Text">Your purchased time period has expired!.</span>  
          <div className="Trial__Alert__SubText">Please purchase a plan to keep using all the features.</div>          
        </div>        
      </div>
    )
  }
}
export default withRouter(connect(mapStateToProps, {})(PurchaseExpiredAlert));