import React from "react";
import constants from "../../utils/constants";
import {deletePlan} from "../../services/membershipPlanServices"
import { NavLink } from "react-router-dom";
import {
    Icon,
    Table,
    Input,
    Button,
    Modal
} from "antd";
import Highlighter from "react-highlight-words";
const { confirm } = Modal;

export default class PlansTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortedInfo: null,
            filteredInfo: null,
            tableRowCount: null,
            plans:[]
            
        };
    }
       
    componentDidMount() {       
       this.setState({ tableRowCount: this.props.plans.length });
    }    

    handleChange = (pagination, filters, sorter, currentPageData) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
            tableRowCount: currentPageData.currentDataSource.length
        });
    };

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null
        });
    };

    handleOk = () => {
        this.setState({ visible: false });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

     showConfirm (planId) {
        confirm({
          title: 'Delete',
          content: 'Do you want to delete this plan?',
          onOk : async()=> {
            await deletePlan(planId)
            this.props.onRefresh();
          },
          onCancel() {},
        });
      }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={e =>
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
            </Button>
                    <Button
                        onClick={() => this.handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
            </Button>
                </div>
            ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        )
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    render() {
        //let { sortedInfo, filteredInfo } = this.state;
        let { sortedInfo } = this.state;
        sortedInfo = sortedInfo || {};
        //filteredInfo = filteredInfo || {};
        const columns = [
            {
                title: "Plan ID",
                width: 140,
                fixed:"left",
                dataIndex: "planId",
                key: "planId",
                sorter: (a, b) => a.planId.length - b.planId.length,
                sortOrder: sortedInfo.columnKey === "planId" && sortedInfo.order,
                ...this.getColumnSearchProps("planId")
            },
            {
                title: "Plan Name",
                width: 220,
                dataIndex: "membershipPlanName",
                key: "membershipPlanName",
                sorter: (a, b) => a.membershipPlanName.length - b.membershipPlanName.length,
                sortOrder: sortedInfo.columnKey === "membershipPlanName" && sortedInfo.order,
                ...this.getColumnSearchProps("membershipPlanName")
            },
            {
                title: "Period",
                dataIndex: "membershipPeriod",
                width: 150,
                // ellipsis: true,
                key: "membershipPeriod",
                sorter: (a, b) => a.membershipPeriod.length - b.membershipPeriod.length,
                sortOrder: sortedInfo.columnKey === "membershipPeriod" && sortedInfo.order,
                ...this.getColumnSearchProps("membershipPeriod")
            },
            {
                title: "Price ($)",
                 width: 150,
                dataIndex: "membershipPrice",
                key: "membershipPrice",
                ...this.getColumnSearchProps("membershipPrice")
            },
            {
                title: "Description",
                width: 300,
                dataIndex: "membershipPlanDescription",
                key: "membershipPlanDescription",

            }

        ];

        columns.push({
            title: "",
            dataIndex: "",
            // width: 100,
            key: "b1",
            render: record => (
                
                <><NavLink to={`/edit_membership_plan/${record._id}`} >
                    <Button type="primary" style={{margin:"5px"}}>
                        Update
                    </Button>
                  </NavLink>
                  <Button style={{margin:"5px"}} type="danger" onClick = {()=>{this.showConfirm(record._id) }} >
                        Delete
                  </Button>  
                </>
            )
        })

        return <>

            <Table
                bordered
                rowKey={record => record._id}
                columns={columns}
                scroll={{ x: 1120 }}
                dataSource={this.props.plans}
               //dataSource={plans1}
                onChange={this.handleChange}
                pagination={{
                    pageSizeOptions: constants.tables.pageSizes,
                    showSizeChanger: true
                }}
            />
        </>

    }

}