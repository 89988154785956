import http from "./httpService";
import Utils from "../utils/utils";

const apiEndpoint = Utils.getApiUrl() + "forgotPassword";


export async function checkResetLink(data) {
    return http.get(apiEndpoint, { params: data });
}

export async function forgotPassword(data) {
    return http.post(apiEndpoint, data);
}

export async function resetPassword(data) {
    let token_ = { token: data.token }
    return http.put(apiEndpoint, data, { params: token_ });
}

export default {
    forgotPassword,
    checkResetLink,
    resetPassword
};

