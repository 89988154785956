import React, { Component } from 'react';
import Utils from "../../utils/utils";

const styles = {
    "header": { "width": "100%", "margin": "20px 0", "background": "rgb(255, 255, 255)", "textAlign": "center", "color": "rgb(0, 0, 0)", "font": "700 15px Helvetica,Sans-Serif", "fontWeight": "bold", "textDecoration": "uppercase", "letterSpacing": "10px", "borderTop": "2px solid rgb(0, 0, 0)", "borderBottom": "2px solid rgb(0, 0, 0)", "padding": "8px 0", "backgroundImage": "initial" },
    "gymName": { "textAlign": "left", "margin": "0", "color": "rgb(0, 0, 0)", "font": "700 24px Helvetica,Sans-Serif", "fontWeight": "bold", "padding": "4px 0" },
    "gymDetails": { "textAlign": "left", "color": "rgb(0, 0, 0)", "font": "14px Helvetica,Sans-Serif", "padding": "4px 0 20px 0", "marginBottom": "16px", "borderBottom": "2px dashed rgb(0, 0, 0)" },
    "memberDataLeft": { "textAlign": "left", "color": "rgb(0, 0, 0)", "font": "15px Helvetica,Sans-Serif", "padding": "4px 0" },
    "memberDataRight": { "textAlign": "right", "color": "rgb(0, 0, 0)", "font": "15px Helvetica,Sans-Serif", "padding": "4px 0" },
    "paymentsColHeading": { "textAlign": "center", "color": "rgb(0, 0, 0)", "font": "15px Helvetica,Sans-Serif", "background": "rgb(240, 240, 240)", "border": "1px solid rgb(0, 0, 0)", "padding": "4px 0" },
    "paymentsColRowDataCenter": { "textAlign": "center", "color": "rgb(0, 0, 0)", "font": "15px Helvetica,Sans-Serif", "border": "1px solid rgb(0, 0, 0)", "padding": "20px 4px" },
    "paymentsColRowDataLeft": { "textAlign": "left", "color": "rgb(0, 0, 0)", "font": "15px Helvetica,Sans-Serif", "border": "1px solid rgb(0, 0, 0)", "padding": "20px 4px" },
    "paymentsColRowDataRight": { "textAlign": "right", "color": "rgb(0, 0, 0)", "font": "15px Helvetica,Sans-Serif", "border": "1px solid rgb(0, 0, 0)", "padding": "20px 4px" },
    "paymentsColRowDataRightMonospace": { "textAlign": "right", "color": "rgb(0, 0, 0)", "font": "15px monospace,Helvetica,Sans-Serif", "border": "1px solid rgb(0, 0, 0)", "padding": "20px 4px" },
    "paymentsColRowDataRightMonospaceBorder": { "textAlign": "right", "color": "rgb(0, 0, 0)", "font": "15px monospace,Helvetica,Sans-Serif", "border": "1px solid rgb(0, 0, 0)", "padding": "4px" },
    "paymentsColRowCenter": { "textAlign": "center", "color": "rgb(0, 0, 0)", "font": "15px Helvetica,Sans-Serif", "padding": "4px" },
    "paymentsColRowLeft": { "textAlign": "left", "color": "rgb(0, 0, 0)", "font": "15px Helvetica,Sans-Serif", "padding": "4px" },
    "paymentsColRowRight": { "textAlign": "right", "color": "rgb(0, 0, 0)", "font": "15px Helvetica,Sans-Serif", "padding": "4px" }
}

export default class PaymentBill extends Component {
    render() {
        const { payment, gymDetails } = this.props;
        const { currencyUnit, dateFormat } = this.props.preferences;

        return (
            < div  >
                <div style={styles.header}>INVOICE</div>
                <div>
                    <p style={styles.gymName}>{gymDetails.gymName}</p>
                    <div style={styles.gymDetails}>
                        <span><b>Email:</b> &nbsp;{gymDetails.email}</span>
                        <span><br /><b>Tel:</b> &nbsp;{gymDetails.phone}</span>
                        <span><br /><b>Address:</b> &nbsp;{gymDetails.address}</span>
                    </div>



                    {/* Member details */}
                    <table style={{ width: "100%", marginBottom: "20px" }}>
                        <tbody>
                            <tr>
                                <td style={styles.memberDataLeft}>Payment ID: &nbsp; {payment.paymentId}</td>
                                <td style={styles.memberDataRight}>Date: &nbsp; {Utils.convertUtcTimeToLocal(payment.date, dateFormat)}</td>
                            </tr>
                            <tr>
                                <td style={styles.memberDataLeft}>Mr./Miss./Mrs.: &nbsp; {payment.member.name}</td>
                                <td style={styles.memberDataRight}>Member ID: &nbsp; {payment.memberId}</td>
                            </tr>
                            <tr>
                                <td style={styles.memberDataLeft}>Phone: &nbsp; {payment.member.phone}</td>
                            </tr>
                            <tr>
                                <td style={styles.memberDataLeft}>Address: &nbsp; {payment.member.address}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* Billing details */}
                <table style={{ width: "100%", marginBottom: "20px", borderCollapse: "collapse", border: "1px solid rgb(0, 0, 0)" }}>
                    <thead>
                        <tr>
                            <th style={styles.paymentsColHeading}>No</th>
                            <th style={styles.paymentsColHeading}>Plan Name</th>
                            <th style={styles.paymentsColHeading}>Details</th>
                            <th style={styles.paymentsColHeading}>Amount ({currencyUnit})</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={styles.paymentsColRowDataCenter}>1</td>
                            <td style={styles.paymentsColRowDataLeft}>{payment.planName}</td>
                            <td style={styles.paymentsColRowDataLeft}>From: &nbsp;{Utils.convertUtcTimeToLocal(payment.startDate, dateFormat)} <br />To: &nbsp;{Utils.convertUtcTimeToLocal(payment.expireDate, dateFormat)}</td>
                            <td style={styles.paymentsColRowDataRightMonospace}>{Utils.toPrice(payment.planPrice)}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td style={styles.paymentsColRowRight}>Total</td>
                            <td style={styles.paymentsColRowDataRightMonospaceBorder}>{Utils.toPrice(payment.planPrice)}</td>
                        </tr>
                        <tr>
                            <td ></td>
                            <td ></td>
                            <td style={styles.paymentsColRowRight}>Previous Balance</td>
                            <td style={styles.paymentsColRowDataRightMonospaceBorder}>{Utils.toPrice(payment.planBalanceAmount)}</td>
                        </tr>
                        <tr>
                            <td ></td>
                            <td ></td>
                            <td style={styles.paymentsColRowRight}>Discount</td>
                            <td style={styles.paymentsColRowDataRightMonospaceBorder}>{Utils.toPrice(-1 * payment.discount)}</td>
                        </tr>
                        <tr>
                            <td ></td>
                            <td ></td>
                            <td style={styles.paymentsColRowRight}>Final Total</td>
                            <td style={styles.paymentsColRowDataRightMonospaceBorder}>{Utils.toPrice(payment.amountToBePaid)}</td>
                        </tr>
                        <tr>
                            <td ></td>
                            <td ></td>
                            <td style={styles.paymentsColRowRight}>Amount Paid</td>
                            <td style={styles.paymentsColRowDataRightMonospaceBorder}>{Utils.toPrice(payment.amountPaid)}</td>
                        </tr>
                        <tr>
                            <td ></td>
                            <td ></td>
                            <td style={styles.paymentsColRowRight}>Balance Due</td>
                            <td style={styles.paymentsColRowDataRightMonospaceBorder}>{Utils.toPrice(payment.amountToBePaid - payment.amountPaid)}</td>
                        </tr>
                    </tbody>
                </table>

                <div>
                    <table style={{ width: "100%", marginBottom: "20px" }}>
                        <tbody>
                            <tr>
                                <td style={styles.memberDataLeft}>Payment Type: &nbsp; Cash</td>
                            </tr>
                            <tr>
                                <td style={styles.memberDataLeft}>Received By: </td>
                                <td style={{ ...styles.memberDataRight, float: "right" }}>
                                    <div style={{ textAlign: "center" }}>-----------------------</div>
                                    <div style={{ textAlign: "center" }}>Signature</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </div >
        )
    }
}
