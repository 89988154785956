import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Result} from 'antd';

class NotFoundPage extends React.Component {

    render() {
        return (
            <div className="NotFound">
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                // extra={<Button type="primary">Back Home</Button>}
                />
            </div>
        )
    }
}

export default withRouter(connect(null, {})(NotFoundPage));
