import React from "react";
import {
  Divider,
  Breadcrumb,
  Input,
  Row, Col, 
  Button,
  message,
  Form, 
  Modal
} from "antd";
import Clock from '../../components/Utils/Clock';
import moment from "moment"
import { addAttendance, getAttendanceDetails, attendanceDelete } from "../../services/attendanceServices"
import Utils from "../../utils/utils";
import AttendanceTable from "../../components/Tables/attendanceTable"
import { connect } from 'react-redux';
import _ from "lodash"

const { confirm } = Modal;

const mapStateToProps = (state) => {
  return {
    preferences: state.preferences
  }
}

class TakeAttendance extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      attendances: [],
      dates: {},
      tableLoading:true,
    }
  }


  init = async () => {
    const { dateFormat, timeFormat } = this.props.preferences;
    let data = {};

    let _timeStamp = moment().utc().startOf('day').format("X");
    data.type = "today";
    data.timeStamp = _timeStamp;

    try {
      let response = await getAttendanceDetails(data);
      response.data.forEach((attendance) => {
        attendance.date = Utils.convertUtcTimeToLocal(attendance.date_time, dateFormat);
        attendance.time = Utils.convertUtcTimeToLocal(attendance.date_time, timeFormat);
      })

      this.setState({
        attendances: response.data,
        tableLoading:false,
      });
    } catch (ex) {
      console.log("Init-attendance page")
    }
  }


  componentDidMount = () => {
    this.init();


  }


  doSubmit = async (_memberId, inOutStatus) => {
    const memberId = `M-${_memberId}`;
    try {
      let data = {
        inOutStatus: inOutStatus.toUpperCase(),
        date_time: Utils.convertLocalTimeToUtc(moment()),
        timeStamp: moment().format("X"),
      }
      let response = await addAttendance(memberId, data);
      message.success(response.data.statusText, 5);
      this.props.form.resetFields();
      this.init();
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        message.error(ex.response.data, 5);
      }
    }

  };

  componentWillUnmount() {

  }


  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, memberId) => {
      if (!err) {
        this.doSubmit(memberId);
      }
    });
  };

  handleClick = (inOutStatus) => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.doSubmit(values.memberId, inOutStatus);
      }
    });
  }

  handleDeleteAttendance = async (id) => {
    try {
      confirm({
        title: 'Delete',
        content: 'Do you want to delete this attendance?',
        onOk: async () => {
          const response = await attendanceDelete(id)
          message.success(response.data, 5);
          this.init();
        },
        onCancel() { },
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        message.error(ex.response.data, 5);
      }
    }
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>            
            <span>Attendance / Take Attendance</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <h3>Take Attendance</h3>
        <Divider />

        <Row gutter={24}>
          <Col sm={24} md={24}>
            <Clock className="clock" />
          </Col>
        </Row>
        <Divider />
        <div className="attendance-page">

          <div className="infoPanel">
           
          </div>

          <div>
            <Form onSubmit={this.handleSubmit} className="attendance-form">
              <Form.Item>
                {getFieldDecorator('memberId', {
                  rules: [{ required: true, message: 'Member ID is required!' }],
                })(
                  <Input
                    type="number"
                    size="large"
                    addonBefore="M-"
                    placeholder="0145"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                <div className="inOutButtons">
                  <Button.Group size="large" style={{ width: "100%" }} >
                    <Button style={{ width: "50%" }} type="primary" ghost icon="arrow-down" onClick={() => this.handleClick("IN")} >IN</Button>
                    <Button style={{ width: "50%" }} type="primary" ghost icon="arrow-up" onClick={() => this.handleClick("OUT")} >OUT</Button>
                  </Button.Group>
                </div>
              </Form.Item>
            </Form>

          </div>
        </div>
        <div className="attendanceTable" >
          <h2>Today Attendance - ({_.uniqBy(this.state.attendances, "memberId").length})</h2>
          <AttendanceTable loading={this.state.tableLoading} attendances={this.state.attendances} onDeleteAttendance={this.handleDeleteAttendance} />
        </div>

      </div>
    );
  }
}

const TakeAttendancePage = connect(mapStateToProps, {})(Form.create({ name: 'attendance_form' })(TakeAttendance));
export default TakeAttendancePage;