import http from "./httpService";
import jwtDecode from "jwt-decode";
import Utils from "../utils/utils";

const apiEndpoint = Utils.getApiUrl() + "auth";
const tokenKey = "token";

http.setJwt(getJwt());

export async function signIn(user) {
    return http.post(apiEndpoint, {
        email: user.email,
        password: user.password
    });
}

export async function logoutFromServer() {
    return http.put(apiEndpoint);

}

export function getCurrentUser() {
    try {
        if (localStorage.getItem(tokenKey)) {
            const jwt = localStorage.getItem(tokenKey);
            return jwtDecode(jwt);
        }
        else {
            const jwt = sessionStorage.getItem(tokenKey);
            return jwtDecode(jwt);
        }

    } catch (ex) {
        return null;
    }
}

export function getJwt() {

    try {
        if (localStorage.getItem(tokenKey)) {
            return localStorage.getItem(tokenKey);
        }
        else {
            return sessionStorage.getItem(tokenKey);
        }
    } catch (ex) {
        return null;
    }

}

export function logout() {

    if (localStorage.getItem(tokenKey)) {
        localStorage.removeItem(tokenKey);
    }
    else {
        sessionStorage.removeItem(tokenKey);
    }
}

export default {
    signIn,
    logoutFromServer,
    logout,
    getCurrentUser,
    getJwt,  
};

