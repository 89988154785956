import React from "react";
import StatBar from "../../components/Utils/MembersPageStatBar.js";
import {
  Breadcrumb,
  Spin,
  Divider,
  Tabs,
  Button
} from "antd";
import MembersTable from "../../components/Tables/MembersTable";
import { getMembers } from "../../services/membersServices";
import Utils from "../../utils/utils.js";
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import _ from "lodash";
import ExpireMemberCalendar from "../../components/Calendars/ExpireMemberCalendar"

const { TabPane } = Tabs;

const mapStateToProps = (state) => {
  return {
    preferences: state.preferences
  }
}


class CustomerPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      members: [],
      registeredMembers: [],
      deactivatedMembers: [],
      totalRegisteredMembers: "",
      membershipActiveMembers: "",
      membershipPastDueMembers: "",
      error: false,
      spinning: true,
      tabActiveKey: "1",
    };

  }

  async loadData() {
    try {
      this.data = await getMembers()
      this.processData(this.data);

    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 400) {
        this.setState({ error: true, spinning: false })
      }

    }

  }
  processData(response) {
    const { dateFormat } = this.props.preferences;

    let _members = Utils.combineMemberStatus(response.data.members)
    let _deactivatedMembers = _.filter(_members, { deactivated: true })
    let _registeredMembers = _.filter(_members, { deactivated: false })

    let _totalRegisteredMembers = _registeredMembers.length;

    let _activeMembersArray = _.filter(_registeredMembers, { currentSubscription: { status: "active" } })
    let _activeMembers = _activeMembersArray.length;

    let _pastDueMembersArray = _.filter(_registeredMembers, { currentSubscription: { status: "past due" } })
    let _pastDueMembers = _pastDueMembersArray.length;

    _registeredMembers = _registeredMembers.map((member) => {
      member.joiningDate = Utils.convertUtcTimeToLocal(member.joiningDate, dateFormat);
      member.lastAttendance = Utils.timeStampToLocalTime(member.lastAttendance, dateFormat);
      if (member.currentSubscription) {
        member.monthYear = Utils.convertUtcTimeToLocal(member.currentSubscription.expireDate, "YYYY-MM");
        member.currentSubscription.expireDate = Utils.convertUtcTimeToLocal(member.currentSubscription.expireDate, dateFormat);
      } else {
        member.currentSubscription = {};
        member.currentSubscription.status = "no subscription";
      }
      return member;
    });

    _deactivatedMembers = _deactivatedMembers.map((member) => {
      member.joiningDate = Utils.convertUtcTimeToLocal(member.joiningDate, dateFormat);
      member.lastAttendance = Utils.timeStampToLocalTime(member.lastAttendance, dateFormat);
      if (member.currentSubscription) {
        member.monthYear = Utils.convertUtcTimeToLocal(member.currentSubscription.expireDate, "YYYY-MM");
        member.currentSubscription.expireDate = Utils.convertUtcTimeToLocal(member.currentSubscription.expireDate, dateFormat);
      } else {
        member.currentSubscription = {};
        member.currentSubscription.status = "no subscription";
      }
      return member;
    });

    this.setState({
      members: _registeredMembers,
      deactivatedMembers: _deactivatedMembers,
      totalRegisteredMembers: _totalRegisteredMembers,
      membershipActiveMembers: _activeMembers,
      membershipPastDueMembers: _pastDueMembers,
      spinning: false
    })

  }

  handleTabChange = (key) => {
    this.setState({ tabActiveKey: key })
  }

  componentDidMount() {
    this.loadData();
    if (this.props.location.state?.tabActiveKey === "3" && this.props.location.state) {
      this.setState({ tabActiveKey: this.props.location.state.tabActiveKey })
    } else {
      this.setState({ tabActiveKey: "1" })
    }
    this.props.history.push(`/members`, { tabActiveKey: "1" });
  }

  render() {
    let { totalRegisteredMembers, membershipActiveMembers, membershipPastDueMembers } = this.state
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>         
            <span>Members</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>View Members</Breadcrumb.Item>
        </Breadcrumb>
        <h3>Members</h3>

        <div>

          {(this.state.spinning === true) ? <Spin size="large" className="spinner" tip="Loading Members..." /> : <div>
            <Tabs tabBarGutter={5} type="card" activeKey={this.state.tabActiveKey} onChange={this.handleTabChange}>
              <TabPane tab="Members" key="1">
                {this.state.members.length > 0 ? <>
                  <StatBar totalRegisteredMembers={totalRegisteredMembers} membershipActiveMembers={membershipActiveMembers} membershipPastDueMembers={membershipPastDueMembers} />
                  <Divider />
                  <div className="CustomersPage">
                    <MembersTable
                      type="members"
                      members={this.state.members}>
                    </MembersTable>
                  </div></> : <div style={{ marginTop: "20px" }}><div>There are no members to show. <NavLink to="/memberRegister">
                    <Button style={{ marginLeft: "5px" }} type="primary" size="small" ghost>Add Members</Button>
                  </NavLink></div></div>
                }
              </TabPane>
              <TabPane tab="Membership Expiring Members" key="2">
                <div className="CustomersPage">
                  <ExpireMemberCalendar
                    members={this.state.members}>
                  </ExpireMemberCalendar>
                </div>
              </TabPane>
              <TabPane tab="Deactivated Members" key="3">
                <div className="CustomersPage">
                  <MembersTable
                    type="deactivated"
                    members={this.state.deactivatedMembers}>
                  </MembersTable>
                </div>
              </TabPane>
            </Tabs>


          </div>}

        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, {})(CustomerPage));
