import React from "react";
import ChartComponent from "../../components/Charts/PaymentChart";
import moment from "moment";
import { getDashboardPaymentsDetails, getDashboardMembersDetails, getDashboardAttendanceDetails } from "../../services/dashboardServices.js";
import { Card, Statistic, Row, Col, Spin } from 'antd';
import _ from "lodash"
import Utils from "../../utils/utils"
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AttendanceChart from "../../components/Charts/AttendanceChart";

const mapStateToProps = (state) => {
  return {
    preferences: state.preferences
  }
}


class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spinning: true,
      chartArray: [],
      income: "",
      attendances: [],
      attendanceLoading: true,
      paymentLoading: true,
      membersLoading: true,
      registeredMembers: "",
      deactivatedMembers: "",
      activeMembers: "",
      pastDueMembers: "",
      pendingMembers: "",
      noSubscriptionMembers: "",
      fullyPaidMembers: "",
      notPaidMembers: "",
      partiallyPaidMembers: "",
    };
  }

  componentDidMount() {
    this.getPaymentData()
    this.getMembersData()
    this.getAttendanceData()
  }

  getPaymentData = async () => {
    let data = {};
    data.type = "pastMonth";
    data.from = moment().utc().subtract(1, 'months').startOf('day').format("X");
    data.to = moment().utc().endOf('day').format("X");
    try {
      let response = await getDashboardPaymentsDetails(data);
      this.setState({ paymentLoading: false, chartArray: response.data.chartDataArray, income: response.data.total });
    } catch (ex) {
      console.log("ex", ex)
    }
  }

  getMembersData = async () => {

    try {
      let response = await getDashboardMembersDetails();
      let _members = response.data

      let _deactivatedMembers = _.filter(_members, { deactivated: true }).length
      let _registeredMembers = _.filter(_members, { deactivated: false })

      _registeredMembers = _registeredMembers.map((member) => {
        if (!member.currentSubscription) {
          member.currentSubscription = {};
          member.currentSubscription.status = "no subscription";
        }
        return member;
      });

      let _totalRegisteredMembers = _registeredMembers.length;

      let _activeMembersArray = _.filter(_registeredMembers, { currentSubscription: { status: "active" } })
      let _activeMembers = _activeMembersArray.length;

      let _pastDueMembersArray = _.filter(_registeredMembers, { currentSubscription: { status: "past due" } })
      let _pastDueMembers = _pastDueMembersArray.length;

      let _pendingMembersArray = _.filter(_registeredMembers, { currentSubscription: { status: "pending" } })
      let _pendingMembers = _pendingMembersArray.length;

      let _noSubscriptionMembersArray = _.filter(_registeredMembers, { currentSubscription: { status: "no subscription" } })
      let _noSubscriptionMembers = _noSubscriptionMembersArray.length;

      let _fullyPaidMembersArray = _.filter(_registeredMembers, { currentSubscription: { paymentStatus: "fully paid" } })
      let _fullyPaidMembers = _fullyPaidMembersArray.length;

      let _notPaidMembersArray = _.filter(_registeredMembers, { currentSubscription: { paymentStatus: "not paid" } })
      let _notPaidMembers = _notPaidMembersArray.length;

      let _partiallyPaidMembersArray = _.filter(_registeredMembers, { currentSubscription: { paymentStatus: "partially paid" } })
      let _partiallyPaidMembers = _partiallyPaidMembersArray.length;


      this.setState({
        registeredMembers: _totalRegisteredMembers,
        deactivatedMembers: _deactivatedMembers,
        activeMembers: _activeMembers,
        pastDueMembers: _pastDueMembers,
        pendingMembers: _pendingMembers,
        noSubscriptionMembers: _noSubscriptionMembers,
        fullyPaidMembers: _fullyPaidMembers,
        notPaidMembers: _notPaidMembers,
        partiallyPaidMembers: _partiallyPaidMembers,
        membersLoading: false,
      });

    } catch (ex) {
      console.log("ex", ex)
    }
  }

  getAttendanceData = async () => {
    let data = {};
    data.type = "all";
    data.from = moment().utc().subtract(1, 'months').startOf('day').format("X");
    data.to = moment().utc().endOf('day').format("X");
    try {
      let response = await getDashboardAttendanceDetails(data);

      this.setState({
        attendances: response.data.chartDataArray,
        attendanceLoading: false,
      });
    } catch (ex) {
      console.log("Init-attendance page", ex)
    }
  }


  render() {
    const { currencyUnit } = this.props.preferences
    return (
      <div>
        <h3>Dashboard</h3>
        <div style={{ position: "relative" }}>
          {(this.state.membersLoading) ? <div > <Spin tip="Loading Dashboard..." size="large" className="spinner" /></div> : <>
            <Card style={{ width: "100%", marginBottom: "10px", marginTop: "10px" }} >
              <div className="customers_statBar" >
                <Row gutter={24}>
                  <Col sm={24} md={8}>
                    <Statistic title="Total Registered Members" value={this.state.registeredMembers} />
                  </Col>
                  <Col sm={24} md={16}>
                    <Statistic title="Deactivated Members" value={this.state.deactivatedMembers} />
                  </Col>
                </Row>
              </div>
            </Card>

            <Row >
              <Col xs={24} span={8}>
                <Card title="Membership Status Details" style={{ width: "100%", marginBottom: "10px", marginTop: "10px" }} >

                  <div className="customers_statBar" >
                    <Row gutter={24} style={{ marginBottom: "15px" }}>
                      <Col sm={24} md={12} lg={6}>
                        <Statistic title={<>{Utils.membershipStatusTag("active")}Members</>} value={this.state.activeMembers} />
                      </Col>
                      <Col sm={24} md={12} lg={6}>
                        <Statistic title={<>{Utils.membershipStatusTag("pending")}Members</>} value={this.state.pendingMembers} />
                      </Col>
                      <Col sm={24} md={12} lg={6}>
                        <Statistic title={<>{Utils.membershipStatusTag("past due")}Members</>} value={this.state.pastDueMembers} />
                      </Col>
                      <Col sm={24} md={12} lg={6}>
                        <Statistic title={<>{Utils.membershipStatusTag("no subscription")}Members</>} value={this.state.noSubscriptionMembers} />
                      </Col>
                    </Row>
                  </div>

                </Card>
              </Col>
            </Row>
            <Row >
              <Col xs={24} span={8}>
                <Card title="Payments Status Details" style={{ width: "100%", marginBottom: "10px", marginTop: "10px" }} >

                  <div className="customers_statBar" >
                    <Row gutter={24} style={{ marginBottom: "15px" }}>
                      <Col sm={24} md={12} lg={8}>
                        <Statistic title={<>{Utils.paymentStatusTag("fully paid")}Members</>} value={this.state.fullyPaidMembers} />
                      </Col>
                      <Col sm={24} md={12} lg={8}>
                        <Statistic title={<>{Utils.paymentStatusTag("partially paid")}Members</>} value={this.state.partiallyPaidMembers} />
                      </Col>
                      <Col sm={24} md={24} lg={8}>
                        <Statistic title={<>{Utils.paymentStatusTag("not paid")}Members</>} value={this.state.notPaidMembers} />
                      </Col>
                    </Row>

                  </div>

                </Card>
              </Col>
            </Row>

            <Row >
              <Col xs={24} xl={12} span={8}>
                <Card title="Attendance" style={{ width: "100%", marginBottom: "10px", marginTop: "10px" }} extra={<Statistic style={{ visibility: "hidden" }} title="Past 30 Days" value={0} />}>
                  <AttendanceChart data={this.state.attendances} height={200} width="100%" />
                </Card>
              </Col>
              <Col xs={24} xl={12} span={8}>
                <Card title="Income" style={{ width: "100%", marginBottom: "10px", marginTop: "10px" }} extra={<Statistic title="Past 30 Days" precision={2} value={this.state.income} suffix={currencyUnit} />}>
                  <ChartComponent data={this.state.chartArray} height={200} width="100%" />
                </Card>
              </Col>
            </Row>
          </>}
        </div>
      </div >);
  }
}

export default withRouter(connect(mapStateToProps, {})(DashboardPage));