import Constants from './../utils/constants';

export function setUserName(payload) {
    return {
        type: Constants.redux.actions.SET_USER_NAME,
        payload
    }
};
export function setProfile(payload) {
    return {
        type: Constants.redux.actions.SET_PROFILE,
        payload
    }
};
export function setCurrentSubscription(payload) {
    return {
        type: Constants.redux.actions.SET_CURRENT_SUBSCRIPTION,
        payload
    }
};
export function setMembershipId(payload) {
    return {
        type: Constants.redux.actions.SET_MEMBERSHIP_ID,
        payload
    };
};
export function setPaymentCollapseKey(payload) {
    return {
        type: Constants.redux.actions.SET_PAYMENT_COLLAPSE_KEY,
        payload
    }
};
export function setPreferences(payload) {
    return {
        type: Constants.redux.actions.SET_PREFERENCES,
        payload
    }
};
export function setGymDetails(payload) {
    return {
        type: Constants.redux.actions.SET_GYM_DETAILS,
        payload
    }
};




