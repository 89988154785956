import React from "react";
import { setPreferences } from "../../actions/index";
import {
    Form,
    Divider,   
} from 'antd';
import { connect } from 'react-redux';
import CurrentPurchaseTable from './../../components/Tables/CurrentPurchaseTable';

const mapStateToProps = (state) => {
    return {
        preferences: state.preferences,
        gymDetails: state.gymDetails
    }
}

class _BillingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() { }

    render() {

        return (
            <div>
                <h3>Billing</h3>
                <Divider />
                <div style={{marginBottom:"15px"}}><h3>Current Plan Details</h3></div>
                {this.props.gymDetails.paymentStatus===1?<CurrentPurchaseTable />:<div>There is no plan details.</div>}
            </div>

        );
    }
}

const BillingPage = connect(mapStateToProps, { setPreferences })(Form.create({ name: 'billing_page' })(_BillingPage));

export default BillingPage;

