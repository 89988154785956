import React from "react";
import { setPreferences } from "../../actions/index";
import {
    Form,
    Divider,
    message,
    Button
} from 'antd';
import { connect } from 'react-redux';
import Constants from '../../utils/constants';

const mapStateToProps = (state) => {
    return {
        preferences: state.preferences,
        gymDetails: state.gymDetails
    }
}

class _PurchasePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleBuyMonthlyProPlan = () => {
        if ((this.props.gymDetails.paymentStatus !== 1) ||
            (this.props.gymDetails.paymentStatus === 1 && this.props.gymDetails.subscription_status === Constants.PADDLE.STATES.DELETED)) {
            window.Paddle.Setup({
                vendor: Constants.PADDLE.VENDOR_ID
            });
            window.Paddle.Checkout.open({
                product: Constants.PADDLE.MONTHLY_PLAN_PRODUCT_ID,
                email: this.props.gymDetails.email,
                success: "/billing?subscription=success",
                passthrough: JSON.stringify({
                    upgraded_by_gym_email: this.props.gymDetails.email,
                    upgraded_by_gym_id: this.props.gymDetails._id,
                    upgraded_by_gym_name: this.props.gymDetails.gymName,
                    product: "GMS"
                })
            });
        } else {
            message.error("You can not upgrade to a subscription plan at the moment.", 5);
        }
    }

    handleBuyYearlyProPlan = () => {
        if ((this.props.gymDetails.paymentStatus !== 1) ||
            (this.props.gymDetails.paymentStatus === 1 && this.props.gymDetails.subscription_status === Constants.PADDLE.STATES.DELETED)) {
            window.Paddle.Setup({
                vendor: Constants.PADDLE.VENDOR_ID
            });
            window.Paddle.Checkout.open({
                product: Constants.PADDLE.YEARLY_PLAN_PRODUCT_ID,
                email: this.props.gymDetails.email,
                success: "/billing?subscription=success",
                passthrough: JSON.stringify({
                    upgraded_by_gym_email: this.props.gymDetails.email,
                    upgraded_by_gym_id: this.props.gymDetails._id,
                    upgraded_by_gym_name: this.props.gymDetails.gymName,
                    product: "GMS"
                })
            });
        } else {
            message.error("You can not upgrade to a subscription plan at the moment.", 5);
        }
    }

    componentDidMount() { }

    render() {

        return (
            <div>
                <h3>Purchase Jim Partner</h3>
                <Divider />

                <div className="Pricing__Container">

                    <div className="Pricing__Container__blocks">
                        <div className="Pricing__Container-block FirstBlock">
                            <h3>Monthly Plan</h3>
                            <p>Billed monthly</p>
                            <p className="Pricing__Container-block-price">{Constants.PADDLE.PRICES.MONTHLY}</p>
                            <p className="Pricing__Container-block-desc">Per month when paid monthly</p>
                            <Button type="primary" onClick={this.handleBuyMonthlyProPlan}>Buy Now</Button>
                            <p>Cancel anytime</p>
                        </div>
                        <div className="Pricing__Container-block SecondBlock">
                            <h3>Yearly Plan</h3>
                            <p>Billed yearly</p>
                            <p className="Pricing__Container-block-price">{Constants.PADDLE.PRICES.YEARLY}</p>
                            <p className="Pricing__Container-block-desc">Per month when paid yearly</p>
                            <Button type="primary" onClick={this.handleBuyYearlyProPlan}>Buy Now</Button>
                            <p>Cancel anytime</p>
                            <div className="Pricing__Container__Save">
                                Save $24
                            </div>
                        </div>
                    </div>

                    <p className="Pricing__Container-subtitle">*Prices are in USD. Local taxes are not included.</p>

                    <div className="Features">
                        <h3>Features</h3>

                        <div className="Features__Container__blocks">
                            <div className="FirstBlock">
                                <p>Access Gym from anywhere</p>
                                <p>Handle unlimited members</p>
                                <p>Detailed dashboard</p>
                                <p>No particular hardware is needed</p>
                                <p>Automatically check and notify subscription and payment status</p>
                            </div>
                            <div className="SecondBlock">
                                <p>Membership management</p>
                                <p>Attendance management</p>
                                <p>Payment Management</p>
                                <p>Staff members management</p>
                                <p>Invoice for payments</p>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        );
    }
}

const PurchasePage = connect(mapStateToProps, { setPreferences })(Form.create({ name: 'purchase_page' })(_PurchasePage));

export default PurchasePage;

