import http from "./httpService";
import Utils from "../utils/utils";

const apiEndpoint = Utils.getApiUrl() + "attendance";

function attendanceUrl(id) {
    return `${apiEndpoint}/${id}`;
}

export function getMemberAttendanceDetails(memberId) {
    return http.get(attendanceUrl(memberId));
}
export function getAttendanceDetails(data) {
    return http.get(apiEndpoint,{params:data});
}

export async function addAttendance(memberId,data) {
        return http.post(attendanceUrl(memberId),data);}

export async function attendanceUpdate(memberId) {
    return http.put(attendanceUrl(memberId));
}
export async function attendanceDelete(memberId) {
    return http.delete(attendanceUrl(memberId));
}

export default {
    getAttendanceDetails,
    addAttendance,
    attendanceUpdate,
    attendanceDelete
};

