import React from "react";
import { PubSub } from 'pubsub-js';
import Webcam from "react-webcam";

import {
    Modal,
    Button,
    Spin
} from "antd";

export default class Camera extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            hasUserMedia: false
        };
    }

    openModal = () => {
        this.setState({
            visible: true
        });
        //console.log("Modal Open");
    }

    closeModal = () => {
        this.setState({
            visible: false,
            hasUserMedia: false
        });
    }

    componentDidMount() {
        PubSub.subscribe('openCamera', () => {
            this.openModal();
        });
        PubSub.subscribe('closeCamera', () => {
            this.closeModal();
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe('openCamera');
        PubSub.unsubscribe('closeCamera');
    }

    handleOk = () => {
        if (this.props.onTakePhoto && typeof this.props.onTakePhoto === "function") {
            const profilePhoto = this.refs["webcam"].getScreenshot();
            this.props.onTakePhoto({
                profilePhoto
            });
        }
        this.refs["webcam"].stopAndCleanup();
        this.closeModal();
    }

    handleUserMedia = () => {
        this.setState({
            hasUserMedia: true
        })
    }


    render() {

        const videoConstraints = {
            width: 800,
            height: 450,
            facingMode: "user"
        };

        return (
            <div className="camera__modal">
                <Modal
                    wrapClassName="camera__modal"
                    title={this.props.title}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.closeModal}
                    width={720}
                    footer={[
                        <Button key="Submit" type="primary" onClick={this.handleOk}>
                            Capture
                    </Button>,
                        <Button key="Close" type="secondary" onClick={this.closeModal}>
                            Close
                    </Button>
                    ]}>

                    <div style={{ height: "450px", backgroundColor: "black" }}>
                        {this.state.visible ? <div className="webcam--wrapper">
                            <Webcam
                                audio={false}
                                height={450}
                                ref="webcam"
                                onUserMedia={this.handleUserMedia}
                                screenshotFormat="image/jpeg"
                                width={"100%"}
                                videoConstraints={videoConstraints} />
                            {this.state.hasUserMedia ? null : <Spin size="large" />}
                        </div> : null}
                    </div>


                </Modal>
            </div>
        );
    }

}