import React from "react";
import { Form, Input, Button, Result, Alert } from "antd";
import { forgotPassword } from "../../services/forgotPasswordServices";
import Footer from './../../components/Layout/Footer';

class ForgotPassword extends React.Component {

    state = {
        errors: "",
        buttonDisabled: false,
        emailSent: false,
        email: "",
        messageText: ""

    };

    doSubmit = async (user) => {
        try {
            const response = await forgotPassword({ email: user.email });
            this.setState({ buttonDisabled: false });
            if (response.data.emailSent === true) {
                this.setState({
                    emailSent: true,
                    email: user.email,
                    messageText: `Email has been sent to ${user.email} with password reset link`
                })
            }

        } catch (ex) {
            this.setState({ buttonDisabled: false });
            if (ex.response && ex.response.status === 400) {
                this.setState({ errors: ex.response.data })
            }
        }

    };


    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, userData) => {
            if (!err) {
                this.setState({ buttonDisabled: true }, () => {
                    this.doSubmit(userData);
                })

            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="login-page">
                <div className="form-wrapper">
                    <a href="/"><div className="LogoWrapper">
                        <div className="Logo"></div>
                    </div></a>
                    {this.state.emailSent !== true && <>
                        <Form onSubmit={this.handleSubmit} className="login-form">
                            <h2 style={{ padding: "0", margin: "0", textAlign: "center" }}>Forgot Password</h2>
                            <div style={{ padding: "12px 0" }}>To reset your password, enter your email below and submit. An email will be sent to you with instructions about how to complete the process.</div>

                            {(this.state.errors.length !== 0) ? <Alert
                                message="Error"
                                description={this.state.errors}
                                type="error"
                                showIcon
                            /> : null}

                            <Form.Item>
                                {getFieldDecorator('email', {
                                    rules: [{ required: true, message: 'Email is required.' }],
                                })(
                                    <Input
                                        size="large"
                                        placeholder="Enter your email"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Button loading={this.state.buttonDisabled} disabled={this.state.buttonDisabled} type="primary" size="large" htmlType="submit" className="login-form-button">
                                    Submit
                         </Button>
                            </Form.Item>
                        </Form>
                    </>
                    }

                    {this.state.emailSent === true && <>
                        <Result
                            status="success"
                            title="Reset link sent"
                            subTitle={this.state.messageText}

                        />
                    </>}
                </div>
                <Footer isLogOut={true}></Footer>
            </div >
        );
    }
}

const ForgotPasswordPage = Form.create({ name: 'forgotPassword_form' })(ForgotPassword);
export default ForgotPasswordPage;
