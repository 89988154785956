import React, { Component } from 'react'
import { Button, Card, Spin, Modal, Divider, Descriptions } from "antd"
import Utils from "../../utils/utils";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { setUserName, setProfile } from '../../actions/index';
import { staffMemberDelete } from "../../services/staffMemberServices";
import UnitsConversions from "./../../utils/unitsConversions"

const { confirm } = Modal;

const mapStateToProps = (state) => {
    return {
        preferences: state.preferences,
        membershipId: state.membershipId
    }
}
class PersonalDetailsCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profPic: "",
            spinning: false,
            error: false,
            profile: "",
        };
    }

    handleDeactivate = () => {
        confirm({
            title: 'Deactivate profile?',
            content: ' Are you sure you want to deactivate the staff member profile ?',
            okText: "ok",
            onOk: async () => {
                try {
                    await staffMemberDelete(this.props.profile.staffId);
                    this.props.history.push(`/staff`, { tabActiveKey: "2" });
                } catch (ex) {
                    if (ex.response && ex.response.status === 400) {
                        console.log("Error Occurred")
                    }
                }
            },
            onCancel() { },
        });
    }

    render() {
        const { heightUnit, weightUnit } = this.props.preferences
        let { staffId = "", joiningDate, dateOfBirth, idNumber, name, address, email, phone, gender, age, healthIssues, emgName, emgAddress, emgPhone, emgRelationship, weight, height, profilePic } = this.props.profile
        return (
            <div >
                <Card title={`Staff ID: ${staffId}`} bordered={true} extra={<><NavLink to={`${staffId}/updatePersonal`}>
                    <Button type="primary" >Update</Button>
                </NavLink> <Button style={{ marginLeft: "5px" }} type="primary" onClick={() => this.handleDeactivate()} >Deactivate</Button> </>}>

                    {(this.state.spinning === true) ? <Spin style={{ marginTop: "50px" }} size="large" className="spinner" /> : <>

                        <div
                            className="profilePicContainer">
                            <div className="photo">
                                <div style={{ backgroundImage: `url(${profilePic})`, width: "250px", height: "250px", backgroundPosition: "center", backgroundSize: "cover" }}></div>
                            </div>
                        </div>

                        <Descriptions title="Staff Member Details">
                            <Descriptions.Item label="Name">{name}</Descriptions.Item>
                            <Descriptions.Item label="ID Number">{idNumber}</Descriptions.Item>
                            <Descriptions.Item label="Gender">{gender}</Descriptions.Item>
                            <Descriptions.Item label="Date of Birth">{dateOfBirth}</Descriptions.Item>
                            <Descriptions.Item label="Age">{age}</Descriptions.Item>
                            <Descriptions.Item label="Joined Date">{joiningDate}</Descriptions.Item>
                            <Descriptions.Item label="Health Issues">{healthIssues}</Descriptions.Item>
                        </Descriptions>

                        <Divider />

                        <Descriptions title="Contact details">
                            <Descriptions.Item label="Phone">{phone}</Descriptions.Item>
                            <Descriptions.Item label="Email">{email}</Descriptions.Item>
                            <Descriptions.Item label="Address">{address}</Descriptions.Item>
                        </Descriptions>

                        <Divider />

                        <Descriptions title="Emergency contact details">
                            <Descriptions.Item label="Name">{emgName}</Descriptions.Item>
                            <Descriptions.Item label="Phone">{emgPhone}</Descriptions.Item>
                            <Descriptions.Item label="Address">{emgAddress}</Descriptions.Item>
                            <Descriptions.Item label="Relationship">{emgRelationship}</Descriptions.Item>
                        </Descriptions>

                        <Divider />

                        <Descriptions title="Physical measurement details">
                            <Descriptions.Item label="Weight"> {(weight) ? <div style={{ textAlign: "left" }}>{UnitsConversions.serverWeightToLocalUnits(weightUnit, weight)} {weightUnit}</div> : null}</Descriptions.Item>
                            <Descriptions.Item label="Height">{(height) ? <div style={{ textAlign: "left" }}>{UnitsConversions.serverHeightToLocalUnits(heightUnit, height)}</div> : null}</Descriptions.Item>
                            <Descriptions.Item label="BMI">{Utils.calculateBMI(height, weight, "cm", "kg")}</Descriptions.Item>
                        </Descriptions>

                    </>}
                </Card>

            </div >
        )
    }
}

export default withRouter(connect(mapStateToProps, { setUserName, setProfile })(PersonalDetailsCard));
