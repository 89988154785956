import http from "./httpService";
import Utils from "../utils/utils";

const apiEndpoint = Utils.getApiUrl() + "file-upload";

export async function uploadFile(data) {

    let config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function (progressEvent) {
            // let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
         
        }
    };
    return http.post(apiEndpoint, data, config);
    // return http.post(apiEndpoint, data, {

    //     headers: {
    //         'Content-Type': 'multipart/form-data'
    //     }
    // });
}

export default {
    uploadFile
};

