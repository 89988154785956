import http from "./httpService";
import Utils from "../utils/utils";

const apiEndpoint = Utils.getApiUrl() + "subscriptions";

function subscriptionsUrl(id) {
    return `${apiEndpoint}/${id}`;
}

export function getSubscriptionsDetails(memberId,queryCurrent) {
    return http.get(subscriptionsUrl(memberId),{params:{current:queryCurrent}});
}

export function getAllSubscriptions() {
    return http.get(apiEndpoint);
}

export async function subscriptionsRegister(plan) {
        return http.post(apiEndpoint, plan);
}

export async function subscriptionsUpdate(subscription,subscriptionId) {
    return http.put(subscriptionsUrl(subscriptionId), subscription);
}

export async function deleteSubscription(memberId,_sendToHistory) {  
    return http.delete(subscriptionsUrl(memberId),{params:{sendToHistory:_sendToHistory}});
  }


export default {
    getSubscriptionsDetails,
    subscriptionsRegister,
    subscriptionsUpdate,
    deleteSubscription
};

