import http from "./httpService";
import Utils from "../utils/utils";

const apiEndpoint = Utils.getApiUrl() + "member";

function memberUrl(id) {
    return `${apiEndpoint}/${id}`;
}

export function getMemberDetails(memberId) {
    return http.get(memberUrl(memberId));
}


export async function memberRegister(profile, proceedAnyway) {
    profile.proceedAnyway = proceedAnyway;
    return http.post(apiEndpoint, profile);
}

export async function memberUpdate(profile, proceedAnyway,area) {
    profile.proceedAnyway = proceedAnyway;
    profile.area = area;
    return http.put(memberUrl(profile._id), profile);
}

export async function memberRestore(id,data) {   
    return http.put(memberUrl(id), data);
}

export async function memberDelete(memberId) {    
    return http.delete(memberUrl(memberId));
}

export default {
    memberRegister,
    getMemberDetails,
    memberDelete,
    memberRestore,
    memberUpdate
};

