import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Constants from "../utils/constants"
import {
    Form, Radio, Modal, Input
} from "antd";

const mapStateToProps = (state) => {
    return {
        currentSubscription: state.currentSubscription,
        membershipId: state.membershipId,
    }
}


class _ReasonForCancellationModel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            radioOption: "",
            okButtonDisabled: true,
            textReason: ""
        }
    }

    onChange = e => {
        if (e.target.value === (Constants.reasons.length - 1)) {
            this.setState({ radioOption: e.target.value, okButtonDisabled: true, textReason: "" })

        } else {
            this.setState({ okButtonDisabled: false, radioOption: e.target.value })
        }


    };

    handleCancel = e => {
        this.props.onCancel()
    };

    handleOk = () => {
        let data = {
            reason: Constants.reasons[this.state.radioOption],
            textReason: this.state.textReason
        }
        this.props.onOk(data)

    };

    onTextChange = (e) => {
        if (this.state.radioOption === (Constants.reasons.length - 1)) {
            this.setState({ textReason: e.target.value });
            if (e.target.value.length >= 10) {
                this.setState({ okButtonDisabled: false })
            } else {
                this.setState({ okButtonDisabled: true })
            }
        }

    };

    render() {
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        return (
            <Modal
                title="Tell us why you are leaving?"
                visible={this.props.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okButtonProps={{ disabled: this.state.okButtonDisabled }}
            >

                <Radio.Group onChange={this.onChange} value={this.state.radioOption}>

                    {Constants.reasons.map((reason, index) => (
                        <Radio key={index} style={radioStyle} value={index}>
                            {reason}
                        </Radio>


                    ))}

                    {this.state.radioOption === (Constants.reasons.length - 1) ? <Input.TextArea
                        value={this.state.textReason}
                        onChange={this.onTextChange}
                        rows={2}
                        style={{ width: 400, marginLeft: 10 }}
                    /> : null}

                </Radio.Group>


            </Modal>
        );
    }
}

const ReasonForCancellationModel = connect(mapStateToProps, {})(Form.create({ name: 'purchase_cancel_reason' })(_ReasonForCancellationModel));
export default withRouter(ReasonForCancellationModel);


