import React from "react";
import { signup } from "../../services/signupService";

import { Form, Input, Alert, Checkbox, Button, Spin } from "antd";
import { NavLink } from "react-router-dom";
import Footer from "./../../components/Layout/Footer";

class SignupForm extends React.Component {
  state = {
    confirmDirty: false,
    errors: "",
    creatingAccount: false,
  };

  doSubmit = async (user) => {
    this.setState({ creatingAccount: true });
    try {
      const response = await signup(user);
      // localStorage.setItem("token", response.headers["p-auth-token"])
      if (response.data.emailSent === true) {
        this.setState({ creatingAccount: false });
        this.props.history.push(
          `/emailSent?email=${response.data.email}`,
          response.data
        );
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        this.setState({ errors: ex.response.data, creatingAccount: false });
      }
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, userData) => {
      if (!err) {
        this.doSubmit(userData);
      }
    });
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Passwords do not match!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 14,
          offset: 0,
        },
      },
    };

    return (
      <div className="register-page">
        <div className="form-wrapper">
          <a href="/">
            <div className="LogoWrapper">
              <div className="Logo"></div>
            </div>
          </a>
          <Spin
            tip="Creating your Gym..."
            spinning={this.state.creatingAccount}
          >
            <Form
              {...formItemLayout}
              onSubmit={this.handleSubmit}
              className="register-form"
            >
              {this.state.errors.length !== 0 ? (
                <Alert
                  message="Error"
                  description={this.state.errors}
                  type="error"
                  showIcon
                />
              ) : null}
              <Form.Item label="Gym Name" hasFeedback>
                {getFieldDecorator("gymName", {
                  rules: [
                    { required: true, message: "Gym name is required." },
                    { whitespace: true },
                    {
                      min: 3,
                      message: "Gym name should be 3 to 30 characters long.",
                    },
                    {
                      max: 30,
                      message: "Gym name should be 3 to 30 characters long.",
                    },
                  ],
                })(<Input size="large" />)}
              </Form.Item>
              <Form.Item label="E-mail">
                {getFieldDecorator("email", {
                  rules: [
                    {
                      type: "email",
                      message: "Email format is not valid.",
                    },
                    {
                      required: true,
                      message: "Email is required.",
                    },
                  ],
                })(<Input size="large" />)}
              </Form.Item>
              <Form.Item label="Name" hasFeedback style={{ marginBottom: 0 }}>
                {/* <Input.Group compact> */}
                <Form.Item style={{ display: "inline-block", width: "50%" }}>
                  {getFieldDecorator("firstName", {
                    rules: [
                      {
                        required: true,
                        message: "First name is required.",
                      },
                      { whitespace: true },
                      {
                        min: 1,
                        message:
                          "First name should be 1 to 30 characters long.",
                      },
                      {
                        max: 30,
                        message:
                          "First name should be 1 to 30 characters long.",
                      },
                    ],
                  })(<Input size="large" placeholder="First Name" />)}
                </Form.Item>
                <Form.Item style={{ display: "inline-block", width: "50%" }}>
                  {getFieldDecorator("lastName", {
                    rules: [
                      { required: true, message: "Last name is required." },
                      { whitespace: true },
                      {
                        min: 1,
                        message: "Last name should be 1 to 30 characters long.",
                      },
                      {
                        max: 30,
                        message: "Last name should be 1 to 30 characters long.",
                      },
                    ],
                  })(<Input size="large" placeholder="Last Name" />)}
                </Form.Item>
                {/* </Input.Group> */}
              </Form.Item>

              <Form.Item label="Password" hasFeedback>
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "Password is required.",
                    },
                    {
                      min: 8,
                      message: "Password must have minimum 8 characters.",
                    },
                    {
                      validator: this.validateToNextPassword,
                    },
                  ],
                })(<Input.Password size="large" />)}
              </Form.Item>
              <Form.Item label="Confirm Password" hasFeedback>
                {getFieldDecorator("confirm", {
                  rules: [
                    {
                      required: true,
                      message: "Please confirm your password.",
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                  ],
                })(
                  <Input.Password
                    size="large"
                    onBlur={this.handleConfirmBlur}
                  />
                )}
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                {getFieldDecorator("agreement", {
                  valuePropName: "checked",
                })(
                  <Checkbox>
                    I have read the{" "}
                    <a
                      href="https://www.chikirisoft.com/jimpartner/terms"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      terms &amp; conditions
                    </a>
                  </Checkbox>
                )}
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button
                  type="primary"
                  className="register-form-button"
                  size="large"
                  htmlType="submit"
                >
                  Sign Up
                </Button>
                <span>Already have an account ? </span>
                <NavLink to="/signin">Log in</NavLink>
              </Form.Item>
            </Form>
          </Spin>
        </div>
        <Footer isLogOut={true}></Footer>
      </div>
    );
  }
}

const SignupPage = Form.create({ name: "signup-form" })(SignupForm);

export default SignupPage;
