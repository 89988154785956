
import React from "react";
import { Tag } from "antd";
import moment from "moment"
import UnitConversions from "./unitsConversions";
import { apiUrl } from "../config.json";


function inchesToFeet(inches) {
    var feet = Math.floor(inches / 12);
    inches %= 12;
    return `${feet}'${inches}"`;
}

function inchesToCm(inches) {
    var cm = (inches * 2.54);
    return `${cm} cm`;
}

function heightWithCm(cm) {
    return `${cm} cm`;
}

function cmToFeet(cm) {
    var inches = (cm / 2.54);
    var feet = Math.floor(inches / 12);
    inches %= 12;    
    return `${feet}"${Math.floor(inches)}'`;
}
function kgToLb(kg) {
    var lb = (kg * 2.205);
    return Math.floor(lb);
}


function calculateBMI(height, weight, heightUnit, weightUnit) {

    let heightInMeters = height / 100.0;
    let _weight = "";

    if (weightUnit === "lb") {
        _weight = UnitConversions.lbToKg(weight)
    } else {
        _weight = weight
    }

    let BMI = (_weight / (heightInMeters * heightInMeters)).toFixed(1);

    if (BMI < 18.5) {
        return <Tag color="#e0c516">{BMI} - underweight</Tag>
    }
    else if (BMI >= 18.5 && BMI <= 25) {
        return <Tag color="green">{BMI} - Normal</Tag>
    }
    else if (BMI > 25 && BMI <= 30) {
        return <Tag color="orange">{BMI} - Overweight</Tag>
    }
    else if (BMI > 30) {
        return <Tag color="red">{BMI} - Obese</Tag>
    }

}

function membershipStatusTag(membershipStatus) {
    if (membershipStatus) {
        let status = membershipStatus.toUpperCase()
        switch (status) {
            case 'PENDING':
                return <Tag color="orange">{status}</Tag>;
            case 'ACTIVE':
                return <Tag color="green">{status}</Tag>;
            case 'EXPIRED':
                return <Tag color="red">{status}</Tag>;
            case 'NO SUBSCRIPTION':
                return <Tag color="red">{status}</Tag>;
            case 'DELETED':
                return <Tag color="red">{status}</Tag>;
            case 'PAST DUE':
                return <Tag color="red">{status}</Tag>;
            default:
            // do nothing
        }
    }


}

function paymentStatusTag(paymentStatus) {
    if (paymentStatus) {
        let status = paymentStatus.toUpperCase()
        switch (status) {
            case 'FULLY PAID':
                return <Tag color="geekblue">{status}</Tag>;
            case 'NOT PAID':
                return <Tag color="geekblue">{status}</Tag>;
            case 'PARTIALLY PAID':
                return <Tag color="geekblue">{status}</Tag>;
            default:
            // do nothing
        }
    }


}
function combinePeriodPrefix(plans) {
    let _plans = plans.map((plan) => {
        plan.membershipPeriod = `${plan.membershipPeriod} ${plan.prefix}`;
        return plan
    })
    return _plans
}
function combineMemberStatus(members) {
    let _members = members.map((member) => {
        member.status = [member.membershipStatus, member.paymentStatus]
        return member
    })
    return _members
}

function convertHeightToUnits(unit, value) {
    if (value) {
        if (unit === "cm") {
            return `${value} cm`;
        } else if (unit === "feet") {
            return cmToFeet(value);
        }

    } else {
        return ""
    }

}

function convertWeightToUnits(unit, value) {
    if (unit === "kg") {
        return value;
    } else if (unit === "lb") {
        return kgToLb(value);
    }

}

function convertLocalTimeToUtc(timeMoment) {
    if (timeMoment) {
        let time_utc = timeMoment.clone().utc();
        return time_utc.format();
    } else {
        return timeMoment;
    }
}

function convertUtcTimeToLocal(utcTime, dateFormat) {

    if (utcTime && dateFormat) {

        let _utcTime = new moment(utcTime, "YYYY-MM-DDTHH:mm:ssZ");
        let localTime = _utcTime.local();
        if (dateFormat) {
            return localTime.format(dateFormat);
        }
        return localTime;
    } else {
        return utcTime;
    }

}
function convertUtcTimeToLocalAsMoment(UtcTime) {
    if (UtcTime) {
        let _UtcTime = new moment(UtcTime, "YYYY-MM-DDTHH:mm:ssZ");
        let localTime = _UtcTime.local();
        return localTime;
    } else {
        return UtcTime;
    }
}

function planStatusTag(planStatus) {
    if (planStatus) {
        let status = planStatus.toUpperCase()
        switch (status) {
            case 'ACTIVE':
                return <Tag color="green">{status}</Tag>;
            case 'DELETED':
                return <Tag color="red">{status}</Tag>;
            default:
            // do nothing
        }
    } else {
        return null
    }
}

function timeStampToLocalTime(timeStamp, dateFormat) {

    if (timeStamp && dateFormat) {
        return moment.unix(timeStamp).format(dateFormat);
    } else {
        return timeStamp
    }
}

function toPrice(price) {
    if (price !==0) {
        return (Math.round(price * 100) / 100).toFixed(2);
    }else if(price ===-0){
        return (Math.round(price * 100) / 100).toFixed(2);
    } else {
        return "";
    }
}

function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

function getClosest(array, value) {
    var closest = array.reduce(function (prev, curr) {
        return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
    });    
    return closest;
}

function getApiUrl(){
    return (window.location.hostname === 'localhost' ? 'http://localhost:4000' : '') + apiUrl;
}

export default {
    inchesToFeet,
    inchesToCm,
    heightWithCm,
    cmToFeet,
    calculateBMI,
    membershipStatusTag,
    paymentStatusTag,
    combinePeriodPrefix,
    combineMemberStatus,
    convertHeightToUnits,
    convertLocalTimeToUtc,
    convertUtcTimeToLocal,
    planStatusTag,
    convertUtcTimeToLocalAsMoment,
    timeStampToLocalTime,
    convertWeightToUnits,
    toPrice,
    dataURLtoFile,
    getClosest,
    getApiUrl
}


