import http from "./httpService";
import Utils from "../utils/utils";

const apiEndpoint = Utils.getApiUrl() + "payment";

function paymentUrl(id) {
    return `${apiEndpoint}/${id}`;
}

export function getPaymentDetails(memberId) {
    return http.get(paymentUrl(memberId));
}

export async function addPayment(payment) {
        return http.post(apiEndpoint, payment);
}

export async function paymentUpdate(payment) {
    return http.put(paymentUrl(payment._id), payment);
}

export default {
    getPaymentDetails,
    addPayment,
    paymentUpdate
};

