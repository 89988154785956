import React from "react";

export default class Footer extends React.Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <div
        className={"Footer" + (this.props.isLogOut ? " Footer__Logout" : "")}
      >
        <span>Copyright © {year} ChikiriSoft</span>
        <div className="Footer_Links">
          <a href="https://www.chikirisoft.com/jimpartner/terms">Terms</a>
          <a href="https://www.chikirisoft.com/jimpartner/privacy">Privacy</a>
          <a href="https://www.chikirisoft.com/jimpartner/refund_policy">
            Refund policy
          </a>
          <a href="mailto:support@chikirisoft.com">Contact</a>
        </div>
      </div>
    );
  }
}
