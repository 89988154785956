import React from "react";
import { PubSub } from 'pubsub-js';
import ReactCrop from 'react-image-crop';
import {
    Modal,
    Button,  
} from "antd";

export default class ImageCropper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            hasUserMedia: false,
            crop: {
                unit: 'px',
                width: 250,
                height: 250,
                x: 125,
                y: 125
            }
        };
    }

    openModal = (data) => {
        this.setState({
            visible: true,
            ...data,
            crop: {
                unit: 'px',
                width: 250,
                height: 250
            }
        });
    }

    closeModal = () => {
        this.setState({
            visible: false,
            hasUserMedia: false
        });
    }

    componentDidMount() {
        PubSub.subscribe('openImageCropper', (event, imageData) => {
            this.openModal({
                imageData: imageData
            });
        });
        PubSub.subscribe('closeImageCropper', () => {
            this.closeModal();
        });
    }

    componentWillUnmount() {
        PubSub.unsubscribe('openImageCropper');
        PubSub.unsubscribe('closeImageCropper');
    }

    handleOk = async () => {
        this.makeClientCrop(this.state.crop);
    }

    handleUserMedia = () => {
        this.setState({
            hasUserMedia: true
        })
    }

    onImageLoaded = imageRef => {
        this.imageRef = imageRef;
        const image = document.getElementsByClassName("ReactCrop__image")[0];
        this.setState({
            crop: {
                unit: 'px',
                width: 250,
                height: 250,
                x: (image.width / 2) - 125,
                y: (image.height / 2) - 125
            }
        });
        return false;
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'croppedImage.jpeg'
            );
            this.props.onImageCropped(croppedImageUrl)
            this.closeModal();
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            const dataURL = canvas.toDataURL('image/jpeg', 1.0);
            resolve(dataURL);
        });
    }

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    render() {

        return (
            <div className="camera__modal">
                <Modal
                    wrapClassName="camera__modal"
                    title={this.props.title}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.closeModal}
                    width={720}
                    footer={[
                        <Button key="Submit" type="primary" onClick={this.handleOk}>
                            Crop
                    </Button>,
                        <Button key="Close" type="secondary" onClick={this.closeModal}>
                            Close
                    </Button>
                    ]}>

                    {this.state.visible ? <div className="webcam--wrapper image-cropper">
                        <ReactCrop
                            src={this.state.imageData}
                            crop={this.state.crop}
                            ruleOfThirds={false}
                            locked={true}
                            onChange={this.onCropChange}
                            onImageLoaded={this.onImageLoaded}
                        />
                    </div> : null}

                </Modal>
            </div>
        );
    }

}