export default {
  tables: {
    pageSizes: ["10", "20", "30", "40"],
  },
  paymentTypes: {
    types: ["Cash", "Card", "Check", "Other"],
  },
  heightArray: (unit) => {
    let heights = [];
    if (unit === "cm") {
      for (var i = 120; i < 250; i++) {
        heights.push(i);
      }
      return heights;
    } else if (unit === "feet") {
      for (var j = 120.0; j < 250.0; j += 2.54) {
        heights.push(j);
      }
      return heights;
    }
  },
  currencyUnitsArray: [
    "AED",
    "AFN",
    "ALL",
    "AMD",
    "ANG",
    "AOA",
    "ARS",
    "AUD",
    "AWG",
    "AZN",
    "BAM",
    "BBD",
    "BDT",
    "BGN",
    "BHD",
    "BIF",
    "BMD",
    "BND",
    "BOB",
    "BRL",
    "BSD",
    "BTN",
    "BWP",
    "BYR",
    "BZD",
    "CAD",
    "CDF",
    "CHF",
    "CLF",
    "CLP",
    "CNY",
    "COP",
    "CRC",
    "CUP",
    "CVE",
    "CZK",
    "DJF",
    "DKK",
    "DOP",
    "DZD",
    "EGP",
    "ETB",
    "EUR",
    "FJD",
    "FKP",
    "GBP",
    "GEL",
    "GHS",
    "GIP",
    "GMD",
    "GNF",
    "GTQ",
    "GYD",
    "HKD",
    "HNL",
    "HRK",
    "HTG",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "IQD",
    "IRR",
    "ISK",
    "JEP",
    "JMD",
    "JOD",
    "JPY",
    "KES",
    "KGS",
    "KHR",
    "KMF",
    "KPW",
    "KRW",
    "KWD",
    "KYD",
    "KZT",
    "LAK",
    "LBP",
    "LKR",
    "LRD",
    "LSL",
    "LTL",
    "LVL",
    "LYD",
    "MAD",
    "MDL",
    "MGA",
    "MKD",
    "MMK",
    "MNT",
    "MOP",
    "MRO",
    "MUR",
    "MVR",
    "MWK",
    "MXN",
    "MYR",
    "MZN",
    "NAD",
    "NGN",
    "NIO",
    "NOK",
    "NPR",
    "NZD",
    "OMR",
    "PAB",
    "PEN",
    "PGK",
    "PHP",
    "PKR",
    "PLN",
    "PYG",
    "QAR",
    "RON",
    "RSD",
    "RUB",
    "RWF",
    "SAR",
    "SBD",
    "SCR",
    "SDG",
    "SEK",
    "SGD",
    "SHP",
    "SLL",
    "SOS",
    "SRD",
    "STD",
    "SVC",
    "SYP",
    "SZL",
    "THB",
    "TJS",
    "TMT",
    "TND",
    "TOP",
    "TRY",
    "TTD",
    "TWD",
    "TZS",
    "UAH",
    "UGX",
    "USD",
    "UYU",
    "UZS",
    "VEF",
    "VND",
    "VUV",
    "WST",
    "XAF",
    "XCD",
    "XDR",
    "XOF",
    "XPF",
    "YER",
    "ZAR",
    "ZMK",
    "ZWL",
  ],
  weightUnitsArray: ["kg", "lb"],
  heightUnitsArray: ["cm", "feet"],
  dateFormatArray: ["YYYY-MM-DD", "YY-MM-DD", "DD-MM-YYYY", "DD-MM-YY"],
  timeFormatArray: ["h:mm A", "HH:mm"],
  files: {
    allowedImageTypes: ["image/jpeg", "image/jpg", "image/png"],
    allowedFileBrowserImageTypes: ".jpeg,.jpg,.png",
    maxImageSize: 3145728, //3MB
  },
  redux: {
    actions: {
      SET_USER_NAME: "SET_USER_NAME",
      SET_PROFILE: "SET_PROFILE",
      SET_MEMBERSHIP_ID: "SET_MEMBERSHIP_ID",
      SET_PAYMENT_COLLAPSE_KEY: "SET_PAYMENT_COLLAPSE_KEY",
      SET_CURRENT_SUBSCRIPTION: "SET_CURRENT_SUBSCRIPTION",
      SET_PREFERENCES: "SET_PREFERENCES",
      SET_GYM_DETAILS: "SET_GYM_DETAILS",
    },
  },
  PADDLE: {
    VENDOR_ID: 166161,
    MONTHLY_PLAN_PRODUCT_ID: 822686,
    YEARLY_PLAN_PRODUCT_ID: 822687,
    STATES: {
      ACTIVE: "active",
      TRIALING: "trialling",
      PAST_DUE: "past_due",
      DELETED: "deleted",
    },
    PRICES: {
      MONTHLY: "$19",
      YEARLY: "$17",
    },
  },
  emptyProfilePicUrl:
    "https://gmsapp.s3.amazonaws.com/common/emptyProfilePic.jpg",
  reasons: [
    "Price is expensive",
    "Not enough features",
    "Moving to another product",
    "Doesn't fullfil my requirement",
    "Other",
  ],
};
