import React from "react";
import _ from "lodash";
import { PubSub } from "pubsub-js";
import { connect } from "react-redux";
import { getMemberDetails } from "../../../services/memberServices";
import PersonalDetailsCard from "../../../components/Cards/PersonalDetailsCard";
import SubscriptionCard from "../../../components/Cards/SubscriptionsCard";
import PaymentsCard from "../../../components/Cards/PaymentsCard";
import Utils from "../../../utils/utils";
import { withRouter } from "react-router-dom";

import {
  setMembershipId,
  setProfile,
  setCurrentSubscription,
} from "../../../actions/index";
import MemberAttendanceCalendar from "../../../components/Calendars/MemberAttendanceCalendar";
import {
  Breadcrumb,
  Result,
  Divider,
  Row,
  Col,
  Spin,
  Statistic,
  Button,
  Tabs,
} from "antd";

const { TabPane } = Tabs;

const mapStateToProps = (state) => {
  return {
    membershipId: state.membershipId,
    preferences: state.preferences,
  };
};

class MemberPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.setMembershipId(this.props.match.params.memberId);
    this.state = {
      profile: "",
      hasProfile: "loading",
      currentSubscription: {},
      subscriptionHistory: [],
      payments: [],
      tabActiveKey: "1",
    };
  }

  handleClick = () => {
    this.props.history.goBack();
  };

  getDetails = async () => {
    const { dateFormat } = this.props.preferences;
    let _response = {};
    let _currentSubscription = {};
    let _subscriptionHistoryTemp = [];
    let _subscriptionHistory = [];
    let _attendances = [];
    let _payments = [];
    try {
      const response = await getMemberDetails(this.props.match.params.memberId);
      //console.log("MEMBER DETAIL LOG:", response);

      if (response.data.length > 0) {
        _response = response.data[0];

        _currentSubscription = _.filter(_response.subscriptions, {
          current: true,
        })[0];
        _subscriptionHistoryTemp = _.filter(_response.subscriptions, {
          current: false,
        });
        _subscriptionHistory = _.reverse(_subscriptionHistoryTemp);
        _payments = _.reverse(_response.payments);
        _payments = _payments.map((_payment) => {
          _payment.member = {};
          _payment.member.name = _response.name;
          _payment.member.address = _response.address;
          _payment.member.phone = _response.phone;
          return _payment;
        });

        _attendances = _.reverse(_response.attendances);

        this.props.setCurrentSubscription(_currentSubscription);

        _response.dateOfBirth = Utils.convertUtcTimeToLocal(
          _response.dateOfBirth,
          dateFormat
        );
        _response.joiningDate = Utils.convertUtcTimeToLocal(
          _response.joiningDate,
          dateFormat
        );
        const profileStatus = "true";
        if (_response.deactivated === true) {
          profileStatus = "deactivated";
        }
        this.setState({
          error: false,
          spinning: false,
          profile: _response,
          currentSubscription: _currentSubscription,
          subscriptionHistory: _subscriptionHistory,
          payments: _payments,
          attendances: _attendances,
          hasProfile: profileStatus,
        });
      } else {
        this.setState({ error: true, spinning: false, hasProfile: "false" });
      }
    } catch (ex) {
      console.log(ex);
      if (ex.response && ex.response.status === 400) {
        this.setState({ error: true, spinning: false, hasProfile: "false" });
      }
    }
  };

  componentWillUnmount() {
    PubSub.unsubscribe("refreshPage");
  }

  componentDidMount = async () => {
    PubSub.subscribe("refreshPage", async () => {
      await this.getDetails();
    });
    await this.getDetails();
  };

  handleTabChange = (key) => {
    this.setState({ tabActiveKey: key });
  };

  render() {
    let {
      profile,
      currentSubscription,
      subscriptionHistory,
      payments,
      attendances,
    } = this.state;
    let profileBlock = null;

    switch (this.state.hasProfile) {
      case "true":
        profileBlock = (
          <>
            <Tabs
              tabBarGutter={5}
              // type="card"
              activeKey={this.state.tabActiveKey}
              onChange={this.handleTabChange}
            >
              <TabPane tab="Profile" key="1">
                <PersonalDetailsCard profile={this.state.profile} />
              </TabPane>
              <TabPane tab="Subscriptions" key="2">
                {subscriptionHistory ? (
                  <SubscriptionCard subscriptionHistory={subscriptionHistory} />
                ) : null}
              </TabPane>
              <TabPane tab="Payments" key="3">
                <PaymentsCard payments={payments} />
              </TabPane>
              <TabPane tab="Attendance" key="4">
                <MemberAttendanceCalendar attendances={attendances} />
              </TabPane>
            </Tabs>
          </>
        );
        {
          /* <Row gutter={16} style={{ margin: "10px" }}>
            <Col xs={24} xl={8} span={8}>
              <PersonalDetailsCard profile={this.state.profile} />
            </Col>
            <Col xs={24} xl={8} span={8}>
              {(subscriptionHistory) ? <SubscriptionCard subscriptionHistory={subscriptionHistory} /> : null}
            </Col>
            <Col xs={24} xl={8} span={8}>
              <PaymentsCard payments={payments} />
            </Col>
          </Row>
          <Row gutter={16} style={{ margin: "10px" }}>
            <Col xs={24} xl={24} span={8}>
              <MemberAttendanceCalendar attendances={attendances} />
            </Col>
          </Row></>; */
        }
        break;
      case "deactivated":
        profileBlock = (
          <Result
            // status="403"
            // title="400"
            title="This member profile has been deactivated!"
            extra={
              <Button type="primary" onClick={this.handleClick}>
                Go back
              </Button>
            }
          />
        );
        break;
      case "false":
        profileBlock = (
          <Result
            status="403"
            title="400"
            subTitle="Member not found !"
            extra={
              <Button type="primary" onClick={this.handleClick}>
                Go back
              </Button>
            }
          />
        );
        break;
      case "loading":
        profileBlock = (
          <Spin size="large" className="spinner" tip="Loading Profile..." />
        );
        break;
      default:
    }
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <span>Member</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{this.props.membershipId}</Breadcrumb.Item>
        </Breadcrumb>

        <div className="memberPageDetailsHeader">
          <h3>
            {profile?.name?.toUpperCase()}{" "}
            {currentSubscription
              ? Utils.membershipStatusTag(currentSubscription?.status)
              : Utils.membershipStatusTag("no subscription")}
            {Utils.paymentStatusTag(currentSubscription?.paymentStatus)}
          </h3>
          {currentSubscription?.amountBalance ||
          currentSubscription?.amountBalance === 0 ? (
            <Statistic
              title="Due Amount"
              value={`${currentSubscription?.amountBalance} ${this.props.preferences.currencyUnit}`}
              precision={2}
            />
          ) : null}
        </div>

        {/* <Divider /> */}
        {profileBlock}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, {
    setMembershipId,
    setProfile,
    setCurrentSubscription,
  })(MemberPage)
);
