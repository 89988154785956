import http from "./httpService";
import Utils from "../utils/utils";

const apiEndpoint = Utils.getApiUrl() + "cancelPurchase";


export function cancelPurchase(data) {
    return http.post(apiEndpoint, data);
}


export default {
    cancelPurchase,
};

