import http from "./httpService";
import Utils from "../utils/utils";

const apiEndpoint = Utils.getApiUrl() + "plan";

function planUrl(id) {
    return `${apiEndpoint}/${id}`;
}

export async function getPlanDetails(planId) {
   if (planId){
    return http.get(planUrl(planId));
   }
   
}
export async function planRegister(plan) {
    return http.post(apiEndpoint, plan);
}

export async function planUpdate(plan,planId) {
    return http.put(planUrl(planId), plan);
}

export async function deletePlan(planId) {
    return http.delete(planUrl(planId));
  }


export default {
    getPlanDetails,
    planRegister,
    planUpdate,
    deletePlan
};

