import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import constants from "../utils/constants";
import Utils from "../utils/utils";
import moment from 'moment';
import { addPayment } from "../services/paymentServices";
import { connect } from 'react-redux';
import _ from "lodash";
import {
    Form,
    Input,
    Select,
    Divider,
    Button,
    Modal,
    message,
    Descriptions,
    Spin
} from "antd";

const mapStateToProps = (state) => {
    return {
        currentSubscription: state.currentSubscription,
        membershipId: state.membershipId
    }
}

class _MakePaymentModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            errors: "",
            expireDate: "",
            startDate: "",
            selectedPaymentType: "",
            payment: {},
            amountToBePaid: "",
            formSubmitted: false,
        };
    }
    componentDidMount = async () => {
        try {
            this.setState({
                visible: this.props.visible,
            })
        } catch (ex) {
            console.log("try", ex)
        }
    }
    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.visible !== this.props.visible && this.props.visible === true) {
            try {
                this.setState({
                    visible: this.props.visible,
                })
            } catch (ex) {
                console.log("try", ex)
            }
        }
    }
    makePayment = async (values) => {
        await this._makePayment(values)
        this.setState({ formSubmitted: false })
        this.props.onOk()


    }
    resetFields = () => {
        this.props.form.resetFields()
        this.setState({ selectedSubscription: "" })
    }
    _makePayment = async (values) => {
        try {
            const response = await addPayment(values);
            message.success(response.data, 5)
            this.resetFields();
        } catch (ex) {
            console.log(ex)
            if (ex.response && ex.response.status === 400) {
                this.setState({ error: true, spinning: false })
                message.error(ex.response.data, 5)
            }
        }
    }
    handleSubmit = e => {
        this.setState({ formSubmitted: true })
        let { payment } = this.state
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                payment.date = Utils.convertLocalTimeToUtc(moment());
                payment.timeStamp = moment().utc().format("X");
                payment.memberId = this.props.membershipId;
                payment.planId = this.props.currentSubscription.planId;
                payment.planName = this.props.currentSubscription.membershipPlanName;
                payment.planBalanceAmount = this.props.currentSubscription.amountBalance;
                payment.subscriptionId = this.props.currentSubscription.subscriptionId;
                payment.discount = parseFloat(values.discount || 0).toFixed(2);
                payment.amountToBePaid = this.calculateAmountAfterDiscount(this.props.currentSubscription.amountBalance, this.props.form.getFieldValue("discount"));
                payment.amountPaid = parseFloat(values.amountPaid).toFixed(2);
                payment.paymentType = values.paymentType;
                payment.remarks = values.remarks;
                payment.planPrice = this.props.currentSubscription.price;
                payment.startDate = this.props.currentSubscription.startDate;
                payment.expireDate = this.props.currentSubscription.expireDate;
                this.makePayment(payment)
            }
        });
    };

    saveSelectedSubscription(subscriptionId, subscriptions) {
        let subscription = _.find(subscriptions, { "_id": subscriptionId })
        this.setState({ selectedSubscription: subscription })
    }
    saveSelectedPaymentType(type) {
        if (type) {
            this.setState({ selectedPaymentType: type })
        }

    }
    handleCancel = e => {
        this.props.onCancel();
        this.resetFields();
    };

    handleOk = values => {
        this.props.onOk()
        this.setState({
            visible: false,
        });
    };
    calculateAmountAfterDiscount = (price, discount) => {
        if (discount && price) {
            return (price - discount).toFixed(2);
        } else {
            return price;
        };
    }
    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 12,
                },
            },
        };
        const { getFieldDecorator } = this.props.form;
        return (<>
            {(this.props.currentSubscription) ? <>
                <Modal
                    visible={this.props.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer=""
                    width={700}>
                    <div >
                        <div style={{position: "relative"}}>
                            <Form className="payment_form" name="Payment_Form" noValidate onSubmit={this.handleSubmit} {...formItemLayout}>

                                <h4 style={{ marginBottom: "24px" }}>Make a payment</h4>

                                <Form.Item label="Member ID">
                                    <Descriptions.Item label="memberId">{this.props?.membershipId} </Descriptions.Item>
                                </Form.Item>
                                <Form.Item label="Plan Name">
                                    <Descriptions.Item label="planName">{this.props.currentSubscription?.membershipPlanName} </Descriptions.Item>
                                </Form.Item>
                                <Form.Item label="Amount to be paid for the selected plan">
                                    <Descriptions.Item label="AmountBalance">{this.props.currentSubscription?.amountBalance} $</Descriptions.Item>
                                </Form.Item>
                                <Form.Item label="Plan start date">
                                    <Descriptions.Item label="planStartDate">{Utils.convertUtcTimeToLocal(this.props.currentSubscription?.startDate, "YYYY-MM-DD")} </Descriptions.Item>
                                </Form.Item>
                                <Form.Item label="Plan Expire date">
                                    <Descriptions.Item label="planExpireDate">{Utils.convertUtcTimeToLocal(this.props.currentSubscription?.expireDate, "YYYY-MM-DD")} </Descriptions.Item>
                                </Form.Item>
                                <Divider />
                                <Form.Item label="Discount">
                                    {getFieldDecorator('discount', {
                                        rules: [
                                            {
                                                validator: this.handleDiscountInput
                                            }
                                        ],
                                    })(
                                        <Input placeholder="Enter discount amount" type="number" addonAfter="$" />)}
                                </Form.Item>
                                <Form.Item label="Final amount to be paid">
                                    <Descriptions.Item>{this.calculateAmountAfterDiscount(this.props.currentSubscription?.amountBalance, this.props.form.getFieldValue("discount"))}
                                     $</Descriptions.Item>
                                </Form.Item>
                                <Form.Item label="Select a payment type">
                                    {getFieldDecorator('paymentType')(
                                        <Select placeholder="Please select payment Type" onChange={(type) => { this.saveSelectedPaymentType(type) }}>
                                            {constants.paymentTypes.types.map((type, index) => {
                                                return <Select.Option key={index} value={type}>{type}</Select.Option>
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Payment Amount">
                                    {getFieldDecorator('amountPaid', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please enter payment amount'
                                            }
                                        ],
                                    })(
                                        <Input placeholder="Enter paying amount" type="number" addonAfter="$" />)}
                                </Form.Item>
                                <Form.Item label=" " colon={false}>
                                    <span>* For reverse payment enter negative value </span>
                                </Form.Item>
                                <Form.Item label="Remarks">
                                    {getFieldDecorator('remarks')(
                                        <Input.TextArea rows={2} />
                                    )}
                                </Form.Item>
                                <Form.Item {...tailFormItemLayout}  >
                                    <Button style={{ margin: "10px" }} type="primary" onClick={this.handleCancel}  >
                                        Cancel
                                </Button>
                                    <Button style={{ margin: "10px" }} type="primary" htmlType="submit"  >
                                        {this.props.buttonText}
                                    </Button>
                                </Form.Item>
                            </Form>

                            {this.state.formSubmitted && <div className="modal-overlay">
                                <Spin size="large" tip="Payment Processing..."/>
                            </div>}


                        </div>
                    </div>
                </Modal ></> : null}

        </>
        );
    }
}

const WrappedModal = connect(mapStateToProps, {})(Form.create({ name: 'payments_form' })(_MakePaymentModal));
export default withRouter(WrappedModal);
