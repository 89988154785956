import http from "./httpService";
import Utils from "../utils/utils";

const apiEndpoint = Utils.getApiUrl() + "profile";


export async function getGymDetails() {
    return http.get(apiEndpoint);
}

export async function updateGymDetails(data) {
    return http.put(apiEndpoint,data);
}

export async function changePassword(data) {
    return http.post(apiEndpoint,data);
}

export default {    
    getGymDetails,
    updateGymDetails,
    changePassword
};

