import React from "react";
import {
  Calendar,
  Popover,
  Tooltip,
  Select, Row, Col, Radio, Icon, Button
} from "antd";

import { connect } from 'react-redux';

const { Group, Button: RadioButton } = Radio;
const mapStateToProps = (state) => {
  return {
    preferences: state.preferences
  }
}

class AttendanceLogPageCalendar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      attendances: [],
      dates: {},
      months: {},
      calendarLoading: true,
    }
  }

  componentDidMount = () => {   
    this.init(this.props.attendanceData);
  }

  init = async (data) => {
    this.calculateDates(data)
    this.calculateMonths(data)
    this.setState({
      attendances: data,
      calendarLoading: false,
    });
  }

  calculateDates = (data) => {
    let _dates = {};
    data.forEach((attendance) => {

      const memberId = `${attendance.memberId}|${attendance.details.name}`;

      if (_dates[attendance.date]) {

        if (_dates[attendance.date].desc[memberId]) {
          _dates[attendance.date].desc[memberId].push(`${attendance.time},${attendance.inOutStatus}`);
        } else {
          _dates[attendance.date].desc[memberId] = [`${attendance.time},${attendance.inOutStatus}`];
        }

        if (_dates[attendance.date].count.indexOf(memberId) === -1) {
          _dates[attendance.date].count.push(`${memberId}`);
        }
      } else {
        _dates[attendance.date] = {
          desc: {
            [memberId]: [`${attendance.time},${attendance.inOutStatus}`]
          },
          count: [`${memberId}`],
          popupVisible: false
        };
      }

    });
    this.setState({
      dates: _dates,
    });
  }



  calculateMonths = (data) => {
    let _months = {};
    data.forEach((attendance) => {

      const memberId = `${attendance.memberId}|${attendance.details.name}`;

      if (_months[attendance.monthYear]) {

        if (_months[attendance.monthYear].desc[memberId]) {
          // _months[attendance.monthYear].desc[memberId].push(`${attendance.date}`);
          if (_months[attendance.monthYear].desc[memberId].indexOf(attendance.date) === -1) {
            _months[attendance.monthYear].desc[memberId].push(`${attendance.date}`);
          }

        } else {
          _months[attendance.monthYear].desc[memberId] = [`${attendance.date}`];
        }

        if (_months[attendance.monthYear].count.indexOf(memberId) === -1) {
          _months[attendance.monthYear].count.push(`${memberId}`);
        }
      } else {
        _months[attendance.monthYear] = {
          desc: {
            [memberId]: [`${attendance.date}`]
          },
          count: [`${memberId}`],
          popupVisible: false
        };
      }

    });
    this.setState({
      months: _months,
    });
  }


  monthCellRender = (calendar) => {
    let content;

    this.state.attendances.forEach((attendance) => {
      if (calendar.format("YYYY-MM") === attendance.monthYear && this.state.months[attendance.monthYear].count.length > 0) {
        content = <div className="attendanceLogCell">
          <div className="attendanceCount">{this.state.months[attendance.monthYear].count.length}</div>
          <a href="/#" onClick={(e) => this.handleViewMonth(e, this.state.months[attendance.monthYear].desc, attendance.monthYear)}>Details</a>
          <Popover
            content={
              <div className="attendanceLogPopover__table">
                {(this.state.months[attendance.monthYear].count).map((memberId, i) => {
                  return <div className="attendanceLogPopover__table-row" key={i}>
                    <Tooltip title={memberId.split("|")[1]}>
                      <div className="attendanceLogPopover__table-cell attendanceLogPopover__table-id"><a href={"/member/" + memberId.split("|")[0]} target="_blank" rel="noopener noreferrer">{memberId.split("|")[0]}:&nbsp;</a></div>
                    </Tooltip>
                    <div className="attendanceLogPopover__table-cell">{this.state.months[attendance.monthYear].desc[memberId].map((details, j) => {
                      return <div key={j} className="attendanceLogPopover__table-details">
                        {/* {details.split(",")[1] === "IN" ? <span style={{ color: "#52c41a" }}>🡯&nbsp;</span> : <span style={{ color: "red" }}>🡭&nbsp;</span>} {details.split(",")[0]} */}
                        {details}
                      </div>
                    })}</div>
                  </div>
                })}
              </div>
            }
            title={attendance.monthYear + " Logs"}
            trigger="click"
            visible={this.state.months[attendance.monthYear].popupVisible}
            onVisibleChange={() => this.handleVisibleChangeMonth(attendance.monthYear)}>
          </Popover>
        </div>
      }
    })

    return content;
  }

  dateCellRender = (calendar) => {
    const { dateFormat } = this.props.preferences
    let content;

    this.state.attendances.forEach((attendance) => {
      if (calendar.format(dateFormat) === attendance.date && this.state.dates[attendance.date].count.length > 0) {
        content = <div className="attendanceLogCell">
          <div className="attendanceCount">{this.state.dates[attendance.date].count.length} </div>
          <a href="/#" onClick={(e) => this.handleView(e, this.state.dates[attendance.date].desc, attendance.date)}>Details</a>
          <Popover
            content={
              <div className="attendanceLogPopover__table">
                {Object.keys(this.state.dates[attendance.date].desc).map((memberId, i) => {
                  return <div className="attendanceLogPopover__table-row" key={i}>
                    <Tooltip title={memberId.split("|")[1]}>
                      <div className="attendanceLogPopover__table-cell attendanceLogPopover__table-id"><a href={"/member/" + memberId.split("|")[0]} target="_blank" rel="noopener noreferrer">{memberId.split("|")[0]}:&nbsp;</a></div>
                    </Tooltip>
                    <div className="attendanceLogPopover__table-cell">{this.state.dates[attendance.date].desc[memberId].map((details, j) => {
                      return <div key={j} className="attendanceLogPopover__table-details">
                        {details.split(",")[1] === "IN" ? <span style={{ color: "#52c41a" }}>🡯&nbsp;</span> : <span style={{ color: "red" }}>🡭&nbsp;</span>} {details.split(",")[0]}
                      </div>
                    })}</div>
                  </div>
                })}
              </div>
            }
            title={attendance.date + " Logs"}
            trigger="click"
            visible={this.state.dates[attendance.date].popupVisible}
            onVisibleChange={() => this.handleVisibleChange(attendance.date)}>
          </Popover>
        </div>
      }
    })

    return content;
  }

  handleView = (e, details, date) => {
    e.preventDefault();
    this.setState({
      dates: {
        ...this.state.dates,
        [date]: {
          ...this.state.dates[date],
          popupVisible: true
        }
      }
    });

  }

  handleVisibleChange = (date) => {
    this.setState({
      dates: {
        ...this.state.dates,
        [date]: {
          ...this.state.dates[date],
          popupVisible: false
        }
      }
    });
  }

  handleViewMonth = (e, details, monthYear) => {
    e.preventDefault();
    this.setState({
      months: {
        ...this.state.months,
        [monthYear]: {
          ...this.state.months[monthYear],
          popupVisible: true
        }
      }
    });

  }

  handleVisibleChangeMonth = (monthYear) => {
    this.setState({
      months: {
        ...this.state.months,
        [monthYear]: {
          ...this.state.months[monthYear],
          popupVisible: false
        }
      }
    });
  }


  render() {
    return (
      <div>
        <Calendar dateCellRender={this.dateCellRender} monthCellRender={this.monthCellRender} onPanelChange={this.handleOnPanelChange} fullscreen={true}

          headerRender={({ value, type, onChange, onTypeChange }) => {
            const start = 0;
            const end = 12;
            const monthOptions = [];

            const current = value.clone();
            const localeData = value.localeData();
            const months = [];
            for (let i = 0; i < 12; i++) {
              current.month(i);
              months.push(localeData.months(current));
            }

            for (let index = start; index < end; index++) {
              monthOptions.push(
                <Select.Option className="month-item" key={`${index}`}>
                  {months[index]}
                </Select.Option>,
              );
            }
            const month = value.month();

            const year = value.year();
            const options = [];
            for (let i = year - 10; i < year + 10; i += 1) {
              options.push(
                <Select.Option key={i} value={i} className="year-item">
                  {i}
                </Select.Option>,
              );
            }
            return (
              <div style={{ padding: 20 }}>
                {/* <div style={{ marginBottom: '10px' }}>Custom header </div> */}
                <Row type="flex" justify="space-between">
                  <Col>
                    <Group size="small" onChange={e => onTypeChange(e.target.value)} value={type}>
                      <RadioButton value="month">Month</RadioButton>
                      <RadioButton value="year">Year</RadioButton>
                    </Group>
                  </Col>
                  <Col>
                    <h1>{value.format("YYYY MMMM")}</h1>
                  </Col>

                  <Col>
                    <Select
                      size="small"
                      dropdownMatchSelectWidth={false}
                      className="my-year-select"
                      onChange={newYear => {
                        const now = value.clone().year(newYear);
                        onChange(now);
                      }}
                      value={String(year)}
                    >
                      {options}
                    </Select>
                    <Button
                      size="small"
                      onClick={() => {
                        const newValue = value.clone()
                        const _current = value.clone()
                        newValue.month(parseInt(_current.month()) - 1);
                        onChange(newValue);
                      }}>

                      <Icon type="double-left" />
                    </Button>
                    <Select
                      size="small"
                      dropdownMatchSelectWidth={false}
                      value={String(month)}
                      onChange={selectedMonth => {
                        const newValue = value.clone();
                        newValue.month(parseInt(selectedMonth, 10));
                        onChange(newValue);
                      }}
                    >
                      {monthOptions}
                    </Select>
                    {/* </Col>
              <Col> */}
                    <Button
                      size="small"
                      onClick={() => {
                        const newValue = value.clone()
                        const _current = value.clone()
                        newValue.month(parseInt(_current.month()) + 1);
                        onChange(newValue);
                      }}>

                      <Icon type="double-right" />
                    </Button>
                  </Col>
                </Row>
              </div>
            );
          }}        

        />
      </div>
    );
  }
}

export default connect(mapStateToProps, {})(AttendanceLogPageCalendar);
