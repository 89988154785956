import http from "./httpService";
import Utils from "../utils/utils";

const apiEndpoint = Utils.getApiUrl() + "preferences";

export function getPreferences() {
    return http.get(apiEndpoint);   
}

export function setPreferences(data) {
    return http.post(apiEndpoint,data);
}
export function updatePreferences(data) {
    return http.put(apiEndpoint,data);
}


export default {
    getPreferences,
    setPreferences,
    updatePreferences
};
