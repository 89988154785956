import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import Utils from "../utils/utils";
import { getPlans } from "../services/membershipPlansServices";
import { subscriptionsRegister, subscriptionsUpdate } from "../services/subscriptionsServices";
import { connect } from 'react-redux';
import moment from "moment"
import _ from "lodash"
import {
    Form,
    Select,
    DatePicker,
    Button,
    Modal,
    Descriptions,
    Radio,
    message
} from "antd";


const mapStateToProps = (state) => {
    return {
        currentSubscription: state.currentSubscription,
        membershipId: state.membershipId,
        preferences: state.preferences,
    }
}


class _SubscriptionModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subscription: {},
            visible: false,
            profPic: null,
            errors: "",
            proceedAnyway: false,
            plans: [],
            selectedPlanId: "",
            error: "",
            weightUnit: "",
            heightUnit: "",
            selectedPlan: "",
            expireDate: "",
            startDate: "",
            membershipId: "",
            balanceToBePaid: "",
            updateOld: true,
            checkBoxValue: "keepAsItIs",
            payments: {},
            renewChecked: false,
            loading:false,

        };
    }

    getMembershipPlans = async () => {
        try {
            const response = await getPlans()
            this.setState({
                plans: response.data,
                spinning: false,
            })
        } catch (ex) {
            console.log("try", ex)
            if (ex.response && ex.response.status === 400) {
                this.setState({ error: true, spinning: false })
            }

        }
    }
    addSubscriptionPlan = async (subscription) => {
        try {
            const response = await subscriptionsRegister(subscription)
            message.success(response.data, 5)
        } catch (ex) {
            console.log(ex)
            if (ex.response && ex.response.status === 400) {
                this.setState({ error: true, spinning: false })
                message.error(ex.response.data, 5)
            }
        }
    }



    updateSubscriptionPlan = async (subscription, subscriptionId) => {
        try {
            const response = await subscriptionsUpdate(subscription, subscriptionId)
            message.success(response.data, 5)
        } catch (ex) {
            console.log(ex)
            if (ex.response && ex.response.status === 400) {
                this.setState({ error: true, spinning: false })
                message.error(ex.response.data, 5)
            }
        }
    }

    componentDidMount = () => {
        try {
            //await this.getMembershipPlans();
            this.setState({ renewChecked: false })
        } catch (ex) {
            console.log("try", ex)
        }


    }

    setSelectedPlan = (plans, currentSubscription) => {
        let plan = {};
        if (currentSubscription.planStatus.toLowerCase() === "active") {
            plan = _.find(plans, { "planId": currentSubscription.planId });
            return plan
        } else {
            plan.planId = currentSubscription.planId;
            plan.membershipPeriod = currentSubscription.duration;
            plan.prefix = currentSubscription.durationPrefix;
            plan.membershipPlanName = currentSubscription.membershipPlanName
            plan.membershipPrice = currentSubscription.price
            return plan
        }

    }

    componentDidUpdate = async (prevProps) => {
        const { currentSubscription } = this.props;
        if (prevProps.visible !== this.props.visible && this.props.visible === true) {
            this.setState({ renewChecked: false })
            await this.getMembershipPlans();
            if (this.props.formType === "update") {
                this.setFields();
                const _selectedPlan = this.setSelectedPlan(this.state.plans, currentSubscription)
                this.calculatePayments(this.state.checkBoxValue, currentSubscription, _selectedPlan);
                this.setState({ checkBoxValue: "keepAsItIs", selectedPlan: _selectedPlan, startDate: Utils.convertUtcTimeToLocalAsMoment(currentSubscription.startDate),expireDate: Utils.convertUtcTimeToLocalAsMoment(currentSubscription.expireDate) }, () => this.calculatePayments(this.state.checkBoxValue, currentSubscription, _selectedPlan))
            }
        }
    }

    checkCurrentSubscription = (currentSubscription) => {

        if (currentSubscription.planStatus.toLowerCase() === "deleted") {
            return `${currentSubscription.planId} - Deleted Plan`;
        } else {
            return currentSubscription.planId;
        }
    }

    setFields = () => {
        this.props.form.setFieldsValue({
            membershipPlanId: this.checkCurrentSubscription(this.props.currentSubscription),
            membershipStartDate: Utils.convertUtcTimeToLocalAsMoment(this.props.currentSubscription.startDate),
            membershipExpireDate: Utils.convertUtcTimeToLocalAsMoment(this.props.currentSubscription.expireDate),
            paymentOptions: "keepAsItIs",
        })
        this.setState({ renewChecked: this.props.currentSubscription.renew })

    }

    componentWillUnmount = () => {
        this.setState({ selectedPlan: "", checkBoxValue: "keepAsItIs" })
    }

    handleSubmit = e => {
        this.setState({loading:true})
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {           
            if (!err) {
                if (this.props.formType === "add") {
                    const subscriptionPlan = this.createAddSubscriptionData(values);
                    await this.addSubscriptionPlan(subscriptionPlan);
                    this.setState({loading:false})
                    this.props.onOk();
                } else if (this.props.formType === "update") {
                    const subscriptionPlan = this.createUpdateSubscriptionData(values);
                    await this.updateSubscriptionPlan(subscriptionPlan, this.props.currentSubscription.subscriptionId);
                    this.setState({loading:false})
                    this.props.onOk();
                }

            }
        });
    };

    createUpdateSubscriptionData = (formInputs) => {       
        let NewSubscription = {};
        if (formInputs.membershipPlanId === this.props.currentSubscription.planId) {  //same plan changes            
            NewSubscription.memberId = this.props.membershipId;
            NewSubscription.subscriptionId = this.props.currentSubscription.subscriptionId;
            NewSubscription.planId = this.props.currentSubscription.planId;
            NewSubscription.planStatus = this.props.currentSubscription.planStatus;
            NewSubscription.membershipPlanName = this.props.currentSubscription.membershipPlanName;
            NewSubscription.renew = this.state.renewChecked;
            // NewSubscription.startDate = Utils.convertLocalTimeToUtc(formInputs.membershipStartDate.startOf("day"));
            // NewSubscription.expireDate = Utils.convertLocalTimeToUtc(formInputs.membershipExpireDate.endOf("day"));
            NewSubscription.startDate = Utils.convertLocalTimeToUtc(this.state.startDate);
            NewSubscription.expireDate = Utils.convertLocalTimeToUtc(this.state.expireDate);
            NewSubscription.duration = this.props.currentSubscription.duration;
            NewSubscription.durationPrefix = this.props.currentSubscription.durationPrefix;
            NewSubscription.price = this.props.currentSubscription.price;
            NewSubscription.amountPaid = this.state.payments.amountPaid;
            NewSubscription.amountBalance = this.state.payments.amountBalance;
            NewSubscription.discountsGiven = this.state.payments.discountsGiven;
        } else {  //different plan changes
            NewSubscription.memberId = this.props.membershipId;
            NewSubscription.subscriptionId = this.props.currentSubscription.subscriptionId;
            NewSubscription.planId = this.state.selectedPlan.planId;
            NewSubscription.planStatus = this.state.selectedPlan.planStatus;
            NewSubscription.membershipPlanName = this.state.selectedPlan.membershipPlanName;
            NewSubscription.renew = this.state.renewChecked;
            // NewSubscription.startDate = Utils.convertLocalTimeToUtc(formInputs.membershipStartDate.startOf("day"));
            // NewSubscription.expireDate = Utils.convertLocalTimeToUtc(formInputs.membershipExpireDate.endOf("day"));
            NewSubscription.startDate = Utils.convertLocalTimeToUtc(this.state.startDate);
            NewSubscription.expireDate = Utils.convertLocalTimeToUtc(this.state.expireDate);
            NewSubscription.duration = this.state.selectedPlan.membershipPeriod;
            NewSubscription.durationPrefix = this.state.selectedPlan.prefix;
            NewSubscription.price = this.state.selectedPlan.membershipPrice;
            NewSubscription.amountPaid = this.state.payments.amountPaid;
            NewSubscription.amountBalance = this.state.payments.amountBalance;
            NewSubscription.discountsGiven = this.state.payments.discountsGiven;
        }
        return NewSubscription;
    }

    createAddSubscriptionData = (formInputs) => {
        let NewSubscription = {};
        NewSubscription.planId = this.state.selectedPlan.planId;
        NewSubscription.memberId = this.props.membershipId;
        NewSubscription.planStatus = this.state.selectedPlan.planStatus;
        // NewSubscription.startDate = Utils.convertLocalTimeToUtc(formInputs.membershipStartDate.startOf("day"));
        // NewSubscription.expireDate = Utils.convertLocalTimeToUtc(formInputs.membershipExpireDate.endOf("day"));
        NewSubscription.startDate = Utils.convertLocalTimeToUtc(this.state.startDate);
        NewSubscription.expireDate = Utils.convertLocalTimeToUtc(this.state.expireDate);
        NewSubscription.membershipPlanName = this.state.selectedPlan.membershipPlanName;
        NewSubscription.renew = this.state.renewChecked;
        NewSubscription.duration = this.state.selectedPlan.membershipPeriod;
        NewSubscription.durationPrefix = this.state.selectedPlan.prefix;
        NewSubscription.price = this.state.selectedPlan.membershipPrice;
        NewSubscription.status = "pending";
        NewSubscription.paymentStatus = "not paid";
        NewSubscription.amountPaid = 0;
        return NewSubscription;
    }

    saveSelectedPlan_calculateExpireDate(planId, plans) {       
        const { startDate, checkBoxValue } = this.state;
        const { currentSubscription } = this.props;
        let plan = _.find(plans, { "planId": planId })
        this.calculatePayments(checkBoxValue, currentSubscription, plan);
        this.setState({ selectedPlan: plan }, () => this.calculateExpireDate(plan, startDate))

    }

    savePlanStartDate_calculateExpireDate(planStartDate) {
        let _nowTime = moment().format("HH:mm:ssZ")
        let _startDate = planStartDate.format("YYYY-MM-DD")
        let _combinedDate = _startDate + "T" + _nowTime;

        let _planStartDate = new moment(_combinedDate, "YYYY-MM-DDTHH:mm:ssZ");
     
        if (this.state.selectedPlan) {
            this.setState({ startDate: _planStartDate }, () => this.calculateExpireDate(this.state.selectedPlan, _planStartDate))
        } else {
            this.setState({ startDate: _planStartDate }, () => this.calculateExpireDate(this.props.currentSubscription, _planStartDate))
        }

    }

    calculateExpireDate = (plan, planStartDate) => {
        try {
            let expire_date = "";
            if (this.state.selectedPlan) {
                expire_date = planStartDate.clone().add(plan.membershipPeriod, plan.prefix);               
            } else {
                expire_date = planStartDate.clone().add(plan.duration, plan.durationPrefix);               
            }
            this.setState({ expireDate: expire_date }, () => this.props.form.setFieldsValue({ membershipExpireDate: expire_date }))
        } catch (ex) {
        }
    }

    calculatePayments = (option, currentSubscription, selectedPlan) => {
        let _payments = {};
        if (currentSubscription && selectedPlan) {
            if (currentSubscription.planId === selectedPlan.planId) {
                if (option === "keepAsItIs") {
                    _payments.amountPaid = currentSubscription.amountPaid;
                    _payments.amountBalance = currentSubscription.amountBalance;
                    _payments.discountsGiven = currentSubscription.discountsGiven;
                } else if (option === "removeDiscount") {
                    _payments.amountPaid = currentSubscription.amountPaid;
                    _payments.amountBalance = currentSubscription.amountBalance + currentSubscription.discountsGiven;
                    _payments.discountsGiven = 0;
                } else if (option === "resetAll") {
                    //implement in future if needed
                }

            } else {
                if (option === "keepAsItIs") {
                    _payments.amountPaid = currentSubscription.amountPaid;
                    _payments.amountBalance = selectedPlan.membershipPrice - (currentSubscription.amountPaid + currentSubscription.discountsGiven);
                    _payments.discountsGiven = currentSubscription.discountsGiven;
                } else if (option === "removeDiscount") {
                    _payments.amountPaid = currentSubscription.amountPaid;
                    _payments.amountBalance = selectedPlan.membershipPrice - currentSubscription.amountPaid;
                    _payments.discountsGiven = 0;
                } else if (option === "resetAll") {
                    //implement in future if needed
                }
            }

            this.setState({ payments: _payments });
        }

    }

    handleCheckBoxes = (e) => {    
        this.calculatePayments(e.target.value, this.props.currentSubscription, this.state.selectedPlan)
        this.setState({ checkBoxValue: e.target.value })
    }

    handleCancel = e => {
        this.props.onCancel()
    };

    handleOk = values => {
        this.props.onOk()
        this.setState({
            visible: false,
        });
    };

    checkOnChange = e => {
        this.setState({
            renewChecked: e.target.checked,
        });
    };

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 12,
                },
            },
        };

        const { getFieldDecorator } = this.props.form;
        const dateFormat = this.props.preferences.dateFormat;
        const currencyUnit = this.props.preferences.currencyUnit
        return (
            <Modal
                visible={this.props.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer=""
                width={700}>

                <div >
                    <div >
                        <Form name="Update_Subscription" noValidate onSubmit={this.handleSubmit} {...formItemLayout}>
                            <h4 style={{ marginBottom: "24px" }}>{`${this.props.buttonText} subscription plan`}</h4>
                            <Form.Item label="Membership Plan">
                                {getFieldDecorator('membershipPlanId', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select a membership Plan!'
                                        },
                                    ],
                                })(
                                    <Select placeholder="Please select a plan" onChange={(id) => { this.saveSelectedPlan_calculateExpireDate(id, this.state.plans) }}>
                                        {this.state.plans.map(plan => {
                                            return <Select.Option key={plan._id} value={plan.planId}>{plan.membershipPlanName}</Select.Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                            {/* <Form.Item colon={false} label=" ">

                                <Checkbox checked={this.state.renewChecked} onChange={this.checkOnChange} >Automatically renew subscription after expiry</Checkbox>

                            </Form.Item> */}

                            <Form.Item label="Membership Start Date">
                                {getFieldDecorator('membershipStartDate', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select a start date for the membership plan!'
                                        },
                                    ],
                                })(
                                    <DatePicker showTime={false} format={dateFormat} onChange={(value) => this.savePlanStartDate_calculateExpireDate(value)} />,
                                )}
                            </Form.Item>

                            <Form.Item label="Membership Expire Date">
                                {getFieldDecorator('membershipExpireDate')(
                                    <DatePicker showTime={false} format={dateFormat} disabled
                                    />
                                )}
                            </Form.Item>

                            {(this.props.formType === "update") ? <>
                                <Form.Item label="select one option">
                                    {getFieldDecorator('paymentOptions', { initialValue: this.state.checkBoxValue })(
                                        <Radio.Group onChange={(e) => this.handleCheckBoxes(e)} >
                                            <Radio value="keepAsItIs">Keep the payments as it is</Radio>
                                            <Radio value="removeDiscount">Keep the payment but reset discounts given</Radio>
                                            {/* <Radio value="resetAll">reset all the payment details</Radio> implement in future if needed */}
                                        </Radio.Group>,
                                    )}
                                </Form.Item>

                                {(this.state.selectedPlan) ? <>
                                    <Form.Item label="Membership Duration">
                                        <Descriptions.Item label="Duration">{this.state.selectedPlan?.membershipPeriod} {this.state.selectedPlan?.prefix}</Descriptions.Item>
                                    </Form.Item>

                                    <Form.Item label="Membership Price">
                                        <Descriptions.Item label="Price">{this.state.selectedPlan?.membershipPrice} {currencyUnit}</Descriptions.Item>
                                    </Form.Item>
                                </> : <>
                                        <Form.Item label="Membership Duration">
                                            <Descriptions.Item label="Duration">{this.props.currentSubscription?.duration}</Descriptions.Item>
                                        </Form.Item>

                                        <Form.Item label="Membership Price">
                                            <Descriptions.Item label="Price">{this.props.currentSubscription?.price} {currencyUnit}</Descriptions.Item>
                                        </Form.Item>
                                    </>}

                                <Form.Item label="Balance amount to be paid">
                                    <Descriptions.Item label="balanceAmount">{this.state.payments?.amountBalance} {currencyUnit}</Descriptions.Item>
                                </Form.Item>
                            </> : null}

                            <Form.Item {...tailFormItemLayout}  >
                                <Button style={{ margin: "10px" }} type="primary" onClick={this.handleCancel}  >
                                    Cancel
                                </Button>
                                <Button style={{ margin: "10px" }} type="primary" htmlType="submit" loading={this.state.loading} >
                                    {this.props.buttonText}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        );
    }
}

const WrappedModal = connect(mapStateToProps, {})(Form.create({ name: 'subscription_details_form' })(_SubscriptionModal));
export default withRouter(WrappedModal);
