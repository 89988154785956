import React from "react";
import {
    Spin,
    DatePicker,
    Select,
    Statistic
} from "antd";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPaymentsDetails } from "../../services/paymentsServices.js";
import ChartComponent from "../../components/Charts/PaymentChart";
import moment from "moment";
import PaymentsTable from "../../components/Tables/PaymentsTable"
import DownloadExcelPayments from "../../components/Utils/DownloadExcelPayments";


const { RangePicker } = DatePicker;
const { Option } = Select;

const mapStateToProps = (state) => {
    return {
        preferences: state.preferences
    }
}


class PaymentsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            spinning: true,
            tabActiveKey: "1",
            currentSubscriptions: [],
            payments: [],
            chartArray: [],
            selectValue: "pastMonth",
            fromTimeStamp: "",
            toTimeStamp: "",
            total: 0,
            datePickerFromMoment: "",
            datePickerToMoment: "",
            type: "pastMonth",
        };

    }


    init = async () => {
        let data = {};
        data.type = this.state.type;
        data.from = this.state.fromTimeStamp;
        data.to = this.state.toTimeStamp;
        try {
            let response = await getPaymentsDetails(data);
            this.setState({ spinning: false, chartArray: response.data.chartDataArray, total: response.data.total, payments: response.data.payments });
        } catch (ex) {
            console.log("ex", ex)
        }
    }


    handleRangePickerChange = (date) => {

        if (date.length !== 0) {
            let _from = date[0].utc().startOf('day');
            let _to = date[1].utc().endOf('day');         
            let difference = _to.diff(_from, 'days')
            let _selectValue = "";
            let _type = "";

            if (difference === 0) {
                _type = "oneDay";
                _selectValue = "range";
            } else {
                _selectValue = "range";
                _type = "range";
            }

            _from = _from.utc().format("X");
            _to = _to.utc().format("X");


            this.setState({
                fromTimeStamp: _from,
                toTimeStamp: _to,
                selectValue: _selectValue,
                datePickerFromMoment: date[0],
                datePickerToMoment: date[1],
                type: _type,
            }, () => { this.init() })
        }

    }

    handleSelectChange = (value) => {

        if (value === "today") {
            this.calculateRange(moment(), moment(), value);
        }
        if (value === "pastWeek") {
            let _from = moment().utc().subtract(1, 'weeks');
            let _to = moment().utc();
            this.calculateRange(_from, _to, value);
        }
        if (value === "pastMonth") {
            let _from = moment().utc().subtract(1, 'months');
            let _to = moment().utc();
            this.calculateRange(_from, _to, value);
        }
        if (value === "pastYear") {
            let _from = moment().utc().subtract(1, 'years').subtract(1, 'days');
            let _to = moment().utc();
            this.calculateRange(_from, _to, value);
        }

    }

    calculateRange = (from, to, selectValue) => {     
        let _type = "";
        let _from = from.utc().startOf('day');
        let _to = to.utc().endOf('day');

        let difference = _to.diff(_from, 'days')
        let _selectValue = selectValue;

        if (difference === 0) {
            _selectValue = selectValue
            _type = "oneDay";
        } else {
            _selectValue = selectValue;
            _type = selectValue
        }

        _from = _from.utc().format("X");
        _to = _to.utc().format("X");


        this.setState({
            fromTimeStamp: _from,
            toTimeStamp: _to,
            selectValue: _selectValue,
            datePickerFromMoment: from,
            datePickerToMoment: to,
            type: _type,
        }, () => { this.init() })
    }

    componentDidMount() {
        this.handleSelectChange(this.state.selectValue)
    }

    handleExport = () => {
        return (
            <DownloadExcelPayments />
        );

    }

    render() {
        return (
            <div>
                {/* <Breadcrumb>
                    <Breadcrumb.Item>                      
                        <span>Payments</span>
                    </Breadcrumb.Item>
                </Breadcrumb> */}
                <h3>Payments</h3>

                <div>
                    {(this.state.spinning === true) ? <Spin size="large" className="spinner" tip="Loading Payments..." /> : <div>
                        <div className="SubscriptionsPage" style={{ display: "flex", justifyContent: "space-between" }}>
                            <div >
                                <RangePicker
                                    style={{ paddingRight: "10px" }}
                                    onChange={this.handleRangePickerChange}
                                    format={this.props.preferences.dateFormat}
                                    value={[this.state.datePickerFromMoment, this.state.datePickerToMoment]}
                                />
                                <Select defaultValue="thisMonth" style={{ width: 150 }} onChange={this.handleSelectChange} value={this.state.selectValue}>
                                    <Option value="today">Today</Option>
                                    <Option value="pastWeek">Week to Date</Option>
                                    <Option value="pastMonth">Month to Date</Option>
                                    <Option value="pastYear">Year to Date</Option>
                                    <Option value="range">Select Period</Option>
                                </Select>
                                <DownloadExcelPayments data={this.state.payments} from={this.state.datePickerFromMoment.format(this.props.preferences.dateFormat)} to={this.state.datePickerToMoment.format(this.props.preferences.dateFormat)} />


                            </div>
                            {/* <div style={{ fontSize: "24px" }}>Income: {this.state.total}{this.props.preferences.currencyUnit}</div> */}
                            <Statistic title="Income" precision={2} value={this.state.total} suffix={this.props.preferences.currencyUnit} />
                        </div>
                        <ChartComponent data={this.state.chartArray} height={300} width="100%" />
                        <div style={{ marginTop: "20px" }}>
                            <PaymentsTable payments={this.state.payments} />
                        </div>


                    </div>}

                </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, {})(PaymentsPage));
