import React from "react";
import { Menu } from "antd";
import { NavLink, withRouter } from "react-router-dom";
import PubSub from 'pubsub-js';
import { connect } from 'react-redux';
const { SubMenu } = Menu;

const mapStateToProps = (state) => {
  return {
    gymDetails: state.gymDetails,
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: '1',
      selectedKey: "",
      OpenMenuKey: "",
      sidebarOpen: false,
      disabledMenuItem: true,
    };
  }

  componentDidMount() {
    this.checkPermission()
    this.selectMenuItem();
    PubSub.subscribe('eventSidebar', (event, isForceHide) => {
      this.setState({
        sidebarOpen: isForceHide ? false : !this.state.sidebarOpen
      })
    });
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps !== this.props) {
      this.checkPermission()
    }
    if (this.props.location !== prevProps.location) {
      this.selectMenuItem()
    }

  }
  componentWillUnmount() {
    PubSub.unsubscribe('eventSidebar');
  }

  checkPermission = () => {
    if (this.props.gymDetails.expired) {
      this.setState({ disabledMenuItem: true })
    } else {
      this.setState({ disabledMenuItem: false })
    }
  }

  selectMenuItem = () => {
    let _key1 = ""
    let _key = window.location.pathname.split("/")[1];
    _key1 = _key;
    let _OpenMenuKey = "";
    if (_key === "memberRegister" || _key === "members") {
      _OpenMenuKey = "customers"
    } else if (_key === "attendanceLog" || _key === "takeAttendance") {
      _OpenMenuKey = "attendance"
    } else if (_key === "add_membership_plan" || _key === "membership_plans") {
      _OpenMenuKey = "membershipPlans"
    }
    else if (_key === "staffRegister" || _key === "staff") {
      _OpenMenuKey = "Staff"
    } else if (_key === "preferences") {
      // _key1 = null
    }

    this.setState({
      selectedKey: _key1,
      OpenMenuKey: _OpenMenuKey
    })

  }

  handleClick = e => {
    this.setState({
      selectedKey: e.key,
      OpenMenuKey: e.keyPath[1]
    });
  };

  handleOpen = e => {
    this.setState({
      OpenMenuKey: e[e.length - 1]
    });
  };

  render() {
    const { disabledMenuItem } = this.state;
    return (
      <div className={"Sidebar " + (this.state.sidebarOpen ? "Sidebar__Open" : "")}>
        <div className="LogoWrapper">
          <div className="Logo"></div>
        </div>
        <Menu
          onClick={this.handleClick}
          onOpenChange={this.handleOpen}
          selectedKeys={[this.state.selectedKey]}
          openKeys={[this.state.OpenMenuKey]}
          mode="inline"
          theme="dark"
        >
          <Menu.Item key="dashboard" disabled={disabledMenuItem}>
            <NavLink className="sidebar--link" to="/dashboard">
              <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"
                stroke="#9fa7ae" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" color="#9fa7ae">
                <polygon points="2 12 2 21 6 21 6 12" /> <polygon points="18 7 18 21 22 21 22 7" /> <polygon points="10 3 10 21 14 21 14 3" /> </svg>
              <span>Dashboard</span>
            </NavLink>
          </Menu.Item>
          <SubMenu
            disabled={disabledMenuItem}
            key="customers"
            title={
              <span className="sidebar--link">
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" stroke="#9fa7ae"
                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" color="#9fa7ae">
                  <path d="M1 18C1 15.75 4 15.75 5.5 14.25 6.25 13.5 4 13.5 4 9.75 4 7.25025 4.99975 6 7 6 9.00025 6 10 7.25025 10 9.75 10 13.5 7.75 13.5 8.5 14.25 10 15.75 13 15.75 13 18M12.7918114 15.7266684C13.2840551 15.548266 13.6874862 15.3832994 14.0021045 15.2317685 14.552776 14.9665463 15.0840574 14.6659426 15.5 14.25 16.25 13.5 14 13.5 14 9.75 14 7.25025 14.99975 6 17 6 19.00025 6 20 7.25025 20 9.75 20 13.5 17.75 13.5 18.5 14.25 20 15.75 23 15.75 23 18" />
                  <path strokeLinecap="round" d="M12,16 C12.3662741,15.8763472 12.6302112,15.7852366 12.7918114,15.7266684" /> </svg>
                <span>Members</span>
              </span>
            }
          >
            <Menu.Item key="memberRegister">
              <NavLink to="/memberRegister">
                <span>Add New Member</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="members">
              <NavLink to="/members">
                <span>View Members</span>
              </NavLink>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="subscriptions" disabled={disabledMenuItem}>
            <NavLink className="sidebar--link" to="/subscriptions">
              <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#9fa7ae" strokeWidth="1.5" strokeLinecap="round"
                strokeLinejoin="round" fill="none" color="#9fa7ae">
                <path d="M13 18L6 18L6 7" /> <path d="M3 9L6 6L9 9" /> <path d="M11 6L18 6L18 17" /> <path d="M21 15L18 18L15 15" /> </svg>
              <span>Subscriptions</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="payments" disabled={disabledMenuItem}>
            <NavLink className="sidebar--link" to="/payments">
              <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" stroke="#9fa7ae" strokeWidth="1.5" strokeLinecap="round"
                strokeLinejoin="round" fill="none" color="#9fa7ae">
                <path d="M12 4L12 6M12 18L12 20M15.5 8C15.1666667 6.66666667 14 6 12 6 9 6 8.5 7.95652174 8.5 9 8.5 13.140327 15.5 10.9649412 15.5 15 15.5 16.0434783 15 18 12 18 10 18 8.83333333 17.3333333 8.5 16" /> </svg>
              <span>Payments</span>
            </NavLink>
          </Menu.Item>

          <SubMenu
            disabled={disabledMenuItem}
            key="attendance"
            title={
              <span className="sidebar--link">
                <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#9fa7ae" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" color="#9fa7ae">
                  <path d="M3 5H21V21H3V5Z" /> <path d="M21 9H3" /> <path d="M7 5V3" /> <path d="M17 5V3" /> <rect x="15" y="15" width="2" height="2" /> </svg>
                <span>Attendance</span>
              </span>
            }
          >
            <Menu.Item key="attendanceLog">
              <NavLink to="/attendanceLog">             
                <span>Attendance Log</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="takeAttendance">
              <NavLink to="/takeAttendance">            
                <span>Take Attendance</span>
              </NavLink>
            </Menu.Item>
          </SubMenu>
          <Menu.Divider style={{ backgroundColor: "#65717d" }} />
          <SubMenu
            disabled={disabledMenuItem}
            key="membershipPlans"
            title={
              <span className="sidebar--link">
                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#9fa7ae" strokeWidth="1.5" 
                strokeLinecap="round" strokeLinejoin="round" color="#9fa7ae"> <path d="M9 4C9 2.89543 9.89543 2 11 2C12.1046 2 13 2.89543 13 4V6H18V11H20C21.1046 11 22 11.8954 22 13C22 14.1046 21.1046 15 20 15H18V20H13V18C13 16.8954 12.1046 16 11 16C9.89543 16 9 16.8954 9 18V20H4V15H6C7.10457 15 8 14.1046 8 13C8 11.8954 7.10457 11 6 11H4V6H9V4Z"/> </svg>
                <span>Membership Plans</span>
              </span>
            }
          >
            <Menu.Item key="add_membership_plan">
              <NavLink to="/add_membership_plan">              
                <span>Add Plan</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="membership_plans">
              <NavLink to="/membership_plans">               
                <span>View Plans</span>
              </NavLink>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            disabled={disabledMenuItem}
            key="Staff"
            title={
              <span className="sidebar--link">
                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" stroke="#9fa7ae" strokeWidth="1.5" 
                strokeLinecap="round" strokeLinejoin="round" fill="none" color="#9fa7ae"> 
                <path d="M8,3 L8,21"/> <path d="M20,2.99999899 L20,21 L6,21 C4.8954305,21 4,20.1045695 4,19 L4,4.99999899 C4,3.89542949 4.8954305,2.99999899 6,2.99999899 L20,2.99999899 Z"/> <path d="M8,17 C8,15 11.3333333,15.3333333 12.6666667,14 C13.3333333,13.3333333 11.3333333,13.3333333 11.3333333,10 C11.3333333,7.778 12.222,6.66666667 14,6.66666667 C15.778,6.66666667 16.6666667,7.778 16.6666667,10 C16.6666667,13.3333333 14.6666667,13.3333333 15.3333333,14 C16.6666667,15.3333333 20,15 20,17"/> </svg>
                <span>Staff</span>
              </span>
            }
          >
            <Menu.Item key="staffRegister">
              <NavLink to="/staffRegister">              
                <span>Add Staff Member</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="staff">
              <NavLink to="/staff">              
                <span>View Staff</span>
              </NavLink>
            </Menu.Item>
          </SubMenu>

        </Menu>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, {})(Sidebar));

