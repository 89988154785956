import React from "react";
import { updatePreferences } from "../../services/preferencesServices";
import { setPreferences } from "../../actions/index";
import {
    Form,
    Divider,   
    Select,
    Button,
    message
} from 'antd';
import { connect } from 'react-redux';
import Constants from "../../utils/constants"


const mapStateToProps = (state) => {
    return {
        preferences: state.preferences
    }
}


class _Preferences extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    state = {
        confirmDirty: false,
        autoCompleteResult: [],
    };

    componentDidMount() {
        this.setFields()
    }

    setFields = () => {
        const { heightUnit, weightUnit, dateFormat, currencyUnit, timeFormat } = this.props.preferences;
        this.props.form.setFieldsValue({
            currencyUnit: currencyUnit,
            heightUnit: heightUnit,
            weightUnit: weightUnit,
            dateFormat: dateFormat,
            timeFormat: timeFormat,
        })
    }


    doSubmit = async (plan) => {
        try {
            const response = await updatePreferences(plan);
            this.props.setPreferences(response.data);
            if (response.data) {
                message.success("Preferences Successfully Updated!", 5)
                this.props.history.push(`/dashboard`);
            }

        } catch (ex) {
            if (ex.response) {
                message.error(ex.response.data, 5)
            }
        }

    };


    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, plan) => {
            if (!err) {
                // console.log(plan)
                this.doSubmit(plan)
            }
        });
    };

    reset = () => {
        this.props.form.resetFields()
    }


    addWeightSuffix = (unit) => {
        if (unit) {
            if (unit === "lb") {
                return "lb - Imperial System"
            } else if (unit === "kg") {
                return "kg - Metric System"
            }
        }
    }

    addHeightSuffix = (unit) => {
        if (unit) {
            if (unit === "feet") {
                return "feet & inches - Imperial System"
            } else if (unit === "cm") {
                return "cm - Metric System"
            }
        }
    }
    addTimeFormatSuffix = (unit) => {
        if (unit) {
            if (unit === "h:mm A") {
                return "12 Hour Time - (Ex: 6.32 PM)"
            } else if (unit === "HH:mm") {
                return "24 Hour Time - (Ex: 18.32)"
            }
        }
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 14,
                    offset: 8,
                },
            },
        };

        return (
            <div>

                <h3>Preferences</h3>
                <Divider />
                <div className="RegisterPage">
                    <div className="RegisterPage__Content">

                        <Form {...formItemLayout} onSubmit={this.handleSubmit} className="RegisterPage__Form">

                            <Form.Item label="Currency Unit">
                                {getFieldDecorator('currencyUnit')(
                                    <Select placeholder="Select Currency Unit">
                                        {Constants.currencyUnitsArray.map((currency, index) => <Select.Option key={index} value={currency}>{currency}</Select.Option>)}
                                    </Select>,
                                )}
                            </Form.Item>
                            <Form.Item label="Weight Unit">
                                {getFieldDecorator('weightUnit')(
                                    <Select placeholder="Select weight Unit">
                                        {Constants.weightUnitsArray.map((weight, index) => <Select.Option key={index} value={weight}>{this.addWeightSuffix(weight)}</Select.Option>)}
                                    </Select>,
                                )}
                            </Form.Item>
                            <Form.Item label="Height Unit">
                                {getFieldDecorator('heightUnit')(
                                    <Select placeholder="Select Height Unit">
                                        {Constants.heightUnitsArray.map((height, index) => <Select.Option key={index} value={height}>{this.addHeightSuffix(height)}</Select.Option>)}
                                    </Select>,
                                )}
                            </Form.Item>

                            <Form.Item label="Date Format">
                                {getFieldDecorator('dateFormat')(
                                    <Select placeholder="Select Date Format">
                                        {Constants.dateFormatArray.map((format, index) => <Select.Option key={index} value={format}>{format}</Select.Option>)}
                                    </Select>,
                                )}
                            </Form.Item>
                            <Form.Item label="Time Format">
                                {getFieldDecorator('timeFormat')(
                                    <Select placeholder="Select Time Format">
                                        {Constants.timeFormatArray.map((format, index) => <Select.Option key={index} value={format}>{this.addTimeFormatSuffix(format)}</Select.Option>)}
                                    </Select>,
                                )}
                            </Form.Item>


                            <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit">
                                    Save
                                    </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <Divider />

            </div>

        );
    }
}

const Preferences = connect(mapStateToProps, { setPreferences })(Form.create({ name: 'preferences_page' })(_Preferences));

export default Preferences;

