import React from "react";
import { validateAccount } from "../../services/validateAccountServices";
import queryString from 'query-string'
import Footer from './../../components/Layout/Footer';
import {
    Spin,
    Result
} from 'antd';


export default class AccountValidationPage extends React.Component {
    state = {
        confirmDirty: false,
        errors: "",
        email: "",
        loading: true,

    };


    async componentDidMount() {
        try {
            const value = queryString.parse(this.props.location.search);
            const response = await validateAccount({ token: value.token });

            localStorage.setItem("token", response.data)
            window.location = "/dashboard"

        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                this.setState({ loading: false, errors: ex.response.data })
            }
        }

    }

    render() {

        return (
            <div className="register-page">
                {this.state.loading ?
                    <Spin className="spinner" tip="Verifying account..."></Spin> : null}
                {this.state.errors && <Result
                    status="warning"
                    title={this.state.errors}
                />}
                <Footer isLogOut={true}></Footer>
            </div>
        );
    }
}

