import http from "./httpService";
import Utils from "../utils/utils";

const apiEndpoint = Utils.getApiUrl() + "plans";



export function getPlans() {
    return http.get(apiEndpoint);
}


export default {
   getPlans,
   
};

