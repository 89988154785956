import React, { Component } from 'react'
import { Calendar, Card, Tooltip, Popover, Select, Row, Col, Radio, Icon, Button } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
const { Group, Button: RadioButton } = Radio;

const mapStateToProps = (state) => {
  return {
    membershipId: state.membershipId,
    preferences: state.preferences
  }
}

class _MemberAttendanceCalendar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      attendances: [],
      dates: {},
      members: [],
    }
  }

  componentDidMount = async () => {

    let _members = this.props.members;
    let _dates = {};
    _members.forEach((member) => {
      let date = member.currentSubscription.expireDate;

      if (_dates[date]) {
        _dates[date].memId.push(`${member.memberId}|${member.name}`);
        // _dates[date].count = _dates[date].count + 1;
      } else {
        _dates[date] = {
          memId: [`${member.memberId}|${member.name}`]
        }

        // _dates[date].count = 1;
      }
      member.expireDate = date
    });
    this.calculateMonths(_members)
    this.setState({
      //attendances: response.data,
      dates: _dates,
      members: _members,
    });

  }


  dateCellRender = (calendar) => {
    let { dateFormat } = this.props.preferences;
    let content;
    this.state.members.forEach((member) => {
      if (calendar.format(dateFormat) === member.expireDate) {
        content = <div className="attendanceLogCell">
          <div className="attendanceCount">{this.state.dates[member.expireDate].memId.length} </div>
          <a href="/#" onClick={(e) => this.handleView(e, this.state.dates[member.expireDate], member.expireDate)}>Details</a>
          <Popover
            content={
              <div className="attendanceLogPopover__table">
                {this.state.dates[member.expireDate].memId.map((memberId, i) => {
                  return <div className="attendanceLogPopover__table-row" key={i}>
                    <Tooltip title={memberId.split("|")[1]}>
                      <div className="attendanceLogPopover__table-cell attendanceLogPopover__table-id"><a href={"/member/" + memberId.split("|")[0]} target="_blank" rel="noopener noreferrer">ID: {memberId.split("|")[0]} &nbsp;</a></div>
                    </Tooltip>
                  </div>
                })}
              </div>
            }
            title={" Expires on: " + member.expireDate}
            trigger="click"
            visible={this.state.dates[member.expireDate].popupVisible}
            onVisibleChange={() => this.handleVisibleChange(member.expireDate)}>
          </Popover>
        </div>
      }
    })

    return content;
  }


  handleView = (e, details, date) => {
    e.preventDefault();
    this.setState({
      dates: {
        ...this.state.dates,
        [date]: {
          ...this.state.dates[date],
          popupVisible: true
        }
      }
    });

  }

  handleVisibleChange = (date) => {
    this.setState({
      dates: {
        ...this.state.dates,
        [date]: {
          ...this.state.dates[date],
          popupVisible: false
        }
      }
    });
  }


  calculateMonths = (members) => {

    let _months = {};
    members.forEach((member) => {

      const memberId = `${member.memberId}|${member.name}`;

      if (_months[member.monthYear]) {

        if (_months[member.monthYear].desc[memberId]) {
          // _months[attendance.monthYear].desc[memberId].push(`${attendance.date}`);
          if (_months[member.monthYear].desc[memberId].indexOf(member.expireDate) === -1) {
            _months[member.monthYear].desc[memberId].push(`${member.expireDate}`);
          }

        } else {
          _months[member.monthYear].desc[memberId] = [`${member.expireDate}`];
        }

        if (_months[member.monthYear].count.indexOf(memberId) === -1) {
          _months[member.monthYear].count.push(`${memberId}`);
        }
      } else {
        _months[member.monthYear] = {
          desc: {
            [memberId]: [`${member.expireDate}`]
          },
          count: [`${memberId}`],
          popupVisible: false
        };
      }

    });
    this.setState({
      months: _months,
    });
  }

  monthCellRender = (calendar) => {

    let content;

    this.state.members.forEach((member) => {
      if (calendar.format("YYYY-MM") === member.monthYear && this.state.months[member.monthYear].count.length > 0) {
        content = <div className="attendanceLogCell">
          <div className="attendanceCount">{this.state.months[member.monthYear].count.length}</div>
          <a href="/#" onClick={(e) => this.handleViewMonth(e, this.state.months[member.monthYear].desc, member.monthYear)}>Details</a>
          <Popover
            content={
              <div className="attendanceLogPopover__table">
                {(this.state.months[member.monthYear].count).map((memberId, i) => {
                  return <div className="attendanceLogPopover__table-row" key={i}>
                    <Tooltip title={memberId.split("|")[1]}>
                      <div className="attendanceLogPopover__table-cell attendanceLogPopover__table-id"><a href={"/member/" + memberId.split("|")[0]} target="_blank" rel="noopener noreferrer">{memberId.split("|")[0]}:&nbsp;</a></div>
                    </Tooltip>
                    <div className="attendanceLogPopover__table-cell">{this.state.months[member.monthYear].desc[memberId].map((details, j) => {
                      return <div key={j} className="attendanceLogPopover__table-details">
                        {/* {details.split(",")[1] === "IN" ? <span style={{ color: "#52c41a" }}>🡯&nbsp;</span> : <span style={{ color: "red" }}>🡭&nbsp;</span>} {details.split(",")[0]} */}
                        {details}
                      </div>
                    })}</div>
                  </div>
                })}
              </div>
            }
            title={" Membership expires on " + member.monthYear}
            trigger="click"
            visible={this.state.months[member.monthYear].popupVisible}
            onVisibleChange={() => this.handleVisibleChangeMonth(member.monthYear)}>
          </Popover>
        </div>
      }

    })

    return content;
  }

  handleViewMonth = (e, details, monthYear) => {
    e.preventDefault();
    this.setState({
      months: {
        ...this.state.months,
        [monthYear]: {
          ...this.state.months[monthYear],
          popupVisible: true
        }
      }
    });

  }

  handleVisibleChangeMonth = (monthYear) => {
    this.setState({
      months: {
        ...this.state.months,
        [monthYear]: {
          ...this.state.months[monthYear],
          popupVisible: false
        }
      }
    });
  }

  render() {
    return (
      <Card title="Membership Expiring Members">
        <div className="site-calendar-demo-card">
          <Calendar dateCellRender={this.dateCellRender} monthCellRender={this.monthCellRender} fullscreen={true}

            headerRender={({ value, type, onChange, onTypeChange }) => {
              const start = 0;
              const end = 12;
              const monthOptions = [];

              const current = value.clone();
              const localeData = value.localeData();
              const months = [];
              for (let i = 0; i < 12; i++) {
                current.month(i);
                months.push(localeData.months(current));
              }

              for (let index = start; index < end; index++) {
                monthOptions.push(
                  <Select.Option className="month-item" key={`${index}`}>
                    {months[index]}
                  </Select.Option>,
                );
              }
              const month = value.month();

              const year = value.year();
              const options = [];
              for (let i = year - 10; i < year + 10; i += 1) {
                options.push(
                  <Select.Option key={i} value={i} className="year-item">
                    {i}
                  </Select.Option>,
                );
              }
              return (
                <div style={{ padding: 20 }}>
                  {/* <div style={{ marginBottom: '10px' }}>Custom header </div> */}
                  <Row type="flex" justify="space-between">
                    <Col>
                      <Group size="small" onChange={e => onTypeChange(e.target.value)} value={type}>
                        <RadioButton value="month">Month</RadioButton>
                        <RadioButton value="year">Year</RadioButton>
                      </Group>
                    </Col>
                    <Col>
                      <h1>{value.format("YYYY MMMM")}</h1>
                    </Col>

                    <Col>
                      <Select
                        size="small"
                        dropdownMatchSelectWidth={false}
                        className="my-year-select"
                        onChange={newYear => {
                          const now = value.clone().year(newYear);
                          onChange(now);
                        }}
                        value={String(year)}
                      >
                        {options}
                      </Select>
                      <Button
                        size="small"
                        onClick={() => {
                          const newValue = value.clone()
                          const _current = value.clone()
                          newValue.month(parseInt(_current.month()) - 1);
                          onChange(newValue);
                        }}>

                        <Icon type="double-left" />
                      </Button>
                      <Select
                        size="small"
                        dropdownMatchSelectWidth={false}
                        value={String(month)}
                        onChange={selectedMonth => {
                          const newValue = value.clone();
                          newValue.month(parseInt(selectedMonth, 10));
                          onChange(newValue);
                        }}
                      >
                        {monthOptions}
                      </Select>
                      {/* </Col>
                    <Col> */}
                      <Button
                        size="small"
                        onClick={() => {
                          const newValue = value.clone()
                          const _current = value.clone()
                          newValue.month(parseInt(_current.month()) + 1);
                          onChange(newValue);
                        }}>

                        <Icon type="double-right" />
                      </Button>
                    </Col>
                  </Row>
                </div>
              );
            }}
           

          />
        </div>
      </Card>
    )
  }
}


const MemberAttendanceCalendar = connect(mapStateToProps, {})(_MemberAttendanceCalendar);
export default withRouter(MemberAttendanceCalendar);