import React from "react";
import {
  Divider,
  Breadcrumb,
  Spin,
} from "antd";
import { getAttendanceDetails } from "../../services/attendanceServices"
import Utils from "../../utils/utils";
import { connect } from 'react-redux';
import AttendanceLogPageCalendar from "../../components/Calendars/AttendanceLogPageCalendar";

const mapStateToProps = (state) => {
  return {
    preferences: state.preferences
  }
}

class AttendanceLog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      attendances: [],
      dates: {},
      months: {},
      calendarLoading: true,
    }
  }

  componentDidMount = () => {
    this.init();
  }

  init = async () => {
    const { dateFormat, timeFormat } = this.props.preferences;
    let data = {};

    data.type = "all";

    try {
      let response = await getAttendanceDetails(data);
      response.data.forEach((attendance) => {
        attendance.date = Utils.convertUtcTimeToLocal(attendance.date_time, dateFormat);
        attendance.time = Utils.convertUtcTimeToLocal(attendance.date_time, timeFormat);
        attendance.monthYear = Utils.convertUtcTimeToLocal(attendance.date_time, "YYYY-MM");
      })

      this.setState({
        attendances: response.data,
        calendarLoading: false,
      });
    } catch (ex) {
      console.log("Init-attendance page")
    }
  }

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>            
            <span>Attendance / Attendance Log</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <h3>Attendance Log</h3>
        <Divider />
        {(this.state.calendarLoading) ? <Spin size="large" className="spinner" tip="Loading Attendance Log..." /> :
          // <Calendar dateCellRender={this.dateCellRender} monthCellRender={this.monthCellRender} onPanelChange={this.handleOnPanelChange} fullscreen={true} />
          <AttendanceLogPageCalendar attendanceData={this.state.attendances}></AttendanceLogPageCalendar>
        }
      </div>
    );
  }
}

const AttendanceLogPage = connect(mapStateToProps, {})(AttendanceLog);
export default AttendanceLogPage;