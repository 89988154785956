import React from "react";
import { planRegister } from "../../services/membershipPlanServices";
import {
    Form,
    Divider,
    Breadcrumb,
    Input,
    Select,
    Button,    
    message
} from 'antd';

const { Option } = Select;
const { TextArea } = Input;

class _AddMembershipPlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            memberID: this.props.match.params.memberId
        };
    }

    state = {
        confirmDirty: false,
        autoCompleteResult: [],
    };

    doSubmit = async (plan) => {
        try {
            const response = await planRegister(plan);
            message.success(response.data, 5)
            this.props.history.push(`/membership_plans`);
        } catch (ex) {
            if (ex.response) {
                message.error(ex.response.data, 5)
            }
        }

    };


    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, plan) => {
            if (!err) {
                // console.log(plan)
                this.doSubmit(plan)
            }
        });
    };

    reset = () => {
        this.props.form.resetFields()
    }

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };
 

    render() {
        const { getFieldDecorator } = this.props.form;       

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 14,
                    offset: 8,
                },
            },
        };
        const prefixSelector = getFieldDecorator('prefix', {
            initialValue: 'Months',
        })(
            <Select style={{ width: 150 }}>
                <Option value="Months">Months</Option>
                <Option value="Days">Days</Option>
            </Select>,
        );


        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item>                      
                        <span>Membership Plans</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Add Plan</Breadcrumb.Item>
                </Breadcrumb>
                <h3>Add a Membership Plan</h3>
                <Divider />
                <div className="RegisterPage">
                    <div className="RegisterPage__Content">

                        <Form {...formItemLayout} onSubmit={this.handleSubmit} className="RegisterPage__Form">


                            <Form.Item label="Membership Plan Name" hasFeedback>
                                {getFieldDecorator('membershipPlanName', {
                                    rules: [
                                        {
                                            max: 50,
                                            message: 'Name must be below 50 Characters'
                                        },
                                        {
                                            required: true,
                                            message: 'Please input a name to your Plan!',
                                        },

                                    ],
                                })(<Input />)}
                            </Form.Item>

                            <Form.Item label="Membership Period">
                                {getFieldDecorator('membershipPeriod', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select a time period for your plan!'
                                        },


                                    ],
                                })(<Input type="number" addonAfter={prefixSelector} />)}
                            </Form.Item>

                            <Form.Item label="Membership Price">
                                {getFieldDecorator('membershipPrice', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select a price for your plan!'

                                        },


                                    ],
                                })(<Input type="number" addonAfter="$" />)}
                            </Form.Item>

                            <Form.Item label="Membership Plan Description" hasFeedback>
                                {getFieldDecorator('membershipPlanDescription', {
                                    rules: [
                                        {
                                            max: 500,
                                            message: 'Description must be below 500 Characters'
                                        },
                                    ],
                                })(<TextArea rows={2} />)}
                            </Form.Item>


                            <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit">
                                    Add Plan
                                    </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <Divider />

            </div>

        );
    }
}

const AddMembershipPlan = Form.create({ name: 'Add_Membership_Plan' })(_AddMembershipPlan);

export default AddMembershipPlan;

