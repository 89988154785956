export default {
    gymId:"",
    profilePic:"",
    memberId:"",
    name: "",
    idNumber: "",
    address: "",
    email: "",
    phone: "",
    gender: "",
    dateOfBirth:"",
    age: "",
    weight: "",
    height: "",
    bmi:"",
    healthIssues: "",
    emergency: {
        name: "",
        address: "",
        phone: "",
        relationship: ""
    },
    membershipPlanId:"",
    joiningDate: "",
    membershipStartDate:"",
    membershipExpireDate:"",
    membershipStatus:"",
    paymentStatus:"",
    // membershipPlan:{
    //     gymId :"",
    //     membershipPeriod:"",
    //     membershipPlanName:"",
    //     membershipPrice:"",
    //     prefix:"",
    //     membershipPlanDescription:""
    // },
    
}


